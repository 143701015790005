import { Link } from "react-router-dom";
import { Images } from "../../utils/images";

export const WorkCard = ({ item }) => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  return (
    <Link
      onClick={handleClick}
      to={`/${item.title.split(" ").join("-").toLowerCase()}`}
    >
      <div className="ease-in duration-150" key={item.id}>
        <div className="rounded-xl overflow-hidden relative cursor-pointer my-10 ">
          <div className=" hover:scale-125 transition-transform duration-700 hover:rotate-6  h-full sm:h-[500px]">
            <img
              className="w-full h-full object-cover"
              src={item.image}
              alt="latest work"
            />
          </div>
          <img
            className="absolute bottom-8 right-8 sm:h-auto sm:w-auto h-10 w-10"
            src={Images.PlusIcon}
            alt="plus icon"
          />
        </div>
        <h4 className="sm:text-2xl text-lg text-[#343434] font-semibold mt-3 cursor-pointer my-2">
          {item.title}
        </h4>
        <p className="sm:text-xl text-base text-[#555555]">
          {item.description.slice(0, 120)} ...
        </p>
        <div className="flex gap-6 sm:text-lg text-sm font-semibold mt-3">
          {item.category.map((ele, index) => (
            <li key={index}>{ele}</li>
          ))}
        </div>
      </div>
    </Link>
  );
};
