import { Images } from "./images";

export const WSpecality = {
  title: ["Let us help you elevate", "your digital presence"],
  cardContent: [
    {
      id: 1,
      title: "Website Design",
      description:
        "Website design for web development crafting visually appealing interfaces and functionality.",
      image: "https://i.ibb.co/T43LcDC/dev-Card1-fd2597f959f1cf7c650f.webp",
    },

    {
      id: 2,
      title: "CMS Development",
      description:
        "CMS development involves building user-friendly and customizable Content Management Systems, enabling efficient creation",
      image: "https://i.ibb.co/VQ4B8mM/dev-Card2-1596ddb40ddf1b02fd04.jpg",
    },

    {
      id: 3,
      title: "E-commerce",
      description:
        "Facilitating online transactions and business activities through digital platforms, creating seamless shopping experiences for users.",
      image: "https://i.ibb.co/WtdLBxr/dev-Card3-caa18e0919a80fa802be.webp",
    },

    {
      id: 4,
      title: "SEO Optimization",
      description:
        "Enhancing online visibility and search engine rankings through strategic techniques and content optimization, driving organic traffic to websites.",
      image:  "https://i.ibb.co/dDjZwrL/dev-Card4-6681af1a71035683af8e.webp",
    },
  ],
};

// Web services

export const webServices = {
  title: "Web Development Services",
  services: [
    {
      title: "Wordpress Development",
      image: "https://i.ibb.co/68TqshJ/worpressd90dfe33fa945b325298-65e00d7838256.webp",
      description:
        "WordPress development: customization, plugins, content, performance, security, tailored solutions.",
    },
    {
      title: "Webflow Developement ",
      image: "https://i.ibb.co/chZgFFC/webflow-e61fd525e856f112a901.jpg",
      description:
        "Developing websites using Webflow's visual design and CMS capabilities.",
    },
    {
      title: "Shopify Development",
      image: "https://i.ibb.co/fDkQG7G/shopfydd7c189de34d0bf59dba-65e00ddbc7621.webp",
      description:
        "Creating online stores with Shopify, including design, customization, and integration.",
    },
    {
      title: "Custom Website Development",
      image:  "https://i.ibb.co/kJZ5xH5/custome-website-0aed61d4505c5bbfa697.webp",
      description:
        "Tailored website creation, emphasizing unique design, functionality, and user experience. ",
    },
  ],
  button_text: "Develop Now",
  link: "/contact",
};

// deliverables
export const webDeliverables = {
  title: "Deliverables ",
  categoryTitle1: "Web Development",
  category1: [
    "Customized Website",
    "Responsive Design",
    "User-friendly Interface",
    "Content Management System (CMS)",
    "Cross-browser Compatibility",
  ],
  category2: [
    "SEO Optimization",
    "Secure Web Applications",
    "Mobile Responsiveness",
    "Fast Loading Speed",
    "Maintenance and Supports",
  ],
};

// Working proccess web development
export const webWorkingProccess = {
  title: ["Strategic Working Process", "for Business Success"],
  process: [
    "Information Gathering",
    "Planning",
    "Design",
    "Deployment",
    "Quality Assurance",
    "Launch",
    "Monitoring & Updates",
  ],
};

// web Gallery
export const webUXGallery = {
  buttonText: "View more projects",
  link: "/our-works",
  images: [
    "https://i.ibb.co/VNy4JVX/dev-1-14d9c4344b2c1b1d2f60.webp",
    "https://i.ibb.co/GWc5G5g/dev-2-67e79c5176775a2cd9f7.webp",
    "https://i.ibb.co/bgYFs5W/dev-3-6aa1a7dc9a3e130c4b7d.webp",
    "https://i.ibb.co/CzX8QKV/first-825d03b695987ad80cba.webp",
    "https://i.ibb.co/sHVyYMy/dev-5-9ff4430fc36172484377.webp",
    "https://i.ibb.co/0Ygh36F/dev-6-378aa88cb0f5db09c86f.webp",
    "https://i.ibb.co/HzGb3tm/dev-7-0785e5276adca9cd98e5.png",
  ],
};

// About Web Service
export const aboutWebService = {
  description:
    "Make more than just a thing; give your customers a special and unforgettable experience.",
  title:
    "We develop interfaces thoughtfully to make users comfortable and trust us with every click.",
  buttonText: "Craft Your Digital Journey",
};

// Why Chuse us sevices text
export const webwhayChuseUs = {
  services: [
    {
      id: 1,
      title: `High-Quality Work and On-time Delivery`,
      image: Images.dimond1,
    },
    {
      id: 2,
      title: `Committed to providing the highest quality service.`,
      image: Images.badge1,
    },
    {
      id: 3,
      title: `Quick Response with 24/7 Support`,
      image: Images.tecnical1,
    },
    {
      id: 4,
      title: `30 Days of Free Support After the Delivery`,
      image: Images.productivaty1,
    },
  ],

  question: [
    {
      title: "What are Web development  services?",
      answer:
        "Web development services, including CMS solutions, involve building and enhancing websites using coding and design. Their goal is to craft functional and visually appealing online experiences that engage users effectively.",
    },
    {
      title: "Why are Web Development services important?",
      answer:
        "Web development services are important because they create and maintain websites, making them easy to use. This helps businesses succeed online by reaching more people and providing a good experience for visitors.",
    },
    {
      title: "How Can Web Development services help my Product?",
      answer:
        "Web development services can boost your product by creating a user-friendly website. They enhance online visibility, engage customers, and make your brand more accessible in the digital world.",
    },
    {
      title: "Can you help us develop a website?",
      answer:
        "We need help creating an easy-to-use and attractive online presence for our business.",
    },
    {
      title: "Can you assist with app and website redesign?",
      answer:
        "Yes, we can help make your app or website better. We're good at making things look nice and work well. Let's talk about what you want to achieve, and we'll make it happen!",
    },
    {
      title:
        "Do you offer ongoing support after completing the web development project?",
      answer:
        "Yes, we provide continuous support even after finishing your website. If you need any further assistance or updates, just reach out to us!",
    },
    {
      title: "What do you need to begin?",
      answer:
        "To start, we'll need some basic information from you. This might include your ideas, content, and any specific requirements you have in mind. Let's discuss what you envision, and we'll take it from there!",
    },
    {
      title:
        "I don't have any plans for how I want my website to look. Can you help?",
      answer:
        "Don't worry if you don't have any ideas yet. We can guide you through the process and help you figure out what will work best for your website. Just let us know your goals and preferences, and we'll take it from there!",
    },
    {
      title:
        "I need more pages or screens for my project. How can I request them?",
      answer:
        "If you need additional pages or screens, simply let us know how many and what content or features you'd like to include. We'll work with you to expand your project scope accordingly. Just inform us of your requirements, and we'll get started on adding those extra pages or screens!",
    },
  ],
};
