const TeamCard = ({ image, name, designation }) => {
  return (
    <div
      className="max-w-xs mx-auto bg-darkcolor p-4 transition-transform transform hover:scale-95 duration-500 cursor-pointer rounded-lg border-[1px] shadow-lg"
      style={{ height: "500px" }}
    >
      <div
        className="mb-4 border-gray-300 overflow-hidden p-4 rounded-lg"
        style={{ height: "330px" }}
      >
        <img
          src={image === " " ? "https://i.ibb.co/rfYRs9F/ceo2.jpg" : image}
          alt={name}
          className="w-full h-full object-cover object-center transition-transform transform rounded-lg"
          style={{ objectFit: "cover", height: "100%" }}
        />
      </div>
      <div className="text-center py-4">
        <h2 className="text-xl text-white-800 font-bold mb-2">{name}</h2>
        <p className="text-lg text-white-600">{designation}</p>
      </div>
    </div>
  );
};

export default TeamCard;
