import React from "react";
import icon1 from "../../assets/Photo/icon1.png";
import icon2 from "../../assets/Photo/icon2.png";
import icon3 from "../../assets/Photo/icon5.png";
import icon4 from "../../assets/Photo/icon4.png";

export const Work = () => {

  const ourWorks = [
    {
      id: 1,
      name: "Explore",
      icon: icon1,
    },
    {
      id: 2,
      name: "Clarify",
      icon: icon2,
    },
    {
      id: 3,
      name: "Prototype",
      icon: icon3,
    },
    {
      id: 4,
      name: "Implement",
      icon: icon4,
    },
  ];
  return (
    <section
      className="lg:min-h-[80vh] py-[100px] flex items-center bg-cover bg-center bg-no-repeat relative bg-fixed overflow-hidden"
      style={{
        backgroundImage:
          "url('https://i.ibb.co/h7TL9hY/abstract-background-mountain-wireframe-landscape-futuristic-background-1-65dffc573658d.webp')",
      }}
    >
      <div className="container relative z-20 text-white">
        {/* heading */}
        <div className="flex lg:justify-between lg:items-center lg:flex-row flex-col xl:gap-0 gap-7  h-full w-full pb-12">
          <div
            className="service-title-font-size font-bold"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <h4 className="text-white">How</h4>
            <div className=" xl:text-end  xl:mr-56">
              <h2 className="gradientText">We Work</h2>
            </div>
          </div>
          <div
            className=" flex-1 flex justify-end xl:items-end "
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <p className="lg:text-2xl text-base  md:w-8/12 lg:w-full xl:mr-0 mr-auto font-medium xl:pl-40 2xl:pl-52">
              Turning ideas into amazing products - we take your concepts and
              turn them into reality, guiding you every step of the way.
            </p>
          </div>
        </div>

        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-10 sm:mt-16"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          {ourWorks.map((item) => (
            <div
              key={item.id}
              className="border  border-white rounded-lg p-16 hover:bg-darkColor hover:cursor-pointer hover:duration-500 "
            >
              <p className="text-white text-xl md:text-3xl text-center mb-6">
                {item.name}
              </p>
              <img
                className="mx-auto md:h-auto md:w-auto h-10 w-10"
                src={item.icon}
                alt="about our work"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-black/30 backdrop-blur absolute top-0 left-0 w-full h-full z-10"></div>
    </section>
  );
};
