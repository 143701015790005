import { coreValues } from './ourValues'
import { CommonSectionHeading } from './CommonSectionHeading'
import { CommonSectionCard } from './CommonSectionCard'


export const CoreValues = () => {
    
    return (
        <section className='py-[120px] bg-[#E7F0EE] overflow-x-hidden'>
            <div className="container" data-aos="zoom-in" data-aos-duration="3000">
                <CommonSectionHeading
                    heading1="Our Core"
                    heading2="Values"
                    description="We work with you to understand your business goals, target audience, and the problem that your website."
                />

                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-[35px] gap-x-[60px] mt-20'>
                    {
                        coreValues.map(item => (
                            <CommonSectionCard key={item.id} item={item} />
                        ))
                    }
                </div>

            </div>
        </section>
    )
}

