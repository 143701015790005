export const Modal = ({ isOpen, handleModalOpen, children }) => {
  return (
    <div
      onClick={handleModalOpen}
      className={`fixed top-0 left-0 w-full z-[200] h-screen bg-gray-800/20 backdrop-blur-md flex items-center justify-center cursor-pointer ${
        isOpen ? "poup-animatin" : "scale-0 duration-300"
      }`}
    >
      <div className=" w-[90%] md:w-[60%] min-h-[100px] cursor-auto shadow-2xl poup-shadow rounded-lg  relative z-[150]">
        <button
          onClick={handleModalOpen}
          className="absolute -top-8 sm:-right-7 -right-5 scale-95 h-10 w-10 hover:bg-secondary duration-200 bg-gray-700 rounded-full flex justify-center items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="overflow-hidden rounded-lg">{children}</div>
      </div>
    </div>
  );
};
