import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../../components/button";
import { JobStyledBtn } from "./JobStyledBtn";
import { JobStyledContent } from "./JobStyledContent";
import { JobInfo } from "./JobInfo";
import { useRef, useState } from "react";

export const Job = ({ job }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const smoothRef = useRef(null);


  return (
    <div ref={smoothRef} className="bg-bgColor2 text-white p-10 rounded-2xl">
      <div className="grid grid-cols-1 lg:grid-cols-[90px_1fr] gap-8">
        <div className="flex flex-col items-center gap-2">
          <img src={job.image} alt="job" />
          <img
            className="lg:block hidden"
            src={Images.CustomArrow}
            alt="CustomArrow"
          />
          <div className="w-10 h-10 bg-[#FFDE00] rounded-full hidden place-items-center lg:grid">
            <span className="material-symbols-outlined text-bgColor2">
              trophy
            </span>
          </div>
          <div
            className={`${
              openDetails ? "flex" : "hidden"
            } flex-col -mr-20 mt-10 gap-8`}
          >
            <JobStyledBtn icon="star" btnText={job.experience} />
            {/* <JobStyledBtn icon="star" btnText={job.salary} /> */}
            <JobStyledBtn icon="star" btnText={job.deadline} />
          </div>
        </div>

        <div>
          <div className="flex sm:flex-row flex-col gap-6 justify-between sm:items-center border-b border-[#095442] pb-4">
            {/* first row */}
            <div>
              <h4 className="md:text-[32px] text-2xl font-medium mb-3">
                {job.title}
              </h4>
              <div className="flex md:flex-row flex-col gap-4 lg:gap-8">
                <JobStyledContent icon="location_on" text={job.location} />
                <JobStyledContent icon="timer" text={job.workTime} />
                <JobStyledContent
                  icon="calendar_month"
                  text={job.workTimePerWeek}
                />
              </div>
            </div>
            <ButtonArrowTop name="Apply Now" />
          </div>
          {/* second row */}
          <div
            className={`${
              openDetails ? "hidden" : "flex"
            } gap-6 justify-between sm:flex-row flex-col sm:items-center mt-20`}
          >
            <div className="flex md:flex-row flex-col gap-4 lg:gap-8">
              <JobStyledBtn icon="star" btnText={job.experience} />
              {/* <JobStyledBtn icon="star" btnText={job.salary} /> */}
              <JobStyledBtn icon="star" btnText={job.deadline} />
            </div>
            <div onClick={() => setOpenDetails(!openDetails)}>
              <div>
                <JobStyledBtn icon="zoom_in_map" btnText="View job" />
              </div>
            </div>
          </div>
          {/* initially hidden */}
          <div
            className={`${
              openDetails ? "flex" : "hidden"
            } flex-col gap-10 mt-20 ml-20`}
          >
            <JobInfo title="About Us" description={job.aboutUs} />
            <JobInfo title="Job Description" description={job.jobDescription} />
            <JobInfo title="The Role" description={job.role} />
            <JobInfo
              title="Job Requirements"
              description={job.requirements}
            ></JobInfo>
            <JobInfo
              title="Responsibilities"
              description={job.responsibilities}
            />
            <JobInfo
              title="Skills and Experience"
              description={job.skillsAndExperience}
            />
            <JobInfo title="Benefit" description={job.benefit} />

            <div className="space-y-4 text-base text-[#B0B0B0]">
              {job.outers !== undefined
                ? job?.outers.map((item, idx) => <p key={idx}>{item}</p>)
                : ""}

            </div>
              <JobInfo title="Office Location" description={job.officeLocation} />
              <JobInfo title="Be Noted" description={job.be_noted} />
            <div className="self-end">
              <div
                onClick={() => {
                  smoothRef.current?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setOpenDetails(!openDetails);
                }}
                className="flex items-center gap-1 text-lg font-medium bg-bgColor1 text-[#1D2939] px-8 py-3 cursor-pointer rounded-full"
              >
                <span>See Less</span>
                <span className="material-symbols-outlined font-bold text-2xl">
                  arrow_upward
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
