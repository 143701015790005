import React from "react";
import { ServicePageTitle } from "../servicePageTitle";
import { Images } from "../../utils/images";
import { UiUxAcoridan } from "../../pages/ui-ux/uiuxAcordian";

export const WhayChoseUS = ({
  title = "Why Choose Us!",
  services,
  question,
}) => {
  return (
    <section
      className="relative section-padding-services-page  "
      style={{
        backgroundImage: `url(${Images.whayChuseUsBanner})`,
        backgroundSize: "50%",
        backgroundPosition: "center 10%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container flex lg:flex-row flex-col justify-center lg:gap-0 gap-8 lg:justify-between  z-50 relative   ">
        <div className="flex-1 lg:text-start text-center    h-[100px] top-24 static lg:sticky">
          <ServicePageTitle>
            <h2 className="service-title-font-size ">{title}</h2>
          </ServicePageTitle>
        </div>
        <div className="   space-y-8   ">
          {services?.map(({ id, title, image }) => (
            <div
              key={id}
              className="p-8 bg-[#0C6E57] text-white rounded space-y-10 w-full lg:w-[520px]"
            >
              <img src={image} alt={`icon of ${title}`} />
              <h3 className="font-bold text-white text-xl sm:text-[32px] sm:leading-[40px]">
                {title}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <div className="service-section-background2 h-full w-full z-10  opacity-95 absolute top-0 left-0"></div>
      {/* Frequently Ask Question */}
      <UiUxAcoridan uiuxQuestion={question} />
    </section>
  );
};
