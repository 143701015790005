import React, { useState, useEffect } from "react";
import { ButtonArrowTop } from "../button";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import logo from "../../assets/logo.png";
import { ServiceMenu } from "./ServiceMenu";
import MobileNavbar from "./MobileNavbar";




export const Navbar = () => {
 
  const [isSticky, setIsSticky] = useState(false);
  const [openServices, setOpenServices] = useState(false);

  const [toogleNave, setToggleNav] = useState(false);
  // const location = useLocation();
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  const handleServiceButtonEnter = () => {
    setOpenServices(true);
  };

  const handleServiceButtonLeave = () => {
    setOpenServices(false);
  };

 

  const handleSubmenuItemClicked = () => {
    setOpenServices(false);

   

    window.scroll({
      top: 0,
    });
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (window.scrollY > -500) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

 

  return (
    <>
      <header
  
        className={`z-[80] text-xl font-medium ${
          isSticky
            ? `sticky top-0 h-[80px] duration-500 flex items-center bg-darkColor backdrop-blur-md text-white transition-all ease-in-out delay-100`
            : "relative py-4 bg-darkColor text-white transition-all duration-500 ease-in-out"
        }`}
      >
        <div className="xl:container px-4 z-[70] w-full  ">
          <div className="flex justify-between items-center  z-50 relative">
            <Link to="/">
              <img
                className="sm:w-[320px] w-52"
                src={logo}
                alt="logo"
                onClick={handleClick}
              />
            </Link>

            <div className=" xl:block hidden  ">
              <ul
                id="main-nav"
                className="flex  justify-center items-center gap-6 xl:gap-10"
              >
                <li>
             
                  <NavLink to="/" onClick={handleClick}>
                    {" "}
                    Home
                  </NavLink>
                </li>
                <li className="relative group">
                  <div className="flex items-center gap-2 cursor-pointer ">
                    <NavLink to="/about" onClick={handleClick}>
                      About
                    </NavLink>
                    <span
                      className={`flex justify-center items-center h-6 w-6  rounded-full  relative group-hover:rotate-180 duration-300 transition-transform bg-white text-darkColor
                      `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`w-4 h-4 `}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="rounded-lg z-10 group-hover:scale-100 group-hover:opacity-100 scale-0 opacity-0 duration-300 ease-out transition-all absolute top-[25px] bg-transparent -left-1/2">
                    <ul className="bg-darkColor text-white w-[150px] px-5 py-3 mt-10">
                      <li className="border-b-2 border-[#343434] py-5">
                        <NavLink to="/about" onClick={handleClick}>
                          About Us
                        </NavLink>
                      </li>
                      <li className="py-5">
                        <NavLink to="/team" onClick={handleClick}>
                          Team
                        </NavLink>
                      </li>
                      {/* <li className="py-5">
                        <NavLink
                          onClick={() => {
                            window.scroll({
                              top: 0,
                            });
                          }}
                          to="/testimonial"
                        >
                          Testimonial
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li>
                  <NavLink to="/our-works" onClick={handleClick}>
                    Our Works
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/career" onClick={handleClick}>
                    Career
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="xl:flex hidden  items-center gap-8 justify-self-end  ">
              <button
                id="service-menu-btn"
                className="flex items-center gap-3 "
                onMouseEnter={ (e) => {
                   e.stopPropagation();
                   handleServiceButtonEnter();
                  }
                }
              >
                <span>Services</span>
                <span
                  className={`h-6 w-6 rounded-full  flex justify-center items-center relative transition-transform duration-200 ${
                    openServices ? "rotate-180 bg-whiteColor " : ""
                  }
                   bg-white text-darkColor
                      
                  `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 ${
                      openServices ? "text-darkColor" : ""
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>
                </span>
              </button>
              <Link to="contact" onClick={handleClick}>
                <ButtonArrowTop name="Contact Us" />
              </Link>
            </div>

            {/* Hamburger Menu */}
            <button
              onClick={() => setToggleNav(!toogleNave)}
              className=" sm:px-3 p-1 sm:py-2 transition-all duration-300  shadow rounded-md gap-1 border active:scale-95  justify-center items-center xl:hidden flex z-50 relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <span className="sm:block hidden">Menu</span>
            </button>
          </div>
        </div>

        {/* Service Menu */}
        <div id="service-menu-container"
          onMouseLeave={(e) => {
            e.stopPropagation();
            handleServiceButtonLeave();
          }}
     
          className={`absolute w-full top-0 left-0 z-40 duration-1000 overflow-hidden transition-all bg-darkColor ${
            openServices ? "h-[700px] " : "h-0 "
          }`}
        >
          <ServiceMenu openServices={openServices}   handleSubmenuItemClicked={handleSubmenuItemClicked} />
        </div>
      </header>

      {/* Responsive Mobile Menu */}
      <MobileNavbar toogleNave={toogleNave} setToggleNav={setToggleNav}/>
      
    </>
  );
};

