import React from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import TeamCard from "./teamCard";
import { SmtDefenders } from "../../utils/teamData";

export const DefenderssDev = () => {
  return (
    <>
      <section className="">
        <div className="container flex items-center justify-center text-center ">
          <h2 className="team-page-title">SMT DEFENDERS TEAM</h2>
        </div>

        <div className="container">
          <Swiper
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true} // Set loop to true for infinite loop
            loopFillGroupWithBlank={true} // Fill group with additional slides
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {SmtDefenders.map((members, index) => (
              <SwiperSlide key={index}>
                <TeamCard {...members}></TeamCard>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};
