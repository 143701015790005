import { useEffect, useRef, useState } from "react";
import { SubmitArrowTop } from "../submit";
import { InputFiled } from "../inputFiled";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Swal from "sweetalert2";

export const ProjectInMind = () => {
  const [value, setValue] = useState("");
  const [subCategory, setSubCategroy] = useState("not given");
  const textareaRef = useRef(null);
  const [services2, setServices] = useState([]);
  const [selecteService, setSelectedService] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { isLoading, error, data } = useQuery({
    queryKey: ["services"],
    queryFn: () =>
      axios.get("./sercices.json").then((response) => response.data),
  });

  useEffect(() => {
    // Automatically adjust textarea height on mount and update
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Set initial height to 1px
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (selecteService === "Select Your Service" || selecteService === "") {
      setIsDisabled(true);
    } else {
      const fileterData = data?.filter(
        (item) => item.category.toLowerCase() === selecteService
      );

      //sub category disabled if not available --start
      let hasSubService = false;
      for (let i = 0; i < fileterData.length; i++) {
        if (fileterData[i].subServices.length) hasSubService = true;
      }
      //--end

      setServices(fileterData);

      setIsDisabled(!hasSubService);
    }
  }, [selecteService, data]);

  const handleSelect = (e) => {
    setSelectedService(e.target.value.toLowerCase());
  };

  const handleSubCategory = (e) => {
    if (e.target.value === "Select Your Categroy") {
      setSubCategroy("not given");
    } else {
      setSubCategroy(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const company_name = formData.get("company_name") || "not given";
    const message = formData.get("message");

    try {
      const res = await axios.post(
        "https://smt-server-rho.vercel.app/contact-us",
        {
          name,
          email,
          company_name,
          service: selecteService,
          category: subCategory,
          message,
        }
      );

      if (res.data.success) {
        Swal.fire({
          title: res.data.message,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: res.data.message,
          icon: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: error.message,
        icon: "error",
      });
    }
  };

  if (isLoading) return <h1>Loading...</h1>;
  if (error) return <h1>{error}</h1>;
  return (
    <section className="bg-bgColor2 py-[120px]">
      <div className="container">
        <div className="">
          <h2 className=" text-xl font-bold md:text-4xl  md:leading-[60px] text-[#FFDE00] text-center pb-10">
            ‍Ready to take your online business to the next level?
            <br />
            Join us at SM Technology, and we will deliver results,
            <br /> NOT excuses!
          </h2>
        </div>
        <div data-aos="zoom-in" data-aos-duration="3000">
          <div className="pl-[20px] pr-[30px] md:pl-[60px] md:pr-[120px] py-[60px] bg-darkColor/20 text-white rounded-[40px]">
            <h2 className="sm:text-3xl text-xl md:text-6xl font-bold pb-4">
              Project In Mind ?
            </h2>
            <form
              autoCapitalize="off"
              onSubmit={handleSubmit}
              className="mt-12 grid md:flex flex-col gap-12"
            >
              <div className="grid md:flex items-center gap-8">
                <InputFiled
                  name="name"
                  type="text"
                  required={false}
                  placeholder="Your Name"
                  label="Name"
                  className={"border-white/70"}
                  className2={`text-white/70 peer-focus:after:border-white`}
                ></InputFiled>

                <InputFiled
                  name="company_name"
                  type="text"
                  required={false}
                  placeholder="Walmart"
                  label="Company Name"
                  className={"border-white/70"}
                  className2={`text-white/70 peer-focus:after:border-white`}
                ></InputFiled>
              </div>

              <div className="grid md:flex  items-center gap-8">
                <div className="w-full relative">
                  <select
                    required
                    onChange={handleSelect}
                    placeholder="Select Your Service"
                    className="w-full peer h-full  border-b-2 border-white/70 bg-transparent pt-4 pb-1.5 font-family  font-medium text-lg text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:text-white rounded-t-none rounded-b-none   focus:border-gray-500 text-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  >
                    <option
                      className="text-gray-500 font-medium rounded-none bg-bgColor2"
                      defaultValue={"Select Your Service"}
                    >
                      Select Your Service
                    </option>
                    {services?.map((item, idx) => (
                      <option
                        key={idx}
                        className="rounded-none bg-bgColor2"
                        value={item}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                  <label className="after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-900 transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-white peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1">
                    <p className="mb-8 flex items-center gap-1 text-white/70 text-xl font-semibold">
                      <span>Service Required</span>
                      <span className="text-red-500">*</span>
                    </p>
                  </label>
                </div>
                <div className="w-full relative">
                  <select
                    onChange={handleSubCategory}
                    required
                    disabled={isDisabled}
                    placeholder="Select Your Service"
                    className={`
              w-full peer h-full  border-b-2 border-white/70 bg-transparent pt-4 pb-1.5 font-family  font-medium text-lg text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:text-white rounded-t-none rounded-b-none   focus:border-gray-500 text-white/70 focus:outline-0  disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100
              ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
              `}
                  >
                    <option
                      className="text-white/70 bg-bgColor2 font-medium rounded-none"
                      defaultValue={"Select Your Service"}
                    >
                      Select Your Categroy
                    </option>

                    {services2.length > 0
                      ? services2[0].subServices.map((item, idx) => (
                          <option
                            key={
                              idx + "_" + item.replace(" ", "").toLowerCase()
                            }
                            className="rounded-none bg-bgColor2"
                            value={item}
                          >
                            {item}
                          </option>
                        ))
                      : ""}
                  </select>
                  <label className="after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-white/70 transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-white peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1">
                    <p
                      className={`mb-8 flex items-center gap-1  text-xl font-semibold ${
                        isDisabled ? " text-gray-500" : "  text-white/70"
                      }`}
                    >
                      <span>Category</span>
                      {/* <span className="text-red-500">*</span> */}
                    </p>
                  </label>
                </div>
              </div>
              <InputFiled
                name="email"
                type="email"
                required={true}
                placeholder="you@example.com"
                label="Email"
                className={"border-white/70"}
                className2={`text-white/70 peer-focus:after:border-white`}
              ></InputFiled>
              <div className="relative w-full">
                <label className="">
                  <p className="mb-8 flex items-center gap-1 text-white/70 text-xl font-semibold">
                    <span>Project Details</span>
                    <span className="text-red-500">*</span>
                  </p>
                </label>
                <textarea
                  placeholder="Tell us more about idea"
                  required
                  ref={textareaRef}
                  value={value}
                  onChange={handleChange}
                  name="message"
                  className="disabled:resize-none appearance-none scrollbar-hide peer h-full w-full border-b-2 border-white/70  bg-transparent    outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-white focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50  text-lg font-medium resize-none"
                ></textarea>
              </div>
              <div className="mt-6">
                <SubmitArrowTop disabled={loading} name="Send Inquiry" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const services = [
  "UI UX Design",
  "Web Development",
  "Email Marketing",
  "Lead Generation",
  "Data Entry",
];
