import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../../components/button";
import { WorkCard2 } from "../home/WorkCard2";
import { works } from "../../utils/postData";

export const LatestWork2 = () => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  return (
    <section className="sm:pt-[60px]   px-0">
      <div className="sm:container">
        <div className="font-black text-7xl">
          {/* <h2 className='mb-4 text-secondary'>Look at Our</h2>
                    <h2 className='text-[#343434]'>Recent Work</h2> */}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 my-14">
          {works.slice(0, 6).map((item) => (
            <WorkCard2 key={item.id} item={item} />
          ))}
        </div>
        <div className="flex items-center justify-center h-full py-8">
          <Link to="/our-works" onClick={handleClick}>
            <ButtonArrowTop name="View all works" />
          </Link>
        </div>
      </div>
    </section>
  );
};

// const currentDate = new Date().toLocaleDateString("en-US", {
//   weekday: "long",
//   month: "long",
//   day: "numeric",
// });

// const works = [
//   {
//     id: 1,
//     image: Images.CardGal1,
//     title: "Yoga & Fitness Mobile App UI",
//     description:
//       "Yoga app designed to bring tranquility and simplicity to your wellness journey.",
//     category: ["ux/ui design", "App Design"],
//     client: "Fitness Innovations Inc.",
//     live_view: "https://example.com/yoga-app-live",
//     timelines: "3 months",
//     service_we_provide: ["UI/UX Design", "App Development"],
//     project_details: {
//       problems:
//         "The client needed a mobile app that could cater to both beginners and advanced users of yoga and fitness routines.",
//       challenges:
//         "Designing an intuitive interface that could seamlessly guide users through various yoga poses and fitness exercises.",
//       our_solutions:
//         "We conducted thorough user research to understand the target audience's needs and preferences. We then developed a user-friendly interface with instructional videos and step-by-step guides for different yoga routines.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 2,
//     image: Images.CardGal2,
//     title: "Interior Agency UI Design",
//     description:
//       "Our website unveils a curated showcase of captivating interior designs, reflecting timeless aesthetics and innovative solutions.",
//     category: ["ux/ui design", "web design"],
//     client: "Interior Design Solutions LLC",
//     live_view: "https://example.com/interior-agency-live",
//     timelines: "2.5 months",
//     service_we_provide: ["UI/UX Design", "Web Development"],
//     project_details: {
//       problems:
//         "The client required a website to showcase their portfolio of interior designs and attract potential clients.",
//       challenges:
//         "Balancing aesthetic appeal with functionality to ensure a seamless user experience.",
//       our_solutions:
//         "We collaborated closely with the client to understand their brand identity and vision. Our team implemented a clean and modern design with easy navigation and high-resolution images to showcase their work effectively.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 3,
//     image: Images.CardGal4,
//     title: "Gameborse",
//     description:
//       "Gameborse, our gaming website, Check out our latest work on Gameborse, the gaming website We created.",
//     category: ["web design", "web development"],
//     client: "Gameborse Gaming Ltd.",
//     live_view: "https://example.com/gameborse-live",
//     timelines: "4 months",
//     service_we_provide: ["Web Design", "Web Development"],
//     project_details: {
//       problems:
//         "Creating an engaging platform for gamers to interact and share experiences.",
//       challenges:
//         "Integrating various gaming elements while maintaining website performance.",
//       our_solutions:
//         "We utilized modern web technologies to create a dynamic and responsive gaming platform. Our team focused on optimizing loading times and ensuring smooth gameplay experiences.",
//     },
//     publish_date: currentDate,
//   },

//   {
//     id: 5,
//     image: Images.CardGal52,
//     title: "Juicy Oasis",
//     client: "Email Marketing Pro LLC",
//     description:
//       "Discover the oasis of flavor at Juicy Oasis, where each sip is a journey through tantalizing tastes.",
//     live_view: "https://hugo.smtech24.com/",
//     category: ["UI/UX Design, App Design"],
//     timelines: "2 months",
//     service_we_provide: ["Email Campaigns", "Marketing Automation"],
//     project_details: {
//       problems:
//         "Increasing engagement and conversion rates through email marketing campaigns.",
//       challenges: "Standing out in crowded inboxes and avoiding spam filters.",
//       our_solutions:
//         "We crafted personalized and visually appealing email campaigns tailored to each client's target audience. Our team also implemented segmentation and automation techniques to deliver timely and relevant content to subscribers.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 6,
//     image: Images.CardGal42,
//     title: "PetPantry: Nourish & Nurture",
//     client: "Email Marketing Pro LLC",
//     description:
//       "Caring for pets with premium food and expert care essentials, ensuring their health and happiness.",
//     live_view: "https://letyourpetdecide.com/",
//     category: ["Web Design", "Web Development"],
//     service_we_provide: ["Email Campaigns", "Marketing Automation"],
//     project_details: {
//       problems:
//         "Increasing engagement and conversion rates through email marketing campaigns.",
//       challenges: "Standing out in crowded inboxes and avoiding spam filters.",
//       our_solutions:
//         "We crafted personalized and visually appealing email campaigns tailored to each client's target audience. Our team also implemented segmentation and automation techniques to deliver timely and relevant content to subscribers.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 7,
//     image: Images.CardGal62,
//     title: "MuscleMart",
//     description:
//       "MuscleMart offers top-tier gym accessories and bodybuilding supplements, empowering athletes to reach peak performance.",
//     live_view: "https://albertogell.smtech24.com/",
//     category: ["UI/UX Design, App Design"],
//     timelines: "2 months",
//     service_we_provide: ["Email Campaigns", "Marketing Automation"],
//     project_details: {
//       problems:
//         "Increasing engagement and conversion rates through email marketing campaigns.",
//       challenges: "Standing out in crowded inboxes and avoiding spam filters.",
//       our_solutions:
//         "We crafted personalized and visually appealing email campaigns tailored to each client's target audience. Our team also implemented segmentation and automation techniques to deliver timely and relevant content to subscribers.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 8,
//     image: Images.Work5,
//     title: "B2B Lead Generation",
//     description:
//       "We specialize in finding potential customers for your business. Our B2B lead generation services identify and connect you with companies interested in your products or services.",
//     category: ["B2B", "lead generation"],
//     client: "LeadGen Solutions LLC",
//     live_view: "https://example.com/lead-gen-live",
//     timelines: "3 months",
//     service_we_provide: ["Lead Generation", "Market Analysis"],
//     project_details: {
//       problems:
//         "Generating qualified leads for businesses targeting specific industries and demographics.",
//       challenges:
//         "Developing targeted marketing strategies to reach decision-makers within target companies.",
//       our_solutions:
//         "We employed data-driven approaches to identify potential leads and tailored marketing campaigns to address their specific needs and pain points. Our team also conducted thorough market analysis to refine lead generation strategies.",
//     },
//     publish_date: currentDate,
//   },
//   {
//     id: 9,
//     image: Images.Work6,
//     title: "Email Marketing",
//     description:
//       "We create engaging email campaigns to connect with your audience, share updates, and drive meaningful interactions, helping you build lasting relationships and drive results.",
//     category: ["Mail Chimp", "lead generation"],
//     client: "Email Marketing Pro LLC",
//     live_view: "https://example.com/email-marketing-live",
//     timelines: "2 months",
//     service_we_provide: ["Email Campaigns", "Marketing Automation"],
//     project_details: {
//       problems:
//         "Increasing engagement and conversion rates through email marketing campaigns.",
//       challenges: "Standing out in crowded inboxes and avoiding spam filters.",
//       our_solutions:
//         "We crafted personalized and visually appealing email campaigns tailored to each client's target audience. Our team also implemented segmentation and automation techniques to deliver timely and relevant content to subscribers.",
//     },
//     publish_date: currentDate,
//   },

//   {
//     id: 4,
//     image: Images.Work4,
//     title: "Data Entry & Web Research",
//     client: "Research Solutions Inc.",
//     description:
//       "Efficient data entry and thorough web research services to help organize information and gather valuable insights.",
//     category: ["data entry", "Web Research"],
//     client: "Research Solutions Inc.",
//     live_view: "https://example.com/data-research-live",
//     timelines: "2 months",
//     service_we_provide: ["Data Entry", "Web Research"],
//     project_details: {
//       problems:
//         "Managing large volumes of data and conducting in-depth research within tight deadlines.",
//       challenges:
//         "Ensuring accuracy and reliability of collected data while navigating through vast amounts of information.",
//       our_solutions:
//         "We implemented automated data entry processes and utilized advanced search techniques to streamline research efforts. Our team also employed quality control measures to maintain data integrity.",
//     },
//     publish_date: currentDate,
//   },
//   // Add similar objects for the remaining projects...
// ];

// import React from "react";
// import { Link } from "react-router-dom";
// import { Images } from "../../utils/images";
// import { WorkCard } from "./WorkCard";
// import { ButtonArrowTop } from "../../components/button";

// export const LatestWork2 = () => {
//     return (
//         <section className="sm:pt-[60px] px-0">
//             <div className="sm:container">
//                 <div className="font-black text-7xl">
//                     {/* <h2 className='mb-4 text-secondary'>Look at Our</h2>
//                     <h2 className='text-[#343434]'>Recent Work</h2> */}
//                 </div>

//                 <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 my-14">
//                     {works.map((item) => (
//                         <Link key={item.id} to={`/work/${item.id}`}>
//                             <WorkCard item={item} />
//                         </Link>
//                     ))}
//                 </div>
//                 <div className="flex items-center justify-center h-full py-8">
//                     <Link
//                         onClick={() => {
//                             window.scroll({
//                                 top: 0,
//                             });
//                         }}
//                         to="/our-works"
//                     >
//                         <ButtonArrowTop name="View all works" />
//                     </Link>
//                 </div>
//             </div>
//         </section>
//     );
// };

// const works = [
//     {
//         id: 1,
//         image: Images.CardGal1,
//         title: "Yoga & Fitness Mobile App UI",
//         description:
//             "Yoga app designed to bring tranquility and simplicity to your wellness journey.",
//         category: ["UI/UX Design, App Design"],
//     },
//     {
//         id: 2,
//         image: Images.CardGal2,
//         description:
//             "Our website unveils a curated showcase of captivating interior designs, reflecting timeless aesthetics and innovative solutions.",
//         category: ["UI/UX Design, Web Design"],
//     },
//     {
//         id: 3,
//         image: Images.CardGal4,
//         title: "Gameborse",
//         description:
//             "Gameborse, our gaming website, Check out our latest work on Gameborse, the gaming website We created.",
//         category: ["Web Design", "Web Development"],
//     },
// ];

// Assuming you have a component to display details of a single work (e.g., WorkDetail)
// Import the component and set up your routes in the App component or route configuration.
