import { CommonHeading } from "../../components/commonHeading";

export const CareerHero = () => {

  const handleScrollJobSection = () => {
    const jobSection = document.getElementById('job-section');
    jobSection.scrollIntoView({behavior: 'smooth'})
    
  }
  return (
    <section
      className="min-h-screen pt-[120px] relative bg-no-repeat bg-cover bg-center text-white"
      style={{
        backgroundImage: `url('https://i.ibb.co/QJn7fdS/Hero-Section-Bg.webp')`,
      }}
    >
      <div className="container  h-full ">
        <CommonHeading
          handleScrollJobSection={handleScrollJobSection}
          heading="Career with"
          subHeading="SM Technology"
          description="Looking for a career that's all about making, shaping, and celebrating the extraordinary? then you are home!"
          btnContent="Join Our Team"
        />
        <div  className="w-full lg:h-[684px] rounded-lg mt-[100px] bg-green-600 lg:static absolute bottom-0 left-0">
        <img
        className="object-cover w-full h-full"
          src="https://i.ibb.co/42ppzvf/kam-Sales-Oriantation.jpg"
          alt="career hero"
        />
        </div>
      </div>
    </section>
  );
};
