import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Images } from "../../utils/images";
import { WorkCard } from "./WorkCard";
import { ButtonArrowTop } from "../../components/button";
import { works } from "../../utils/postData";

export const LatestWorks = () => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  const [currentTab, setCurrentTab] = useState("all");
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (currentTab.toLowerCase() === "all" || !currentData.length) {
      setCurrentData(works);
      return;
    }

    const filterData = works.filter((item) =>
      item.category
        .map((cat) => cat.toLowerCase())
        .includes(currentTab.toLowerCase())
    );
    setCurrentData(filterData);
  }, [currentTab]);

  return (
    <section className="sm:pt-[60px] px-0">
      <div className="container">
        <div className="font-black text-7xl">
          {/* <h2 className='mb-4 text-secondary'>Look at Our</h2>
          <h2 className='text-[#343434]'>Recent Work</h2> */}
        </div>

        <div className="pt-16 flex justify-center items-center gap-6 flex-wrap">
          {menu.map((item) => (
            <button
              onClick={() => setCurrentTab(item.tab)}
              key={item.id}
              className={`
              bg-bgColor1 text-[#555555] md:text-xl text-base  font-semibold px-6 py-3 rounded-full hover:bg-secondary hover:text-white transition-all ease-in-out duration-300
              ${currentTab === item.tab ? "bg-secondary text-white" : ""}
              `}
            >
              {item.title}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-14">
          {currentData.map((item) => (
            <WorkCard key={item.id} item={item} />
          ))}
        </div>
        <div className="flex items-center justify-center h-full py-8">
          <Link to="/our-works" onClick={handleClick}>
            <ButtonArrowTop name="View all works" />
          </Link>
        </div>
      </div>
    </section>
  );
};

const menu = [
  {
    id: 1,
    title: "All",
    tab: "all",
  },
  {
    id: 2,
    title: "UI/UX Design",
    tab: "UI/UX Design",
  },
  {
    id: 3,
    title: "Web Design",
    tab: "web design",
  },
  {
    id: 4,
    title: "Web Development",
    tab: "web development",
  },
  {
    id: 5,
    title: "Data Entry & Web Research",
    tab: "data entry",
  },
  {
    id: 6,
    title: "B2B Lead Generation",
    tab: "lead generation",
  },
];
