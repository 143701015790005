import { ServicePageTitle } from "../../../components/servicePageTitle";
import { Images } from "../../../utils/images";


const TimeLineTextContainer = ({ text, className }) => {
  return (
    <div className="sm:p-8 p-4 bg-bgColor2 text-lg sm:text-2xl relative z-20 font-medium text-white rounded-lg">
      <h5>{text}</h5>
    </div>
  );
};

export const StragicWorking = ({
  subTitle = "Our Working Process",
  title,
  process,
}) => {

  return (
    <section className="py-[160px] bg-[#0b0b0b] relative overflow-x-hidden">
      <div className="container py-10">
        <div
          className="flex flex-col justify-center items-center text-center mb-[110px] aos-init aos-animate"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <h3 className="text-[#FFF192] font-medium text-xl flex items-center gap-2 mb-6">
            <span className="bg-[#FFF192] h-[2px] w-8 block"></span>Our Working
            Process
          </h3>
          <h2 className="xl:text-[88px] text-3xl sm:text-4xl md:text-6xl font-bold bg-gradient-to-b from-white to-rgbaTeal40 bg-clip-text text-transparent leading-[40px] md:leading-[100px] pb-5">
            <div className="xl:space-y-3">
              {/* <span className="block">{subTitle}</span> */}
              <span className="block">{title}</span>
            </div>
          </h2>
        </div>
        <div
          className="relative 2xl:hidden block aos-init"
          data-aos="zoom-out"
          data-aos-duration="3000"
        >
          <div className="mb-8 justify-center flex">
            <div className="h-[130px] w-[130px] bg-secondary rounded-full flex justify-center items-center text-center">
              <h5 className="text-white text-lg font-medium">
                Client Brief & Instruction
              </h5>
            </div>
          </div>
          <div className="space-y-8 relative z-20">
            <div className="sm:p-8 p-4 bg-bgColor2 text-lg sm:text-2xl relative z-20 font-medium text-white rounded-lg">
              <h5>UX Research</h5>
            </div>
            {
              process.map((item, idx) => (
                <div key={idx} className="sm:p-8 p-4 bg-bgColor2 text-lg sm:text-2xl relative z-20 font-medium text-white rounded-lg">
                 <h5>{item}</h5>
                </div>
              ))
            }
       
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
              <div className="bg-gradient-to-b from-[#FFF192] to-secondary w-[4px] h-[115%]"></div>
              <span className="block bg-[#FFF192] h-4 w-4 rounded-full absolute -top-16 sm:-top-20 left-1/2 -translate-x-1/2 "></span>
              <span className="absolute -bottom-[57px] left-1/2 -translate-x-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-7 h-7 text-secondary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          <div className="flex justify-center items-end mt-20">
            <div className="h-[130px] w-[130px] bg-[#FFDE00] rounded-full flex justify-center items-center text-center 2xl:mr-[135px]">
              <h5 className="text-darkColor text-xl font-bold">Support</h5>
            </div>
          </div>
        </div>
        <div
          className="relative 2xl:block hidden aos-init aos-animate"
          data-aos="zoom-out"
          data-aos-duration="3000"
        >
          <div className="h-full w-full -top-2 absolute 2xl:block hidden">
            <div className="h-[130px] w-[130px] bg-secondary rounded-full -ml-[70px] 2xl:ml-[50px] flex justify-center items-center text-center">
              <h5 className="text-white text-lg font-medium">
                Client Brief & Instruction
              </h5>
            </div>
            <div className="h-full flex justify-end w-full">
              <div className="h-[130px] w-[130px] bg-[#FFDE00] rounded-full flex justify-center items-center text-center 2xl:mr-[135px]">
                <h5 className="text-darkColor text-xl font-bold">Support</h5>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-[42px] relative bg-center bg-no-repeat working-proccess-backgrund-line">
            <div className="relative w-full h-full flex justify-center gap-8 overflow-visible">
              {
                process.slice(0, 4).map((item, idx) => (
                  <div key={idx} className="sm:p-8 p-4 bg-bgColor2 text-lg sm:text-2xl relative z-20 font-medium text-white rounded-lg">
                <h5>{item}</h5>
              </div>
              
                ))
              }
            </div>
            <div className="flex gap-7 justify-center w-full pr-16">
              {
                process.slice(4).map((item, idx) => (
                  <div key={idx} className="sm:p-8 p-4 bg-bgColor2 text-lg sm:text-2xl relative z-20 font-medium text-white rounded-lg">
                <h5>{item}</h5>
              </div>
                ))
              }
              
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute hidden overflow-x-hidden -top-16 right-0"
        src="/static/media/working-process-icon.7829c83426210c34f1ca.png"
        alt="workign procss icon"
      />
    </section>
  );
};
