import React from "react";

const Gallery = () => {
  return (
    <section className="bg-[#E8F3F0] pt-16 pb-20 md:pb-24 "> 
      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 xl:grid-cols-3">
        <div className="col-span-1 xl:col-span-2 xl:h-[48vh] overflow-hidden  rounded-xl">
          <img
            src="https://i.ibb.co/7nS5P4g/jpeg-optimizer-Gallery3.jpg"
            alt="gallery 1"
            className="h-full w-full object-cover hover:scale-125 duration-1000"
          />
        </div>
        <div className="col-span-1  xl:h-[48vh] overflow-hidden rounded-xl">
          <img
            src="https://i.ibb.co/6XvHGJt/Gallery4.jpg"
            alt="gallery 2"
            className="h-full w-full object-cover hover:scale-125 duration-1000"
          />
        </div>
        <div className="col-span-1  xl:h-[48vh] overflow-hidden rounded-xl">
          <img
            src="https://i.ibb.co/FhZy9p9/gallery-6.webp"
            alt="gallery 2"
            className="h-full w-full object-cover hover:scale-125 duration-1000"
          />
        </div>
        <div className="col-span-1 xl:col-span-2 xl:h-[48vh] overflow-hidden rounded-xl">
          <img
            src="https://i.ibb.co/NCjh57v/Gallery5.jpg"
            alt="gallery 2"
            className="h-full w-full object-cover hover:scale-125 duration-1000"
          />
        </div>
      </div>
    </section>
  );
};

const YourComponent = () => {
  const shouldRenderGallery = true;
  return (
    <div>
      {shouldRenderGallery && <Gallery />}
    </div>
  );
};

export default YourComponent;
