import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

export const Counter = () => {
  const [countStart, setCountStart] = useState(false);
  const counterContents = [
    {
      id: 1,
      count: 8,
      title: "Years of Experience",
    },
    {
      id: 2,
      count: 20000,
      title: "Project Completed",
    },
    {
      id: 3,
      count: 47,
      title: "Countries Served",
    },
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {counterContents.map((item) => (
        <div
          key={item.id}
          className="md:border-r-4 px-3 last:border-0 border-[#373635] text-center"
        >
          <h3 className="text-4xl lg:text-6xl gradientText mb-6 ">
            <ScrollTrigger
              onEnter={() => setCountStart(true)}
              onExit={() => setCountStart(false)}
            >
              {countStart && (
                <>
                  <CountUp end={item.count} duration={1} />
                  <span>+</span>
                </>
              )}
            </ScrollTrigger>
          </h3>
          <h4 className="text-xl lg:text-3xl font-semibold text-[#01211A]">
            {item.title}
          </h4>
        </div>
      ))}
    </div>
  );
};
