

export const OurSpecalityCard = ({ image, title, description }) => {
  return (
    <div className="flex md:w-full w-[95%] sm:w-[400px] mx-auto">
      <div className="card  bg-base-100 shadow-xl">
        <figure className="h-[240px]">
            <img className="w-full h-full" src={image} alt="Web-Design" />
        </figure>
        <div className="card-body">
          <h2 className="card-title font-bold text-2xl ">
            {title}
            {/* <div className="badge badge-secondary">Web Design</div> */}
          </h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
