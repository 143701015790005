import { Home } from "./pages/home";
import { FourOFour } from "./pages/404";
import { permittedRoutes } from "./routes";
import { MainLayout } from "./layouts/main.layout";
import { Navigate, useRoutes } from "react-router-dom";
import { About } from "./pages/about";
import { Team } from "./pages/team";
import { OurWorks } from "./pages/ourWorks";
import { Career } from "./pages/career";
import { Contact } from "./pages/contact";
import { UIUX } from "./pages/ui-ux";
import { Web } from "./pages/web-dev";
import { Email } from "./pages/email";
import { Lead } from "./pages/lead-gen";
import { Data } from "./pages/data-entry";

import { Blog } from "./pages/blog";
import { ErrorPage } from "./pages/error";

// Testing Start

//Testing End

export const App = () => {
  const mainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "*", element: <Navigate to="/404" /> },
      { path: "/error", element: <ErrorPage /> },
      { path: "/", element: <Home /> },
      { path: "/about", element: <About /> },
      { path: "/about", element: <About /> },
      { path: "/team", element: <Team /> },
      // { path: "/testimonial", element: <Testimonial /> },
      { path: "/:id", element: <Blog /> },
      { path: "our-works", element: <OurWorks /> },
      { path: "career", element: <Career /> },
      { path: "contact", element: <Contact /> },
      { path: "404", element: <FourOFour /> },
      { path: "ui-ux", element: <UIUX /> },
      { path: "web-development", element: <Web /> },
      { path: "email-marketing", element: <Email /> },
      { path: "lead-generation", element: <Lead /> },
      { path: "data-entry", element: <Data /> },
    ],
  };

  const routing = useRoutes([mainRoutes, ...permittedRoutes()]);

  return (
    <>
      {routing}
      {/* <ToastContainer /> */}
    </>
  );
};
