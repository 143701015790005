import { Images } from "./images";

// Email Marketing Specality content
export const emailMarketingSpecality = {
  title: ["Let us help you elevate", "your digital presence"],
  cardContent: [
    {
      id: 1,
      title: "Personalized Campaigns",
      description:
        "Tailoring content and messaging to individual preferences, maximizing engagement and effectiveness in email marketing.",
      image: "https://i.ibb.co/2sD1fzT/email-Card1-f3b0a049b97e5ad19927.jpg",
    },

    {
      id: 2,
      title: "Automated Workflows",
      description:
        "Web design is the art of crafting visually appealing and user-friendly websites for optimal online interaction.",
      image: "https://i.ibb.co/qJ7RDNY/email-Card2-05a34e002271ffbf1bfa.jpg",
    },

    {
      id: 3,
      title: "Targeted Segmentation",
      description:
        "Precision in categorizing and addressing specific audience groups, optimizing marketing efforts for personalized and relevant communication.",
      image: "https://i.ibb.co/BwKJ4kn/email-Card3-bdd9c79638ca3ee80dc8.jpg",
    },

    {
      id: 4,
      title: "Performance Analytics",
      description:
        "Leveraging data insights to assess, analyze, and optimize the effectiveness of digital strategies, decision-making and overall business performance.",
      image: "https://i.ibb.co/4jwFYW1/email-Card4-2cffe77f45ee30be08ef.webp",
    },
  ],
};

// Email Marketing Services
export const emailMarketingServices = {
  title: "Email Marketing Services",
  services: [
    {
      title: "Targeted Email Campaigns",
      image:
        "https://i.ibb.co/NF4Tb8B/Targeted-Email-Campaigns-2af6f3b072d555c47554.webp",
      description:
        "Reach Your Audience with Customized Emails for Better Results and Enhanced Engagement",
    },
    {
      title: "Automated Email Sequences",
      image: "https://i.ibb.co/Jr9Q1r7/Automated-Email-Sequences.webp",
      description:
        "Effortlessly Engage Customers with Automated Email Flows for Improved Efficiency and Response",
    },
    {
      title: "Email List Management",
      image:
        "https://i.ibb.co/F8NY4Lr/Email-List-Management-68cd696a6070a032d343.jpg",
      description:
        "Organize and Target Your Email Contacts for Maximum Impact and Greater Conversion Rates",
    },
    {
      title: "Performance Analytics",
      image:
        "https://i.ibb.co/crzLqB5/Performance-Analytics-29ab8e06652b059059f2.jpg",
      description:
        "Track and Improve Your Email Campaigns with Detailed Insights and Informed Decision-Making",
    },
  ],
  button_text: "Contact Us Now",
  link: "/contact",
};

// About Email Marketing Serives
export const aboutEmailMarketingServices = {
  title: "Don't Just Send Emails, Create Memorable Customer Experiences.",
  description:
    "Turn your email marketing into memorable customer experiences, where every message connects and makes a lasting impact.",
  buttonText: "Craft Your Digital Journey",
};

// Email Markegint  deliverables
export const emailMarketingbDeliverables = {
  title: "Deliverables ",
  categoryTitle1: "Email Marketing",
  category1: [
    "Personalized Email Campaigns",
    "Mobile-friendly Templates",
    "Engaging Content Creation",
    "Email List Management",
    "Cross-platform Compatibility",
  ],
  category2: [
    "SEO-friendly Email Content",
    "Secure Email Delivery",
    "Mobile Responsiveness",
    "Fast Delivery Speed",
    "Maintenance and Support Services",
  ],
};

// Working proccess Email Marking
export const emailMarketingWorkingProccess = {
  title: ["Strategic Working Process", "for Business Success"],
  process: [
    "Personalization",
    "Automation",
    "Segmentation",
    "Targeting",
    "Engagement",
    "Analytics",
    "Optimization",
  ],
};

// Email Marketing Gallery
export const emailMarketingGallery = {
  buttonText: "View more projects",
  link: "/our-works",
  images: [
    "https://i.ibb.co/16CbfhM/email1-51bfc48e6aa75a05a300.webp",
    "https://i.ibb.co/XXsh0Fk/email2-69c1a260470a69a6503d.webp",
    "https://i.ibb.co/qd8hfDC/email3-8a664c1cb7554b1c66d4.webp",
    "https://i.ibb.co/bNMnK9g/email4-6aeedf9013b57f279501.webp",
    "https://i.ibb.co/N2xp3Jj/email5-22c93c6ac2c9478ed617.webp",
    "https://i.ibb.co/99kY8MR/email6-22d6bf72732056385d63.webp",
    "https://i.ibb.co/6BwT7QL/email7-5b870b339e533f8e74d8.webp",
  ],
};

// Why Chuse us sevices text
export const emailMarketingwhayChuseUs = {
  services: [
    {
      id: 1,
      title: `High-Quality Work and On-time Delivery`,
      image: Images.dimond1,
    },
    {
      id: 2,
      title: `Committed to providing the highest quality service.`,
      image: Images.badge1,
    },
    {
      id: 3,
      title: `Quick Response with 24/7 Support`,
      image: Images.tecnical1,
    },
    {
      id: 4,
      title: `30 Days of Free Support After the Delivery`,
      image: Images.productivaty1,
    },
  ],

  question: [
    {
      title: "What is Email Marketing, and how can it benefit my business?",
      answer:
        " Email Marketing is a way to send messages to your customers through email. It helps you keep them informed about your products, promotions, and important updates.",
    },
    {
      title: "How do you build an email list for my business?",
      answer:
        "We use different strategies to collect email addresses from people interested in your products or services. This includes sign-up forms on your website and other methods to grow your list.",
    },
    {
      title:
        "Is my customer's information safe with your Email Marketing service?",
      answer:
        "Absolutely. We take privacy seriously. Your customer's information is kept secure, and we follow strict rules to protect their privacy.",
    },
    {
      title: "Can you help us with Email Marketing?",
      answer:
        "Definitely! We can assist you with Email Marketing by creating and sending impactful emails that reach your target audience, promoting your products or services effectively, and helping you achieve your marketing goals. Let's boost your brand's presence and engagement through strategic email campaigns.",
    },
    {
      title: "What do you need to begin?",
      answer:
        "To start with Email Marketing, we'll need your contact list and details about your products or services. Additionally, any specific preferences or content ideas you have will help us tailor effective and personalized email campaigns for your audience.",
    },
    {
      title: "How do you measure the success of an Email Marketing campaign?",
      answer:
        "We use different metrics like open rates and click-through rates to measure how well your emails are performing. This helps us make improvements and ensure your campaigns are effective",
    },
    {
      title:
        "I don't have any plans for how I want to start Email Marketing. Can you help me?",
      answer:
        "Certainly! If you're unsure where to start with Email Marketing, we can guide you by understanding your business goals and target audience. Together, we'll create a customized plan to effectively engage your customers and drive success through impactful email campaigns.",
    },
  ],
};
