export const InputFiled = ({
  required = true,
  label,
  placeholder,
  type,
  name,
  className,
  className2,
}) => {
  return (
    <div className="relative h-11 w-full ">
      <input

        name={name}
        type={type}
        required={required}
        placeholder={placeholder}
        className={`peer h-full w-full border-b-2  bg-transparent pt-4 pb-1.5   outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50  text-lg font-medium ${className}`}
       
      />
      <label
        className={`after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate leading-tight  transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 
        ${className2} peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1  text-xl font-semibold`}
      >
        <p className="mb-8 flex items-center gap-1 ">
          <span>{label}</span>
          {required ? (
            <span className={required ? "text-red-500" : ""}>*</span>
          ) : (
            ""
          )}
        </p>
      </label>
    </div>
  );
};
