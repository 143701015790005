
export const ServiceCard = ({ service }) => {
  const { title, image, description } = service || {};
  return (
    <div className="p-6 border-b border-b-gray-300  text-darkColor sm:flex xl:flex-row sm:flex-row flex-col md:flex-col gap-5 group grid grid-cols-1   duration-300 ">
      <div className="">
        <div className="overflow-hidden h-full sm:h-[142px] w-full sm:w-[191px] rounded">
          <img
            className="h-full w-full object-cover group-hover:scale-110 duration-1000 hover:cursor-pointer"
            src={image}
            alt={`image of ${title}`}
          />
          
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-4">
        <h3 className="xl:text-2xl md:text-xl text-lg font-bold">{title}</h3>
        <p className="text-gray-700 text-base sm:text-lg">{description}</p>
      </div>
    </div>
  );
};
