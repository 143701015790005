import { Link } from "react-router-dom";
import { ButtonArrowTop } from "../button";



export const ServicePageBanner = ({
  title,
  subTitle,
  description,
  buttonText,
  linkText,
  serviceImage,
}) => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  return (
    <section className=" service-background flex items-center justify-center relative sm:py-0 py-28 sm:h-[680px] overflow-hidden ">
      <div className="container flex items-center ">
        <div
          className="flex-1"
          data-aos="fade-down-right"
          data-aos-duration="3000"
        >
          <div className="xl:text-[96px] text-4xl sm:text-6xl font-black text-white ">
            <span>{title}</span>
            <div className="flex items-center gap-5">
              <span className="sm:w-24 w-10 h-2 sm:h-4 bg-white inline-block"></span>
              <span>{subTitle}</span>
            </div>
          </div>
          <p className="md:text-lg text-base mt-6 text-white">{description}</p>

          <div className="mt-16">
            <Link to={linkText} onClick={handleClick}>
              <ButtonArrowTop px="sm:px-5 px-3" name={buttonText}></ButtonArrowTop>
            </Link>
          </div>
        </div>
        <div
          className="basis-2/5 md:block hidden"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
<img className="h-full w-full " src={serviceImage} alt="" />
        </div>
        <div className="md:hidden hidden sm:block bottom-4 right-4 h-44 w-44 absolute">
  <img
            className="h-full w-full object-cover "
            src={serviceImage}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};
