import { CommonSectionCard } from './CommonSectionCard'
import { CommonSectionHeading } from './CommonSectionHeading'
import { workWithUs } from './ourValues'


export const WorkWithUs = () => {

  return (
    <section className='py-[120px] bg-[#E7F0EE] overflow-x-hidden'>
            <div className="container">

              <div data-aos="fade-right" data-aos-duration="3000">
              <CommonSectionHeading
                    heading1="Why Work"
                    heading2="With US"
                    description="We work with you to understand your business goals, target audience, and work closely with you to identify the challenges your website faces and strategize effective solutions."
                />
              </div>

                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-[35px] gap-x-[60px] mt-20 ' data-aos="flip-down" data-aos-duration="3000">
                    {
                        workWithUs.map(item => (
                            <CommonSectionCard key={item.id} item={item} />
                        ))
                    }
                </div>

            </div>
        </section>
  )
}
