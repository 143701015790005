import { NewsLetter } from "../../components/newsletter";
import { LatestWorks } from "./LatestWorks";
import { WorksHero } from "./WorksHero";

export const OurWorks = () => {
  return (
    <>
      <WorksHero />
      <div className="flex justify-center items-center pt-8">
        <div className="font-black service-title-font-size text-center xl:leading-[80px] md:leading-[60px] leading-6">
          <h5 className="mb-4 text-darkColor text-2xl">Our Portfolio</h5>
          <h2 className="mb-4 text-secondary">Look at Our</h2>
          <h2 className="text-darkColor">Recent Work</h2>
        </div>
      </div>
      <LatestWorks />
      <NewsLetter />
    </>
  );
};
