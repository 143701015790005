import { CareerHero } from "./CareerHero";
// import { CoreValues } from "./CoreValues";
import { EmployeesFeedback } from "./EmployeesFeedback";
import { Jobs } from "./Jobs";
// import { WorkWithUs } from "./WorkWithUs";
// import { ContactUs } from "./text";
export const Career = () => {
  return (
    <>
      <CareerHero />
      {/* <CoreValues /> */}
      <Jobs />
      <EmployeesFeedback />
      {/* <ContactUs/> */}
      {/* <WorkWithUs /> */}
    </>
  );
};
