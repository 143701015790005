import { ServicePageBanner } from "../../components/servicePageBanner";

import { OurSpecality } from "../../components/ourSpecality";
import {
  WSpecality,
  aboutWebService,
  webDeliverables,
  webServices,
  webUXGallery,
  webWorkingProccess,
  webwhayChuseUs,
} from "../../utils/webServiceInfo";
import { Services } from "../../components/services";
import { AboutServices } from "../../components/aoboutServices";
import { ServicesFeatuers } from "../../components/servicesFeatuers";
import { StragicWorking } from "../ui-ux/strategicWorking";
import { ServicesGallary } from "../../components/servicesGallary";
import { ServicesClientsFeedback } from "../../components/servicesClientsFeedback";
import { ServicesProjectInMind } from "../../components/servicesProjectInMind";
import { WhayChoseUS } from "../../components/whayChoseUs";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";


export const Web = () => {
  const {data: web_dev, isLoading, error} = useQuery(
    {
      queryKey: 'web_dev',
      queryFn: () => {
        const res = axios.get('sercices.json');
        return res;
      }
    }
  )

  const filterData = web_dev?.data.filter(item => item.category.toLowerCase() === "web development") || [];
  return (
    <>
      <ServicePageBanner
        title="Web Development"
        subTitle="Services"
        description="We craft powerful websites tailored to your needs. Elevate your online presence with our expert  web development services."
        linkText="/contact"
        buttonText="Get Started with Web Development"
        serviceImage="https://i.ibb.co/kK2XW34/dev207cbbb3645a20f15518-65e00c844a61d.webp"
      ></ServicePageBanner>
      {/* Web development our spacality */}
      <OurSpecality {...WSpecality} />
      {/* Web development services */}
      <Services {...webServices} />
      {/* About webdevelopment services */}
      <AboutServices {...aboutWebService} />
      {/* Services features */}
      <ServicesFeatuers {...webDeliverables} />
      {/* Web development statagic working section */}
      <StragicWorking {...webWorkingProccess} />
      {/* Gallery */}
      <ServicesGallary {...webUXGallery} />
      {/* Clients Feed back */}
      <ServicesClientsFeedback />
      {/* Why Chuse us */}
      <WhayChoseUS {...webwhayChuseUs} />

      {/* Project in mind */}
      <ServicesProjectInMind error={error} isLoading={isLoading} categories={filterData[0]?.subServices}  />
    </>
  );
};
