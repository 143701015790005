import React from "react";
import { AboutHeroSection } from "../../components/aboutHeroSection";
import { SuccessStories } from "./SuccessStories";
// import { ValueSection } from "./ValueSection";
import { Work } from "./Work";
import { MoreInfo } from "./MoreInfo";
import { OurServices } from "./OurService";
import { AboutVideoSection } from "./aboutVideoSection";
import { WahtPeopleSay } from "./WhatPeopleSay";
// import { Growth } from "./growth";
import { WorkWithUs } from "../career/WorkWithUs";
import { CoreValues } from "../career/CoreValues";
import "./about.css";

export const About = () => {
  return (
    <>
      <AboutHeroSection />
      <SuccessStories />
      <CoreValues />
      {/* <ValueSection /> */}
      <OurServices />
      <Work />
      {/* <Growth /> */}
      <AboutVideoSection />
      <WorkWithUs />
      <WahtPeopleSay />
      <MoreInfo />
    </>
  );
};
