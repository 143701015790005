import { ButtonArrowTop } from "../../components/button";
import { ServicePageTitle } from "../../components/servicePageTitle";
import { Link } from "react-router-dom";


export const OurServices = () => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  return (
    <section className="bg-black section-padding-services-page  overflow-x-hidden">
      <div className="container">
        <div data-aos="fade-right" className="service-title-box-padding" data-aos-duration="3000">
          <ServicePageTitle>
            <h2 className="service-title-font-size">Our Services</h2>
          </ServicePageTitle>
        </div>

        <div
          className=" text-white bg-gradient-to-b from-white gap-[1px] to-gray-500  grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 p-[1px]"
          data-aos="flip-up"
          data-aos-duration="1500"
        >
          {services.map(({ name, path, category }, idx) => (
            <div
              className={`bg-black  py-[50px] flex flex-col justify-center items-center text-center text-lg sm:text-[28px] font-semibold ${
                idx === 4 ? "xl:col-span-1 md:col-span-2 col-span-1" : ""
              }`}
              key={idx}
            >
              <h4>{name}</h4>
              <ul className="text-base flex flex-col gap-2 py-10">
                {category?.map((item, idx) => (
                  <li className="flex items-center gap-2" key={idx}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-secondary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              <div className="">
                <Link to={`/${path}`} onClick={handleClick}>
                  <ButtonArrowTop name="Discover More" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const services = [
  {
    name: "UI/UX Design",
    path: "ui-ux",
    category: ["Web Design", "App Design", "Product Design", "SAAS Design"],
  },
  {
    name: "Web Development",
    path: "web-development",
    category: [
      "WordPress Development",
      "Webflow Development",
      "Shopify Development",
      "Front-End Development",
    ],
  },
  {
    name: "Email Marketing",
    path: "email-marketing",
    category: [
      "Campaign Management",
      "Email Template",
      "Newsletter Design",
      "Email Automation",
    ],
  },
  {
    name: "Lead Generation",
    path: "lead-generation",
    category: [
      "Targeted B2B Leads",
      "Contact List",
      "Building Leads",
      "LinkedIn Leads",
    ],
  },
  {
    name: "Data Entry Service",
    path: "data-entry",
    category: [
      "Data Collection",
      "Excel Data Entry",
      "Web Research",
      "Web Scraping",
    ],
  },
];
