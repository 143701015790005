import React from "react";
import { ManagementCard } from "../../components/managementCard";
import { MangeMent } from "../../utils/teamData";
export const Management = () => {
  return (
    <section className="">
      <div className="container pb-5 flex items-center justify-center text-center ">
        <div data-aos="fade-right" data-aos-duration="1500">
          <h3 className="text-[#fff] font-medium text-xl justify-center  mb-4">
            Our Team
          </h3>

          <h2 className="team-page-title">MANAGEMENT</h2>
        </div>
      </div>
      <div
        className="flex sm:justify-center sm:items-center gap-24 mb-14  lg:flex-row flex-col sm:px-0 px-3"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        {MangeMent.map((item, idx) => (
          <ManagementCard key={idx} {...item} />
        ))}
      </div>
    </section>
  );
};
