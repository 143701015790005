import React from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { SmtUiUx } from "../../utils/teamData";
import TeamCard from "./teamCard";

export const UiUxDev = () => {
  return (
    <>
      <section className="section-padding-services-page">
        <div className="container flex items-center justify-center text-center">
          <h2 className="team-page-title">
            UI-UX TEAM
          </h2>
        </div>

        <div className="container">
          <Swiper
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {SmtUiUx.map((members, index) => (
              <SwiperSlide key={index}>
            <TeamCard {...members}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};


