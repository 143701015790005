import React from "react";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import { Images } from "../../utils/images";
import { ManagementCard } from "../../components/managementCard";
export const Lead = () => {
  return (
    <section className="section-padding-services-page">
      <div className="container pb-5 flex items-center justify-center text-center ">
        <div data-aos="fade-right" data-aos-duration="1500">
          <h2 className="team-page-title">
            SMT LEAD
          </h2>
        </div>
      </div>
      <div className="sm:mx-0 mx-3">
        <div className=" grid place-content-center mb-16 ">
          <ManagementCard {...teams[0]} />
        </div>
        <div
          className=" grid grid-cols-1 md:grid-cols-2  xl:grid-cols-4 gap-6"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          {teams.slice(1).map((item, idx) => (
            <ManagementCard key={idx} {...item} />
          ))}
        </div>
        {/* <div className="mt-14  justify-center items-center xl:flex hidden">
          <ManagementCard {...teams[4]} />
        </div> */}
        {/* <div
          className="grid grid-cols-1 md:grid-cols-2 xl:hidden gap-8"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          {teams.slice(1).map((item, idx) => (
            <ManagementCard key={idx} {...item} />
          ))}
        </div> */}
      </div>
    </section>
  );
};

const teams = [
  {
    id: 1,
    image: Images.Shamim,
    name: "Shamim Miah",
    degisnation: "GM",
    linkedIn: "https://linkedin.com",
  },
  {
    id: 2,
    image: "https://i.ibb.co/89zF2b9/jabed.jpg",
    name: "Abu Sadek Jabed",
    degisnation: "GM, Sales",
    linkedIn: "https://linkedin.com",
  },
  {
    id: 3,
    image: Images.Sohel,
    name: "Sohel Rana",
    degisnation: "AGM, Operations",
    linkedIn: "https://linkedin.com",
  },
  {
    id: 4,
    image: "https://i.ibb.co/2cMvQfC/rakibul-islam.png",
    name: "Rakibul Hasan",
    degisnation: "Key Account Manager",
    linkedIn: "https://linkedin.com",
  },
  {
    id: 5,
    image: "https://i.ibb.co/ZcKW02g/rajon.png",
    name: "Md. Rajan Miah",
    degisnation: "Manager, Operations",
    linkedIn: "https://linkedin.com",
  },
];
