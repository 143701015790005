import { Images } from "../../utils/images";

export const coreValues = [
  {
    id: 1,
    title: "Creativity",
    description:
      "We love thinking creatively to find new solutions and make things exciting. It's all about using fresh ideas to make technology awesome.",
    image: Images.CoreValue1,
  },
  {
    id: 2,
    title: "Client Centric",
    description:
      "We aim for excellence, working hard to create outstanding results that reflect our team's dedication and skill.",
    image: Images.CoreValue1,
  },
  {
    id: 3,
    title: "Integrity",
    description:
      "Diverse viewpoints unite in seamless collaboration, nurturing a rich ecosystem of creativity, ensuring we deliver excellence!",
    image: Images.CoreValue3,
  },
  {
    id: 4,
    title: "Collaboration",
    description:
      "Striving for excellence is our hallmark, propelling us to craft extraordinary work that showcases our team's exceptional dedication and competence.",
    image: Images.CoreValue1,
  },
  {
    id: 5,
    title: "Sustainability",
    description:
      "Ownership breeds trust; our commitment to meeting and exceeding expectations amplifies our reputation as a reliable and accomplished team.",
    image: Images.CoreValue5,
  },
];

export const workWithUs = [
  {
    id: 1,
    title: "High-Quality Work and On-time Delivery",
    description:
      "Consistently delivering high-quality work with a commitment to on-time delivery, ensuring excellence and punctuality in every project undertaken.",
    image: "https://i.ibb.co/kJcPHwY/iconOne.png",
  },
  {
    id: 2,
    title: "100% Satisfaction Guaranteed",
    description:
      "We guarantee 100% satisfaction, ensuring that our services not only meet but exceed your expectations. Your contentment is our top priority, and we stand by it with confidence.",
    image: Images.chooseIcon2,
  },
  {
    id: 3,
    title: "Quick Response with 24/7 Support",
    description:
      "Get quick responses and round-the-clock support for all your inquiries. Our team is here 24/7 to assist you promptly, ensuring a seamless experience at any time.",
    image: Images.chooseIcon3,
  },
  {
    id: 4,
    title: "30 Days of Free Support After the Delivery",
    description:
      "Enjoy peace of mind with our service—receive 30 days of free support after delivery. We're committed to assisting you even after the project is completed, ensuring your satisfaction for an extended period.",
    image: Images.chooseIcon4,
  },
];

export const jobs = [
  {
    id: 1,
    image: Images.Job,
    title: "Webflow Developer",
    location: "Banasree, Dhaka",
    locationIcon: "location",
    workTime: "Full-time",
    timeIcon: "work",
    workTimePerWeek: "6 days/Week",
    calendarIcon: "calendar",
    tophyIcon: "experience",
    experience: "2+ years exp.",
    salaryIcon: "money",
    salary: "20k - 25k BDT",
    deadlineIcon: "deadline",
    deadline: "15 March 2024",
    viewJobIcon: "view",
    aboutUs: "At SM Technology, we are a freelance-based digital agency specializing in UI/UX design, web development, and business administrative services.bdCalling IT Ltd. is a leading software development company...",
    jobDescription:[
      " Job Type - Office Desk Job",
  " Work Schedule: 6 days a week, from 9:00 AM to 6:00 PM",
  " Salary  (negotiable).",
  " Employment Status: This is a full-time on-site job",
  " Probation Period: 3 months",
  " Deadline: 15 March 2024"
    ],
    role: "As a member of the Webflow CMS team, you will play a pivotal role in crafting, implementing, and maintaining top-tier content management solutions. Collaborating closely with cross-functional teams, you'll define project requirements and ensure the seamless delivery of projects.",
    requirements: [
      " Strong knowledge of Webflow and Front-end Development.",
      " Excellent English communication skills, both verbal and written.",
      " Good understanding of visual design.",
      " Converting Figma to Webflow will be a plus."
  ],
    responsibilities: [
      " Design, develop, and maintain Webflow-based websites.",
      " Debug and resolve technical issues as they arise.",
      " Collaborate with designers, project managers, and other team members to ensure timely and successful project delivery."
    ],
    skillsAndExperience: [
      "Proficiency in Webflow's visual development platform.",
      "Strong understanding of Webflow's interactions and animations.",
      "Experience with Webflow's CMS capabilities for dynamic content management.",
      "Ability to implement custom designs accurately within Webflow's framework.",
      "Knowledge of Webflow's integrations and third-party plugins.",
      "Familiarity with Webflow's responsive design features.",
    ],
    officeLocation: "House #11/13, Road #5, Block C, Banasree, Rampura Dhaka 1219",
    outers: [
      "Please submit your resume and portfolio of relevant projects.",
      "If you are shortlisted, we will contact you via email and phone for the interview."
    ],
    be_noted: [
      "This is not a remote/part-time job.",
      "Please don't apply if you cannot attend a physical interview."
    ],
    benefit: [
      "Competitive salary",
      "Performance bonus.",
      "Health benefits",
      "Opportunities for professional growth",
    ],
  },
  {
    id: 2,
    image: Images.Job,
    title: " WordPress Developers",
    location: "Banasree, Dhaka",
    locationIcon: "location",
    workTime: "Part-time",
    timeIcon: "work",
    workTimePerWeek: "6 days/Week",
    calendarIcon: "calendar",
    tophyIcon: "experience",
    experience: "1+ years exp.",
    salaryIcon: "money",
    salary: "30k - 35k BDT",
    deadlineIcon: "deadline",
    deadline: "15 March 2024",
    viewJobIcon: "view",
    aboutUs: "At SM Technology, we are a freelance-based digital agency specializing in UI/UX design, web development, and business administrative services.bdCalling IT Ltd. is a leading software development company...",
      jobDescription:[
        "Job Nature: Full-time (Desk Job)",
        "Office Time - 6 days/week.",
        "Probation Period: 3 months",
        "Deadline: 15 March 2024",
        "(Not only WordPress Customizer)"

      ],
    role: "As a Marketing Coordinator, you will support the marketing team in executing various campaigns and initiatives. You will assist in coordinating marketing activities, analyzing market trends, and generating reports...",
    requirements: [
      " Strong knowledge of WordPress, PHP, HTML, CSS, and JavaScript.",
      " Understanding of WordPress core functions, actions, and hooks.",
      " Excellent English communication skills, both verbal and written.",
      " Expertise in popular WordPress page builders and ACF (Advanced Custom Fields).",
      " Develop custom Elementor widgets and extensions, harnessing the full potential of Elementor Pro.",
      " Convert Figma design into Pixel Perfect Responsive WordPress development using Elementor Pro."
  ],
    responsibilities: [
      " Design, develop, and maintain WordPress-based websites.",
      " Customize and extend plugins and themes to meet specific project requirements.",
      " Debug and resolve technical issues as they arise.",
      " Collaborate with designers, project managers, and other team members to ensure timely and successful project delivery.",
    ],
    skillsAndExperience: [
      "Design, develop, and maintain WordPress-based websites.",
      "Customize and extend plugins and themes to meet specific project requirements.",
      "Debug and resolve technical issues as they arise.",
      "Collaborate with designers, project managers, and other team members to ensure timely and successful project delivery."
    ],
    officeLocation: "House #11/13, Road #5, Block C, Banasree, Rampura Dhaka 1219",
    outers: [
      "If you are passionate about web development and want to work with a talented and dedicated team, we encourage you to apply! Please submit your resume and portfolio of relevant projects:",
      "If you are shortlisted, we will contact you via email and phone for the interview."
    ],
    be_noted: [
      "This is not a remote/part-time job.",
      "Please don't apply if you cannot attend a physical interview."
    ],
    benefit: [
      "Flexible work schedule",
      "Performance bonus.",
      "Opportunity for growth",
      "A collaborative work environment",
    ],
  },
  // {
  //   id: 3,
  //   image: Images.Job,
  //   title: "Graphic Designer",
  //   location: "Banasree, Dhaka",
  //   locationIcon: "location",
  //   workTime: "Contract",
  //   timeIcon: "work",
  //   workTimePerWeek: "30 hrs/Week",
  //   calendarIcon: "calendar",
  //   tophyIcon: "experience",
  //   experience: "3+ years exp.",
  //   salaryIcon: "money",
  //   salary: "30k - 35k BDT",
  //   deadlineIcon: "deadline",
  //   deadline: "10 Mar 2024",
  //   viewJobIcon: "view",
  //   aboutUs:
  //     "bdCalling IT Ltd. is a leading design agency specializing in graphic design services...",
  //   jobDescription:
  //     "We are in need of a talented Graphic Designer to create engaging and on-brand graphics for various digital and print mediums. The ideal candidate will have a strong portfolio showcasing creative designs and a passion for visual storytelling...",
  //   role: "As a Graphic Designer, you will be responsible for conceptualizing and creating visual assets for marketing campaigns, websites, and other branding materials. You will collaborate with the marketing team to translate concepts into visually appealing designs...",
  //   responsibilities: [
  //     "Design and create various materials for print and digital collateral.",
  //     "Collaborate with the marketing team to brainstorm creative concepts.",
  //     "Ensure consistency in brand identity across all design projects.",
  //   ],
  //   skillsAndExperience: [
  //     "Bachelor's degree in Graphic Design or related field.",
  //     "3+ years of experience in graphic design.",
  //     "Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign).",
  //     "Strong portfolio showcasing creative designs.",
  //   ],

  //   benefit: [
  //     "Competitive salary",
  //     "Remote work options",
  //     "Opportunities to showcase creativity",
  //   ],
  // },
];
