
import { OurSpecalityCard } from "../ourSpecalityCard";
import { ServicePageTitle } from "../servicePageTitle";


export const OurSpecality = ({
  subtitle = "Our Speciality",
  title,
  cardContent,
}) => {

  return (
    <section className="section-padding-services-page bg-gradient-to-br service-section-background overflow-hidden ">
      <div className="container">
        <div
          className="service-title-box-padding"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <h3 className="text-[#FFF192] font-medium text-xl flex items-center gap-2 mb-6">
            ‍<span className="bg-[#FFF192] h-[2px] w-8 block"></span> {subtitle}
          </h3>
          <ServicePageTitle>
            <h2 className="service-title-font-size">
              {title?.map((item, idx) => (
                <span key={idx} className="block">
                  {item}
                </span>
              ))}
            </h2>
          </ServicePageTitle>
        </div>

        <div
          className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 "
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          {cardContent?.map((item, idx) => (
            <OurSpecalityCard key={idx} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};
