
import React from "react";
import { Images } from "../../utils/images";
import "./team.css";
// import { ServicePageTitle } from "../../components/servicePageTitle";
import { Lead } from "./teamLead";
import { Management } from "./mangement";
import { WebDev } from "./webdev";
import { UiUxDev } from "./uiux";
import { WarriorsDev } from "./smtwarriors";
import { DefenderssDev } from "./smtdefenders";
import { DominatorsDev } from "./smtdominators";
import { Sales } from "./smtsales";
import { Bpu } from "./bpu";
import { HR } from "./hr";
export const Team = () => {

  return (
    <section className=" bg-[#1d1d1d] text-white py-[120px] relative">
      <Management/>
      <Lead/>
      <WebDev/>
      <UiUxDev/>
      <WarriorsDev/>
      <DefenderssDev/>
      <DominatorsDev/>
      <Sales/>
      <Bpu/>
      <HR/>
    </section>
  );
};

/* 
<div className="col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <div className="cover item-b bg-cover relative">
                <h1>
                  Tropical
                  <br />
                  Leaf
                </h1>
                <span className="price">$35</span>
                <div className="card-back absolute inset-0 flex items-center justify-center">
                  <a href="#">Add to cart</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="card">
              <div className="cover item-c bg-cover relative">
                <h1>
                  Marijuana
                  <br />
                  Chill
                </h1>
                <span className="price">$155</span>
                <div className="card-back absolute inset-0 flex items-center justify-center">
                  <a href="#">Add to cart</a>
                </div>
              </div>
            </div>
          </div>
*/

const teams = [
  // {
  //   id: 1,
  //   image: "https://i.ibb.co/sv95Yh3/IMG-9064.jpg",
  //   name: "Muhammad Shamim Mia",
  //   degisnation: "GM",
  //   linkedIn: "https://linkedin.com",
  // },
  {
    id: 2,
    image: "https://i.ibb.co/89zF2b9/jabed.jpg",
    name: "Abu Sadeck Jabed",
    degisnation: "GM of Sales",
    linkedIn: "https://linkedin.com",
  },
  {
    id: 3,
    image: "https://i.ibb.co/30CMp3f/IMG-9024.jpg",
    name: "Sohel Rana",
    degisnation: "GM of Operation",
    linkedIn: "https://linkedin.com",
  },

];
const teamMembers = [
  {
    id: 1,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },
  {
    id: 2,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },
  {
    id: 3,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },
  {
    id: 4,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },
  {
    id: 5,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },
  {
    id: 6,
    image: Images.CEO,
    name: "Muhammad Monir Hossain",
    designation: "CEO",
    linkedIn: "https://linkedin.com",
    faceBook: "hhtps://fb.com",
  },

];
