import { Images } from "./images";

// Ui Ux Specality content
export const uiuxSpecality = {
  title: ["Let us help you elevate", "your digital presence"],
  cardContent: [
    {
      id: 1,
      title: "Web Design",
      description:
        "Web design is the art of crafting visually appealing and user-friendly websites for optimal online interaction.",
      image: "https://i.ibb.co/Kwhf8MK/ui-Card1-14758ff705e683884ed0.webp",
    },

    {
      id: 2,
      title: "App Design",
      description:
        "App design: Crafting visually intuitive interfaces to optimize user experience and functionality in mobile or desktop applications.",
      image:
        "https://i.ibb.co/SJZ1c06/uicard2930b629b9c068be2feed-65e0072ae5ee7.webp",
    },

    {
      id: 3,
      title: "Product Design",
      description:
        "Product design integrates user-centric principles and aesthetics to create a visually appealing and ensuring a seamless and satisfying user experience.",
      image: "https://i.ibb.co/R0pymXq/product-0e7ae09b844c578e5cd8.webp",
    },

    {
      id: 4,
      title: "SAAS Design",
      description:
        "SaaS design focuses on creating user-friendly and scalable interfaces for Software as a Service applications, and accessibility in a cloud-based environment.",
      image: "https://i.ibb.co/MSb7jgd/saas2-b43e84b50638cf4a400a.jpg",
    },
  ],
};

// Ui Ux Services
export const uiuxServices = {
  title: "UI/UX Design Services",
  services: [
    {
      title: "Mobile UI/UX Design",
      image:
        "https://i.ibb.co/5BXdnkH/mobile-ui-ux-design-22964888ae78c74534d8.png",
      description:
        "Crafting seamless web experiences to elevate user engagement.",
    },
    {
      title: "Web UI/UX Design",
      image:
        "https://i.ibb.co/mzPQhhz/web-ui-ux-design-2224b24bd923349f164f.png",
      description:
        "We design intuitive mobile interfaces for superior user interaction.",
    },
    {
      title: "Wireframing & Prototyping",
      image:
        "https://i.ibb.co/jWZKWNB/wireframing-prototyping-d4f38238300d09d6aee5.png",
      description:
        "We bring design concepts to life with interactive prototypes.",
    },
    {
      title: "Data-Driven Design",
      image:
        "https://i.ibb.co/Kjzpxqk/data-driven-design-0c9363c8fab222df4683.png",
      description:
        "We craft experiences fueled by insightful data intelligence.  ",
    },
  ],
  button_text: "Contact Us Now",
  link: "/contact",
};

// justMakeSomething
export const justMakeSomething = {
  description:
    "We create interfaces with care and skill, that make users feel at ease and trust us with every click.",
  title:
    "Don’t just make something, create a memorable experience for your customers.",
  buttonText: "Design/Redesign your UI UX",
};

// deliverables
export const deliverables = {
  title: "Deliverables ",
  categoryTitle1: "UX Design:",
  categoryTitle2: "UI Design:",
  category1: [
    "User Flow",
    "Information Architecture",
    "Competitor Analysis",
    "Lo-fi Wireframes",
    "Hi-fi Wireframes",
  ],
  category2: [
    "Visual Design",
    "Prototype",
    "Icons, Illustrations  and Assets Share",
    "Icons and Illustrations",
    "UX Copy",
  ],
};

// Working proccess UI UX
export const uiuxWorkingProccess = {
  title: ["Strategic Working Process", "for Business Success"],
  process: [
    "UX Research",
    "Moodboard",
    "Wireframe Design",
    "Draft UI Design",
    "Client Feedback (If Needed)",
    "Final UI Design",
    "Design delivery",
  ],
};

// UI UX Gallery
export const uiUXGallery = {
  buttonText: "View more projects",
  link: "/our-works",
  images: [
    "https://i.ibb.co/QvkKnZm/service-gal-1-05870462265f8e190399.png",
    "https://i.ibb.co/NV1BQNT/service-gal-2-5c2ba7b67a0403977d32.png",
    "https://i.ibb.co/dLXKYsN/service-gal-3-2c24264499fc789b6e82.png",
    "https://i.ibb.co/gRqCKxM/service-gal-4-441bbca24474f3c60dc9.png",
    "https://i.ibb.co/nsRyjyz/service-gal-5-bf1f00e835d2af169c36.png",
    "https://i.ibb.co/GRRKjCL/service-gal-6-9dad52b205165f0afe07.png",
    "https://i.ibb.co/VgjJ6mN/service-gal-7-63705482896afe6f9547.png",
  ],
};

// Why Chuse us sevices text
export const whayChuseUs = {
  services: [
    {
      id: 1,
      title: `High-Quality Work and On-time Delivery`,
      image: Images.dimond1,
    },
    {
      id: 2,
      title: `Committed to providing the highest quality service.`,
      image: Images.badge1,
    },
    {
      id: 3,
      title: `Quick Response with 24/7 Support`,
      image: Images.tecnical1,
    },
    {
      id: 4,
      title: `30 Days of Free Support After the Delivery`,
      image: Images.productivaty1,
    },
  ],

  question: [
    {
      title: "What are UI UX design services?",
      answer:
        "UI/UX design services focus on creating visually appealing digital interfaces (UI) and ensuring a seamless and satisfying user experience (UX) to make websites and apps both attractive and user-friendly. They encompass tasks like wireframing, prototyping, and usability testing to enhance the overall design and functionality.",
    },
    {
      title: "Why are UI/UX services important?",
      answer:
        "UI/UX services are crucial as they make digital products easy to use and visually appealing, ensuring a positive experience for users. Good design increases user satisfaction, encourages engagement, and helps businesses stand out in the competitive online landscape.",
    },
    {
      title: "How Can UI Services help my product?",
      answer:
        "UI services can enhance your product by creating an attractive and intuitive design, making it visually appealing and user-friendly. This, in turn, improves user satisfaction and increases the likelihood of users engaging with and enjoying your product.",
    },
    {
      title: "What does your UI & UX design process look like?",
      answer:
        "In UI/UX design, we start by understanding your users and goals, followed by creating wireframes to plan the layout. Then, we design the visual elements, build interactive prototypes, and gather user feedback to refine and ensure a seamless, enjoyable experience for your audience.",
    },
    {
      title: "Can you help us redesign our app, website ? ",
      answer:
        "Certainly! I can assist in redesigning your app or website by analyzing user experience, proposing visual improvements, and guiding the process to create a more engaging and user-friendly digital presence.",
    },
    {
      title:
        "Do you provide ongong support after completing the UI & UX design project?",
      answer:
        "Yes, I offer ongoing support post-UI/UX design to address any issues, implement updates, and ensure your digital platform continues to meet user needs effectively, fostering a lasting positive experience.",
    },
    {
      title: "What do you need to get started?",
      answer:
        "To begin, I'd need insights into your target audience, your goals, and any specific preferences you have. Additionally, access to your existing design assets and a clear understanding of your brand identity would be helpful in initiating the design process.",
    },
    {
      title: "I don't have any wireframes. Can you asist me?",
      answer:
        "Certainly! I can help create wireframes for your project by understanding your requirements and sketching out the basic layout and structure to guide the design process. Together, we'll build a solid foundation for your digital product.",
    },
    {
      title: "I need more pages/screens; how do I order them?",
      answer:
        "To add more pages or screens to your project, simply let me know the specific requirements and functionalities you need. I'll incorporate them seamlessly into the design process, ensuring a cohesive and comprehensive user experience for your digital product.",
    },
  ],
};
