export const CommonSectionCard = ({ item }) => {
  return (
    <div className="flex flex-col gap-7 shadow p-3 sm:p-8 border rounded-sm">
      <div className="-mt-10 flex justify-center items-center">
        <div className="h-16 w-16 bg-secondary rounded-full flex justify-center items-center">
          <img className="w-12" src={item.image} alt="core value" />
        </div>
      </div>
      <h4 className="font-medium text-xl text:text-2xl md:text-[30px]">
        {item.title}
      </h4>
      <p className="text-[#5F5F5D] text-base md:text-lg">{item.description}</p>
    </div>
  );
};
