import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
export const ManagementCard = ({
  id,
  name,
  image,
  linkedIn,
  degisnation,
  facebook,
}) => {
  return (
    <div className="flex justify-center">
      <div className="relative rounded-lg group hover:cursor-pointer h-[450px] sm:h-[500px] w-full sm:w-96  ">
        <img
          className={`h-full w-full  object-cover relative z-20 rounded-lg `}
          src={image}
          alt={degisnation}
        />
        <div className="absolute h-32 xl:h-20 z-20 opacity-100  bg-black/20 backdrop-blur-lg  bottom-5 w-[90%] rounded-2xl mr-[5%] ml-[5%] duration-500">
          <div className="  h-full w-full  flex justify-center flex-col items-center gap-2">
            <h3 className="sm:text-2xl text-xl text-center font-semibold">
              {name}
            </h3>
            <h5 className="text-lg font-medium">{degisnation}</h5>
            <div className="flex xl:hidden  xl:flex-col gap-3 justify-center items-center">
              <a
                href={linkedIn} target="_blank"
                className="h-10 w-10 border border-white-500 rounded-full flex  justify-center items-center duration-200 hover:bg-secondary"
              >
                <FaLinkedinIn />
              </a>

              <a
                href={facebook} target="_blank"
                className="h-10 w-10 border border-white-500 rounded-full flex  justify-center items-center duration-200 hover:bg-secondary"
              >
                <FaFacebook />
              </a>
            </div>
          </div>
        </div>
        <div
          className={`h-[400px] absolute w-[100px] bottom-5 duration-300 shadow-sm border-[1px] border-gray-500 rounded-lg bg-[#1a1a1a] xl:flex hidden ${
            name === "Md. Rajan Miah"
              ? "-left-[0px] rotate-[0deg] group-hover:-left-[50px] group-hover:-rotate-[11deg] justify-start "
              : "group-hover:-right-[50px] group-hover:rotate-[11deg] rotate-[0deg] -right-[0px] justify-end  "
          }`}
        >
          <div
            className={`mr-4 mt-4 ${name === "Md. Rajan Miah" ? "ml-4" : ""}`}
          >
            <a
              href={linkedIn} target="_blank"
              className="h-10 w-10 border border-white-500 rounded-full flex justify-center items-center duration-200 hover:bg-secondary"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
