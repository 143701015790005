import React from "react";
import { Link } from "react-router-dom";
import { ButtonArrowTop } from "../button";
import Gallery from "../gallery";
import { Counter } from "../counter";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const AboutOurselves = () => {
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleScroll = debounce(() => {
    window.scroll({
      top: 0,
    });
  }, 200);
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    handleScroll();
    window.scroll({
      top: 0,
    });
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section className="py-20 md:py-[120px] bg-bgColor1 ">
      <div className="container" >
        <div data-aos="zoom-in" data-aos-duration="3000">
          <div className="font-bold text-4xl sm:text-6xl md:text-[84px]">
            <h2 className="text-darkColor sm:mb-4  leading-[75px]">
              Hi, We are
            </h2>
            <div className="w-max">
              <h2 className="max-w-fit text-secondary animate-typing overflow-hidden whitespace-nowrap pb-8">
                SM Technology
              </h2>
            </div>
          </div>
          <p className="sm:text-2xl text-lg text-[#101010] max-w-2xl pb-8">
            At SM Technology, we are a freelance-based digital agency
            specializing in UI/UX design, web development, and business
            administrative services. 
          </p>
          <Link to="about" onClick={handleClick}>
            <ButtonArrowTop name="Learn More" />
          </Link>
        </div>
        <Gallery />
        <Counter />
      </div>
    </section>
  );
};

export default AboutOurselves;
