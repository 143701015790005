import React, { useEffect, useRef, useState } from "react";

export const TextAriaFiled = ({
  className,
  className2,
  placeholder,
  name = "message",
  required = true,
}) => {
  const textareaRef = useRef(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    // Automatically adjust textarea height on mount and update
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Set initial height to 1px
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="relative w-full">
      <label className="">
        <p
          className={`mb-5 flex items-center gap-1  text-xl font-semibold ${className2}`}
        >
          <span>Project Details</span>
          <span className={`${required ? "text-red-500" : "hidden"}`}>*</span>
        </p>
      </label>
      {/* Use the ref attribute for the textarea and register it */}
      <textarea
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        ref={textareaRef}
        name={name}
        className={`disabled:resize-none appearance-none scrollbar-hide peer h-full w-full border-b-2 border-gray-500  bg-transparent    outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-darkColor focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50  text-lg font-medium resize-none`}
      ></textarea>
    </div>
  );
};
