import { Job } from "./Job";
import { jobs } from "./ourValues";

export const Jobs = () => {
  return (
    <section  className="pb-[120px] bg-[#E7F0EE]" id="job-section" >
      <div className="container">
        <div className="py-16">
          <div className="font-bold text-3xl sm:text-4xl  lg:text-7xl  pb-10  flex sm:flex-row flex-col gap-2 sm:gap-6 items-center">
            <h2 className="text-darkColor ">Hi, We are</h2>
            <h2 className="max-w-fit text-secondary animate-typing md:leading-[84px]  overflow-hidden whitespace-nowrap">
              SM Technology
            </h2>
          </div>
        </div>

        <div className="flex flex-col gap-10 ">
          {jobs.map((job) => (
            <Job key={job.id} job={job} />
          ))}
        </div>
      </div>
    </section>
  );
};
