import { Images } from "./images";

//Data Entry Specality content
export const DataEntrySpecality = {
  title: ["Let us help you elevate", "your digital presence"],
  cardContent: [
    {
      id: 1,
      title: "Accuracy Assurance",
      description:
        "Guaranteed precision with dedicated accuracy assurance services for efficient data entry.",
      image: "https://i.ibb.co/590b9Lt/data-Card1-6ce3acc25acd83127d40.webp",
    },

    {
      id: 2,
      title: "Timely proccesing",
      description:
        "Optimize data entry with timely processing solutions for enhanced efficiency and reliable project management.",
      image: "https://i.ibb.co/c3F9Pdx/data-Card2-bd7330a5f6f33446058c.webp",
    },

    {
      id: 3,
      title: "Custom Solutions",
      description:
        "Optimizing data entry with tailored approaches for efficient and accurate processing.",
      image: "https://i.ibb.co/Rg5p222/data-Card3-7a495d0fdb0d6ca5cb9e.webp",
    },

    {
      id: 4,
      title: "Confidential Handling",
      description:
        "Ensuring Data Security and Privacy in Customized Solutions for Efficient Data Entry Processing.",
      image: "https://i.ibb.co/fCnJM4n/data-Card4-303058bbe43645d0e9db.webp",
    },
  ],
};

// Data entery services content
export const dataEntryServices = {
  title: "Data Entry Services",
  services: [
    {
      title: "QuickData Entry",
      image:
        "https://i.ibb.co/4gVMD5p/Quick-Data-Entry-9997baaa2c36ce9de35f.jpg",
      description:
        "Efficient data entry solutions streamline operations. Experience accuracy and speed with our services.",
    },
    {
      title: "DataMasters Solutions",
      image: Images.dataMasters_Solutions,
      description:
        "Unlock data's power with us. Our expert team ensures precision and reliability in every entry.",
    },
    {
      title: "SwiftInput Services",
      image: "https://i.ibb.co/2jRV8Tx/Swift-Input-Services.webp",
      description:
        "Fast, accurate data entry solutions tailored to your needs. Elevate efficiency with our reliable services.",
    },
    {
      title: "PrecisionData Solutions",
      image: "https://i.ibb.co/52c6t42/Precision-Data-Solutions.webp",
      description:
        "We deliver precise data entry services, focusing on quality and accuracy. Trust us with your data.",
    },
  ],
  button_text: "Contact Us Now",
  link: "/contact",
};

// Why Chuse us sevices text for data entry with accordion
export const dataEntrywhayChuseUs = {
  services: [
    {
      id: 1,
      title: `High-Quality Work and On-time Delivery`,
      image: Images.dimond1,
    },
    {
      id: 2,
      title: `Committed to providing the highest quality service.`,
      image: Images.badge1,
    },
    {
      id: 3,
      title: `Quick Response with 24/7 Support`,
      image: Images.tecnical1,
    },
    {
      id: 4,
      title: `30 Days of Free Support After the Delivery`,
      image: Images.productivaty1,
    },
  ],

  question: [
    {
      title: "What kinds of data entry do you do?",
      answer:
        "We can help with different types of data tasks like organizing information, fixing errors, and converting data to different formats.",
    },
    {
      title: "How safe is my information with you?",
      answer:
        "Your data is very secure with us. We use special methods to keep it safe, and we follow all the rules to protect your information.",
    },
    {
      title: "What kinds of files can I send for data entry?",
      answer:
        "You can send us information in Excel, CSV, or other common formats. Just let us know what works best for you!",
    },
    {
      title: "How long does it take to finish a data entry job?",
      answer:
        "Usually, it takes a certain amount of time to finish the job. We'll make sure to tell you when you can expect it to be done",
    },
    {
      title: "How can I send my information to you? ",
      answer:
        "You can send us your data through our website, by email, or however you find easiest. We want to make it simple for you!",
    },
    {
      title: "How do you make sure my data is correct?",
      answer:
        "We check the data carefully to make sure it's right. We have special steps to make sure everything is accurate and good quality.",
    },
    {
      title: "How much does it cost, and how can I pay?",
      answer:
        "We have different plans you can choose from. We'll tell you how much it costs, and you can pay with your preferred method, like credit cards or other options.",
    },
  ],
};

// About Data entry
export const aboutForDataEntry = {
  title: "Elevate Your Customer Experience through Data Entry",
  description:
    "Unlock the potential of data to craft unforgettable customer experiences. Our precise and efficient data entry services ensure seamles operations and customer satisfaction.",
  buttonText: "Transform Data",
};

//

// Data Entry deliverables
export const dataEntrydeliverables = {
  title: "Deliverables ",
  categoryTitle1: "Data Entry:",
  category1: [
    "Data Cleansing",
    "Data Conversion",
    "Data Verification",
    "Data Entry Automation",
    "Data Quality Assurance",
  ],
  category2: [
    "Data Analysis",
    "Data Migration",
    "Data Entry Management",
    "Document Indexing",
    "Data Entry Outsourcing",
  ],
};

// Working proccess data entry
export const dataEntryWorkingProccess = {
  title: ["Strategic Working Process", "for Business Success"],
  process: [
    "Collection",
    "Validation",
    "Entry",
    "Verification",
    "Cleaning",
    "Conversion",
    "Integration",
  ],
};

// data entry Gallery
export const dataEntryGallery = {
  buttonText: "View more projects",
  link: "/our-works",
  images: [
    "https://i.ibb.co/096mMPz/data4-8167b748234ccd217cdb.webp",
    "https://i.ibb.co/cywZyb1/data2-9b457534d677e5e7bc58.webp",
    "https://i.ibb.co/zZWzwxV/data3-0e5147d50494a80d7939.webp",
    "https://i.ibb.co/cywZyb1/data2-9b457534d677e5e7bc58.webp",
    "https://i.ibb.co/MVNPnRN/data5-a0fc9e82b1b5af6105bf.webp",
    "https://i.ibb.co/L8KXGQD/data6-504b3c514ba29476dd35.webp",
    "https://i.ibb.co/ZJ6JX9B/data7-c24552e6f2cfb14e48d0.webp",
  ],
};
