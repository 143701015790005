export const JobStyledBtn = ({ icon, btnText }) => {
  return (
    <>
      {
        btnText === "View job" ?
          (
            <div className="flex items-center gap-2 text-lg font-medium bg-bgColor1 text-[#1D2939] px-8 py-3 text-center justify-center cursor-pointer rounded-full">
              <span>{btnText}</span>
              <span className="material-symbols-outlined">{icon}</span>
            </div>
          )
          :
          (
            <div className="flex items-center gap-2 text-sm font-medium bg-bgColor1 text-[#1D2939] px-2 text-center justify-center lg:px-5 py-2 rounded-full">
              <span className="material-symbols-outlined">{icon}</span>
              <span>{btnText}</span>
            </div>
          )
      }
    </>
  )
}
