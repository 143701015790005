export const SubmitArrowTop = (props) => {
  return (
    <>
      <div className="flex items-center h-[54px] w-fit group relative">
        <div className={`w-[45px] h-[45px] mt-[5px] md:mt-0 md:w-[54px] md:h-[54px] ml-[7px] md:ml-0 rounded-full text-white grid place-items-center absolute top-0 left-0 ${props.disabled ? 'bg-gray-400' : 'bg-secondary '}`}>
          <span className="material-symbols-outlined text-2xl">north_east</span>
        </div>

        <button
          disabled={props.disabled}
          type="submit"
          className={`bg-secondary border border-secondary text-white md:text-xl py-3 flex items-center gap-2 disabled:bg-gray-400 disabled:border-gray-400 ${
            props.px ? `${props.px}` : "px-5"
          } font-medium rounded-full group-hover:translate-x-[54px] disabled:group-hover:translate-x-0 disabled:cursor-not-allowed transition-transform disabled:transition-none ease-out duration-200 disabled:duration-0 z-10`}
        >
          {props.disabled && (
            <span class="loading loading-ring loading-md"></span>
          )}
          {props?.name}
        </button>
        <div className={`w-[45px] h-[45px] mt-[5px] md:mt-0 md:w-[54px] md:h-[54px]  md:ml-0 bg-secondary rounded-full text-white grid place-items-center ${props.disabled ? 'bg-gray-400' : 'bg-secondary '}`}>
          <span className="material-symbols-outlined text-2xl">north_east</span>
        </div>
      </div>
    </>
  );
};
