import { useQuery } from "@tanstack/react-query";
import { AboutServices } from "../../components/aoboutServices";
import { OurSpecality } from "../../components/ourSpecality";
import { ServicePageBanner } from "../../components/servicePageBanner";
import { Services } from "../../components/services";
import { ServicesClientsFeedback } from "../../components/servicesClientsFeedback";
import { ServicesFeatuers } from "../../components/servicesFeatuers";
import { ServicesGallary } from "../../components/servicesGallary";
import { ServicesProjectInMind } from "../../components/servicesProjectInMind";
import { WhayChoseUS } from "../../components/whayChoseUs";

import {
  aboutEmailMarketingServices,
  emailMarketingGallery,
  emailMarketingServices,
  emailMarketingSpecality,
  emailMarketingWorkingProccess,
  emailMarketingbDeliverables,
  emailMarketingwhayChuseUs,
} from "../../utils/emailMarketingServiceInfo";

import { StragicWorking } from "../ui-ux/strategicWorking";
import axios from "axios";

export const Email = () => {
  const {
    error,
    data: email_marketing,
    isLoading,
  } = useQuery({
    queryKey: ["email_marketing"],
    queryFn: () => {
      const res = axios.get("/sercices.json");
      return res;
    },
  });

  const filterData = email_marketing?.data.filter(item => item.category.toLowerCase() === "email marketing") || [];
  return (
    <>
      <ServicePageBanner
        title="Email Marketing "
        subTitle="Services"
        description="Elevate your business with our email marketing services. Connect with customers and boost sales with tailored campaigns."
        linkText="/contact"
        buttonText="Get Started with Email Marketing"
        serviceImage="https://i.ibb.co/xgL5kTX/image-46f9a39d924132739dd4.webp"
      ></ServicePageBanner>
      {/* Email Marketing Spacality */}
      <OurSpecality {...emailMarketingSpecality} />
      {/* Eamail Marketing Services */}
      <Services {...emailMarketingServices} />
      {/* About Email Marketing Services */}
      <AboutServices {...aboutEmailMarketingServices} />
      {/* Email Marketing Serivice Features */}
      <ServicesFeatuers {...emailMarketingbDeliverables} />
      {/* Email Marketing Working Process */}
      <StragicWorking {...emailMarketingWorkingProccess} />
      {/* Email Marketing Gallery */}
      <ServicesGallary {...emailMarketingGallery} />
      {/* Clients Feedback */}
      <ServicesClientsFeedback />
      {/* Email Markegitn Whay Chuse Us */}
      <WhayChoseUS {...emailMarketingwhayChuseUs} />
      {/* Project in Mind  */}
      <ServicesProjectInMind error={error} isLoading={isLoading} categories={filterData[0]?.subServices}  />
    </>
  );
};
