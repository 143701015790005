import { Images } from "../../utils/images";

export const EmployeesFeedback = () => {
  return (
    <section className="py-[120px] bg-bgColor2 text-white group">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 ">
          <div className="xl:text-[120px] text-4xl sm:text-7xl font-bold relative my-10">
            <h4 className="text-[#A6A6A6] absolute  -top-10 xl:-top-20 lg:-left-20 z-20 lg:group-hover:left-20 transition-all duration-500 ease-in-out">
              Employees
            </h4>
            <img
              className="w-[550px] h-[550px] object-cover rounded-2xl z-10 relative"
              src="https://i.ibb.co/89zF2b9/jabed.jpg"
              alt="open communication"
            />
            <h4 className="text-[#A6A6A6] text-end absolute -bottom-5 xl:-bottom-10 lg:-right-40 lg:group-hover:-right-10 transition-all duration-500 ease-in-out z-10">
              Feedback
            </h4>
          </div>

          <div className="max-w-xl lg:justify-self-end flex flex-col gap-10">
            <img className="w-fit" src={Images.Quotation} alt="quotation" />
            <p className="xl:text-4xl text-base md:text-xl font-medium">
            Working at SM Technology has been an enriching experience. The company's commitment to excellence in web development, UI/UX design, and cutting-edge email marketing is truly inspiring. The supportive team culture and emphasis on continuous growth make SM Technology a fantastic place to contribute and thrive.
            </p>
            <div>
              <h4 className="text-[#E8FAF5] text-xl md:text-2xl">Abu Sadek Jabed</h4>
              <p className="text-[#B0B0B0]">GM, Sales</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
