import { InputFiled } from "../inputFiled";
import { SubmitArrowTop } from "../submit";
import { TextAriaFiled } from "../textAriaFiled";
import {  useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export const ServicesProjectInMind = ({
  categories = [],
  error,
  isLoading,
}) => {
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const form = new FormData(e.target);
    const name = form.get("email");
    const email = form.get("email");
    const company_name = form.get("company_name") || "not given";
    const category = form.get("service") || "not given";
    const service = "UI UX Design ";
    const message = form.get("message");

    if (category === "Select Your Category") {
      Swal.fire({
        title: "Category is not selected",
        icon: "error",
      });
      return;
    }
    try {
      const res = await axios.post(
        "https://smt-server-rho.vercel.app/contact-us",
        {
          name,
          email,
          company_name,
          service,
          category,
          message,
        }
      );

      if (res.data.success) {
        Swal.fire({
          title: res.data.message,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: res.data.message,
          icon: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: error.message,
        icon: "error",
      });
    }
  };

  if (isLoading) return <h1>Loading...</h1>;
  if (error) return <h1>{error}</h1>;

  return (
    <section className="section-padding-services-page bg-[#0B0B0B] overflow-x-hidden">
      <div className="container">
        <h2
          className="font-heading font-black text-xl md:text-3xl lg:text-4xl  leading-normal md:leading-[60px] text-[#FFDE00] text-center pb-10"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          Ready to take your online business to the next level? <br />
          Join us at SM Technology, and we will deliver results,<br /> NOT
          excuses!
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="container "
        data-aos="zoom-in"
        data-aos-duration="3000"
      >
        <div className="bg-bgColor2 py-[60px] px-[40px] rounded-[40px]">
          <h2 className="text-white font-bold text-2xl sm:text-4xl md:text-7xl ">
            Project in mind ?
          </h2>

          <div className=" text-white mt-16 ">
            <div className="gap-[42px] grid grid-cols-1 md:grid-cols-2 mb-[42px]">
              <InputFiled
                requred={false}
                type="text"
                name="name"
                placeholder="Jhon Doe"
                label="Name"
              ></InputFiled>
              <InputFiled
                required={false}
                type="text"
                name="company_name"
                placeholder="Walmart"
                label="Company Name"
              ></InputFiled>
            </div>
            <div className="flex flex-col gap-[42px]">
              <div className="flex  gap-6 items-center">
                <InputFiled
                  type="email"
                  name="email"
                  placeholder="your@example.com"
                  label="Email"
                ></InputFiled>
                {categories.length ? (
                  <div className="w-full relative">
                    <select
                      name="service"
                      required
                      placeholder="Select Your Service"
                      className={`
              w-full peer h-full  border-b-2 border-white/70 bg-transparent pt-4 pb-1.5 font-family  font-medium text-lg text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:text-white rounded-t-none rounded-b-none   focus:border-gray-500 text-white/70 focus:outline-0  disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100
              "cursor-not-allowed
              `}
                    >
                      <option
                        className="text-white/70 bg-bgColor2 font-medium rounded-none"
                        defaultValue={"Select Your Service"}
                      >
                        Select Your Category
                      </option>

                      {categories?.map((item, idx) => (
                        <option
                          key={idx}
                          className="rounded-none bg-bgColor2"
                          value={item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                    <label className="after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-white/70 transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-white peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1">
                      <p
                        className={`mb-8 flex items-center gap-1  text-xl font-semibold 
                        text-white/70"
                      `}
                      >
                        Select Category{" "}
                        <span className={"text-red-500"}>*</span>
                      </p>
                    </label>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <TextAriaFiled placeholder="Tel us more about idea"></TextAriaFiled>

              <div className="mt-8">
                <SubmitArrowTop disabled={loading} name="Send Inquiry" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
