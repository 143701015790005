import { Link, useParams } from "react-router-dom";
import { works } from "../../utils/postData";

export const Blog = () => {
  const { id } = useParams();

  const filterBlog = works.find(
    (item) => item.title.split(" ").join("-").toLowerCase() === id
  );

  const {
    image,
    image2,
    title,
    description,
    category,
    client,
    live_view,
    timelines,
    service_we_provide,
    publish_date,
    project_details,
  } = filterBlog;

  const { problems, our_solutions, challenges } = project_details;

  return (
    <section className="bg-[#1a1a1a] py-[120px] text-white">
      <div className="container">
        <div className="text-sm font-medium">
          <p className="flex gap-2 sm:items-center sm:flex-row flex-col">
            <span>
              {category[0]}, {category[1]}
            </span>
            <span className="text-secondary text-2xl sm:flex hidden">.</span>
            <span>{publish_date}</span>
          </p>
        </div>

        <h2 className="bg-gradient-to-l from-white to-gray-300 bg-clip-text text-transparent font-bold mt-12 text-3xl">
          {title}
        </h2>
        <div className=" grid lg:grid-cols-2 grid-cols-1  w-full  mt-20 relative border-2 border-sky-100 shadow-md">
          <img
            className=" sm:h-[500px] w-full object-cover relative z-10 lg:block hidden"
            src={image}
            alt={title}
          />
          <img
            className=" sm:h-[500px] w-full object-cover relative z-10"
            src={image}
            alt={title}
          />
          <div className="absolute hidden sm:flex text-center justify-center items-center w-full h-full top-0 left-0  z-20">
            <div className="bg-slate-600/70 backdrop-blur-sm p-4 rounded-xl text-3xl">
              <h2 className="bg-gradient-to-l from-white to-gray-300 bg-clip-text text-transparent font-bold">
                {title}
              </h2>
            </div>
          </div>
        </div>

        <div className="border-y border-gray-600 pb-20 pt-10 mt-20 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:gap-0 gap-7 xl:grid-cols-5 grid">
          <div className="space-y-2">
            <h3 className="text-xl text-gray-400">Client</h3>
            <h5 className="text-base">{client}</h5>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl text-gray-400">Category</h3>
            <h5 className="text-base flex gap-1 items-center">
              <span>{category[0]}</span>/<span>{category[1]}</span>
            </h5>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl text-gray-400">LIVE VIEW</h3>
            <h5 className="text-base flex gap-1 items-center">
              <Link
                className="underline hover:text-secondary duration-200 pb-1"
                to={live_view}
                target="_blank"
              >
                Visit Website
              </Link>
            </h5>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl text-gray-400">TIMELINES</h3>
            <h5 className="text-base flex gap-1 items-center">{timelines}</h5>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl text-gray-400">SERVICE WE PROVIDED</h3>
            <ul className="text-base font-medium flex flex-col gap-3">
              {service_we_provide?.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-16 border-gray-600 border-b pb-10">
          <h3 className="text-2xl font-medium mb-3">About</h3>
          <p>{description}</p>
        </div>

        {image2 ? (
          <div className="my-20 grid grid-cols-1 ">
            <img
              className=" w-full object-cover h-[500px] border-2 border-slate-300"
              src={image2}
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        <div className="mt-16 space-y-12">
          <div>
            <h3 className="text-2xl font-medium mb-3">Problems</h3>
            <p>{project_details.problems}</p>
          </div>
          <div>
            <h3 className="text-2xl font-medium mb-3">Challenges</h3>
            <div className="space-y-8">
              {challenges?.map((item, idx) => (
                <p key={idx}>{item}</p>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-medium mb-3">Our Solutions</h3>
            <div className="space-y-8">
              {our_solutions?.map((item, idx) => (
                <p key={idx}>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
