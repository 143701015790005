import React from "react";

export const JobStyledContent = ({ icon, text }) => {
  return (
    <div className="flex items-center gap-1 text-xl font-medium">
      <div className="lg:w-8  lg:h-8 w-4 h-4 bg-bgColor1 rounded-full grid place-items-center">
        <span className="material-symbols-outlined text-bgColor2">{icon}</span>
      </div>
      <p className="lg:text-xl text-lg">{text}</p>
    </div>
  );
};
