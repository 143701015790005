import { AboutServices } from "../../components/aoboutServices";
import { OurSpecality } from "../../components/ourSpecality";
import { ServicePageBanner } from "../../components/servicePageBanner";
import { Services } from "../../components/services";
import { ServicesClientsFeedback } from "../../components/servicesClientsFeedback";
import { ServicesFeatuers } from "../../components/servicesFeatuers";
import { ServicesGallary } from "../../components/servicesGallary";
import { ServicesProjectInMind } from "../../components/servicesProjectInMind";
import { WhayChoseUS } from "../../components/whayChoseUs";
import { Images } from "../../utils/images";
import {
  LeadGenSpecality,
  aboutLeadGeneration,
  leadGenerationDeliverables,
  leadGenerationGallery,
  leadGenerationServices,
  leadGenerationWhayChuseUs,
  leadGenerationWorkingProccess,
} from "../../utils/leadGenerationServiceInfo";
import { StragicWorking } from "../ui-ux/strategicWorking";

export const Lead = () => {
  return (
    <>
      <ServicePageBanner
        title="Lead Generation"
        subTitle="Services"
        description="Grow your business with our lead generation services. We'll help you find new customers using smart strategies"
        linkText="/contact"
        buttonText="Get Started with Lead Generation"
        serviceImage="https://i.ibb.co/HGbKrph/data-New-bf2396ace9002f7dd8de.png"
      ></ServicePageBanner>
      {/* Lead Genearation Spacality */}
      <OurSpecality {...LeadGenSpecality} />
      {/* Lead Generation Services */}
      <Services {...leadGenerationServices} />
      {/* About Leag Generation Services */}
      <AboutServices {...aboutLeadGeneration} />
      {/* Lead Generation Services deliverables */}
      <ServicesFeatuers {...leadGenerationDeliverables} />
      {/* Lead Generatio Stagic working proccess */}
      <StragicWorking {...leadGenerationWorkingProccess} />
      {/* Lead Generation Gallery  */}
      <ServicesGallary {...leadGenerationGallery} />
      {/* Clients Feed back */}
      <ServicesClientsFeedback />
      {/* Lead Generation why chuse us and accordian section */}
      <WhayChoseUS {...leadGenerationWhayChuseUs} />
      {/* Project in mind */}
      <ServicesProjectInMind />
    </>
  );
};
