import { TfiArrowTopRight } from "react-icons/tfi";
import { ServicePageTitle } from "../servicePageTitle";

export const ServicesFeatuers = ({
  title,
  categoryTitle1,
  categoryTitle2 = " ",
  category1,
  category2,
}) => {
  return (
    <section className=" section-background-3  section-padding-services-page  overflow-x-hidden">
      <div className="container">
        <div
          className="flex justify-center items-center service-title-box-padding"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <ServicePageTitle>
            <h2 className="service-title-font-size">{title}</h2>
          </ServicePageTitle>
        </div>

        <div
          className="grid grid-cols-1  lg:grid-cols-2 gap-10 text-white"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <div>
            <h3 className="text-xl md:text-3xl font-bold md:mb-10 mb-8 lg:mb-[72px]">
              {categoryTitle1}
            </h3>
            <div className="space-y-8">
              {category1?.map((item, idx) => (
                <div
                  key={idx}
                  className="flex flex-col gap-6 text-base sm:text-xl"
                >
                  <p className="flex gap-5 text-center">
                    <TfiArrowTopRight className="text-[#ffde00]" />
                    <span className=" capitalize">{item}</span>
                  </p>
                  <hr className=" border-[#095442]" />
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-xl md:text-3xl font-bold md:mb-10 mb-8 lg:mb-[72px]">
              {categoryTitle2 === " " ? (
                <span className="block opacity-0">fddfdfds</span>
              ) : (
                categoryTitle2
              )}
            </h3>
            <div className="space-y-8">
              {category2?.map((item, idx) => (
                <div
                  key={idx}
                  className="flex flex-col gap-6 text-base sm:text-xl"
                >
                  <p className="flex gap-5 text-center">
                    <TfiArrowTopRight className="text-[#ffde00]" />
                    <span className=" capitalize">{item}</span>
                  </p>
                  <hr className=" border-[#095442]" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
