import { useQuery } from "@tanstack/react-query";
import { SubmitArrowTop } from "../../components/submit";
import { InputFiled } from "../../components/inputFiled";
import axios from "axios";
import { useEffect, useState } from "react";
import { TextAriaFiled } from "../../components/textAriaFiled";
import { Images } from "../../utils/images";
import React from "react";
import Swal from "sweetalert2";

export const Contact = () => {
  const [services, setServices] = useState([]);
  const [selecteService, setSelectedService] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("not given");

  const { isLoading, error, data } = useQuery({
    queryKey: ["services"],
    queryFn: () =>
      axios.get("./sercices.json").then((response) => response.data),
  });

  useEffect(() => {
    if (selecteService === "Select Your Service" || selecteService === "") {
      setIsDisabled(true);
    } else {
      const fileterData = data?.filter(
        (item) => item.category.toLowerCase() === selecteService
      );

      setServices(fileterData);

      setIsDisabled(!fileterData.length);
    }
  }, [selecteService, data]);

  const handleSelect = (e) => {
    setSelectedService(e.target.value.toLowerCase());
    setIsDisabled(e.target.value === "Select Your Service");
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const form = new FormData(e.target);

    const name = form.get("name");
    const email = form.get("email");
    const company_name = form.get("company_name") || "not given";
    const service = selecteService;
    const message = form.get("message");

    try {
      const res = await axios.post(
        "https://smt-server-rho.vercel.app/contact-us",
        {
          name,
          email,
          company_name,
          service,
          category,
          message,
        }
      );

      if (res.data.success) {
        Swal.fire({
          title: res.data.message,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: res.data.message,
          icon: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoading(false);
      Swal.fire({
        title: error.message,
        icon: "error",
      });
    }
  };

  const handleCategory = (e) => {
    if (e.target.value === "Select Your Service") {
      setCategory("not given");
    } else {
      setCategory(e.target.value);
    }
  };

  if (isLoading) return <h1>Loading...</h1>;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <section className="bg-[#ECF0F1] py-[120px]">
        <div className="container">
          <div className="text-[36px] md:text-[80px] text-[#4c4c4c] text-7xl font-black mb-36">
            <span>Let's work</span>
            <div className="flex items-center gap-5">
              <span className="md:w-24 md:h-4 bg-[#cccc] inline-block"></span>
              <span>Together.</span>
            </div>
          </div>

          <form
            id="contact"
            className="grid md:flex flex-col gap-14"
            onSubmit={handleSubmit}
          >
            <InputFiled
              label="Name"
              required={false}
              placeholder="Your Name"
              name="name"
              type="text"
              className={
                "border-gray-500 text-blue-gray-700 placeholder:text-gray-500 text-darkColor text-xl font-semibold"
              }
              className2={"text-darkColor peer-focus:after:border-darkColor"}
            />
            <InputFiled
              className={
                "border-gray-500 text-blue-gray-700 placeholder:text-gray-500 text-darkColor text-xl font-semibold"
              }
              className2={"text-darkColor peer-focus:after:border-darkColor"}
              type="email"
              label="Email"
              required={true}
              placeholder="Your@example.com"
              name="email"
            />
            <div className="w-full relative">
              <select
                onChange={handleSelect}
                placeholder="Select Your Service"
                name="services"
                className="w-full peer h-full  border-b-2 border-gray-500 bg-transparent pt-4 pb-1.5 font-family  font-medium text-lg text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:text-darkColor rounded-t-none rounded-b-none   focus:border-gray-500 text-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
              >
                <option
                  className="text-gray-500 font-medium rounded-none"
                  defaultValue={"Select Your Service"}
                >
                  Select Your Service
                </option>
                <option className="rounded-none" value="UI UX Design">
                  UI UX Design
                </option>
                <option className="rounded-none" value="Web Development">
                  Web Development
                </option>
                <option className="rounded-none" value="Email Marketing">
                  Email Marketing
                </option>
                <option className="rounded-none" value="Lead Generation">
                  Lead Generation
                </option>
                <option className="rounded-none" value="Data entry">
                  Data Entry
                </option>
              </select>
              <label className="after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-900 transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-darkColor peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1">
                <p className="mb-8 flex items-center gap-1 text-darkColor text-xl font-semibold">
                  <span>Service Required</span>
                  <span className="text-red-500">*</span>
                </p>
              </label>
            </div>
            <div className="w-full relative">
              <select
                onChange={handleCategory}
                required={!isDisabled}
                disabled={isDisabled}
                placeholder="Select Your Service"
                name="category"
                className={`
              w-full peer h-full  border-b-2 border-gray-500 bg-transparent pt-4 pb-1.5 font-family  font-medium text-lg text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:text-darkColor rounded-t-none rounded-b-none   focus:border-gray-500 text-gray-500 focus:outline-0  disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100
              ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
              `}
              >
                <option
                  className="text-gray-500 font-medium rounded-none"
                  defaultValue={"Select Your Service"}
                >
                  Select Your Service
                </option>

                {services.length > 0
                  ? services[0].subServices.map((item, idx) => (
                      <option key={idx} className="rounded-none" value={item}>
                        {item}
                      </option>
                    ))
                  : ""}
              </select>
              <label className="after:content[' '] pointer-events-none absolute left-0   -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-900 transition-all  after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-[3px] after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-darkColor peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 items-center gap-1">
                <p
                  className={`mb-8 flex items-center gap-1 text-darkColor text-xl font-semibold ${
                    isDisabled ? " text-gray-500" : "text-darkColor"
                  }`}
                >
                  <span>Category</span>
                  {isDisabled ? "" : <span className="text-red-500">*</span>}
                </p>
              </label>
            </div>
            <TextAriaFiled placeholder={"Tell us more about idea"} />

            <div>
              <div>
                <SubmitArrowTop disabled={loading} name="Send Inquiry" />
              </div>
            </div>
          </form>
        </div>
      </section>

      <section className="py-[120px] container">
        <div className="md:text-[100px] text-[46px] font-bold max-w-2xl">
          <h4 className="text-[#000] leading-[75px]">Find Our</h4>
          <h4 className="text-[#7B7B7B] md:text-end md:ml-0 ml-7">Location</h4>
        </div>

        <div className=" mt-[100px] space-y-10">
          <div className="flex md:items-center md:flex-row flex-col border-b  md:gap-0 gap-8">
            {/* <p className="text-3xl font-bold w-[12%]">{sheridanTime}</p> */}
            <div className="flex-1  ">
              <div className="flex items-center md:flex-row flex-col border-b pb-7 md:gap-0 gap-8">
                {/* <p className="text-3xl font-bold w-full md:w-[12%]">
              {bangladeshTime}
            </p> */}
                <div className="md:flex-1  w-full">
                  <div className="lg:flex grid grid-cols-1 sm:grid-cols-2 lg:gap-0 gap-4 lg:justify-between items-start  lg:items-center">
                    <div>
                      <h2 className="text-2xl font-bold pb-12">Bangladesh</h2>
                      <h4 className="text-2xl font-normal text-gray-500 mb-2 uppercase">
                        Address
                      </h4>
                      <p className="text-lg font-semibold text-darkColor ">
                        House #11/13, Road #5,
                        <br /> Block C, Banasree,
                        <br />
                        Rampura Dhaka 1219
                      </p>
                    </div>
                    <div>
                      <h4 className="text-2xl font-normal text-gray-500 mb-2 md:pt-20 lg:pt-7 uppercase">
                        CONTACT
                      </h4>
                      <p className="text-lg font-semibold text-darkColor ">
                        hello@smtech24.com
                        <br />
                      </p>
                    </div>
                    <div>
                      <h4 className="text-2xl font-normal text-gray-500 mb-2 uppercase">
                        SEE MAP
                      </h4>
                      <a
                        rel="noreferrer"
                        href="https://maps.app.goo.gl/eijWSwf1FDhEzJtK9"
                        target="_blank"
                        className="flex items-center gap-2 text-xl font-semibold text-secondary rounded-lg overflow-hidden"
                      >
                        <img
                          className="h-full w-full hover:scale-125 duration-300"
                          src={Images.bangladehsLocation}
                          alt="Bangladesh office location"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:flex grid grid-cols-1 sm:grid-cols-2 lg:gap-0 gap-4 lg:justify-between items-center">
            <div>
              <h2 className="text-2xl font-bold pb-12">United States</h2>
              <h4 className="text-2xl font-normal text-gray-500 mb-2 uppercase">
                Address
              </h4>
              <p className="text-lg font-semibold text-darkColor ">
                30 N Gould St, Sheridan,
                <br /> Wyoming 82801, US
              </p>
            </div>
            <div>
              <h4 className="text-2xl font-normal text-gray-500 mb-2 md:pt-20 pt-8 uppercase">
                CONTACT
              </h4>
              <p className="text-lg font-semibold text-darkColor ">
                hello@smtech24.com
                <br />
                +1 (307)-207-3020
              </p>
            </div>
            <div>
              <h4 className="text-2xl font-normal text-gray-500 mb-2 uppercase">
                SEE MAP
              </h4>
              <a
                rel="noreferrer"
                href="https://maps.app.goo.gl/hRwL594bqioxuEhBA"
                target="_blank"
                className="flex items-center gap-2 text-xl font-semibold text-secondary overflow-hidden rounded-lg"
              >
                <img
                  className="h-full w-full hover:scale-125 duration-300"
                  src={Images.unitedStateLocation}
                  alt="Bangladesh office location"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

/* 
<h4 className="text-xl font-normal text-gray-500 mb-2 uppercase">
              Address
            </h4>
            <p className="text-lg font-semibold text-darkColor ">
              123 Imaginary Street Fantasy Town,
              <br /> Wonderland F4K3 4DD United Unrealities
            </p>
 */
