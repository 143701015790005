import { NavLink } from "react-router-dom";
import { Images } from "../../utils/images";
import { useState } from "react";

const { ui_ux, Webdev, email_marketing, lead_genaretion, data_entry } = Images;

const images = [
  {
    image: ui_ux,
    name: "UI UX Design",
  },
  {
    image: Webdev,
    name: "Web Development",
  },
  {
    image: email_marketing,
    name: "Email Marketing",
  },
  {
    image: lead_genaretion,
    name: "Lead Generation",
  },
  {
    image: data_entry,
    name: "Data Entry",
  },
];

export const ServiceMenu = ({
  openServices,

  handleSubmenuItemClicked,
}) => {
 
  const [currentImage, setCurrentImage] = useState(0);

  const handleMouseOver = (idx) => {
    setCurrentImage(idx);
  };
  console.log(openServices)

  return (
   
    <div id="service-menu"
      className={` flex h-full  items-center overflow-hidden duration-500 ease-linear transition-opacity mt-16  ${openServices ? ' opacity-100' : ' opacity-0'} `}
    >
      <div className={`max-w-5xl mx-auto overflow-hidden flex justify-between mt-0  relative  w-full `}>
        <div className={`h-[300px] w-[600px] relative duration-2000 transition-opacity rounded-md overflow-hidden `}>
          {images.map((item, idx) => (
            <img key={idx}
              className={`h-full rounded-md w-full object-cover duration-1000 transition-all absolute top-0 left-0 ${idx === currentImage ? 'z-20  scale-100' : 'z-0  scale-0'}`}
              src={item.image}
              alt={item.name}
            />
          ))}
        </div>
        <ul className="flex flex-col gap-6 text-2xl font-medium text-white text-right">
          {servicesMenu.map((item, idx) => (
            <ServiceNavLink
              key={idx}
              {...item}
              idx={idx}
              handleMouseOver={handleMouseOver}
              setCurrentImage={setCurrentImage}
              handleSubmenuItemClicked={handleSubmenuItemClicked}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const ServiceNavLink = ({
  path,
  title,
  idx,
  handleMouseOver,
  setCurrentImage,
  handleSubmenuItemClicked,
}) => (
  <li
    onMouseOver={(e) => {
      e.stopPropagation();
      handleMouseOver(idx);
    }}
    onMouseLeave={(e) => {
      e.stopPropagation();
      setCurrentImage(0);
    }}
  >
    <NavLink
      to={path}
      className="hover:text-secondary duration-150 after:block relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-[3px] after:bg-secondary hover:after:w-full pb-1 after:duration-500 after:transition-all"
      onClick={handleSubmenuItemClicked}
    >
      {title}
    </NavLink>
  </li>
);

const servicesMenu = [
  {
    id: 1,
    title: "UI UX Design",
    path: "ui-ux",
    finding: "ui_ux",
  },
  {
    id: 2,
    title: "Web Development",
    path: "web-development",
    finding: "Webdev",
  },
  {
    id: 3,
    title: "Email Marketing",
    path: "email-marketing",
    finding: "email_marketing",
  },
  {
    id: 4,
    title: "Lead Generation",
    path: "lead-generation",
    finding: "lead_genaretion",
  },
  {
    id: 5,
    title: "Data Entry Service",
    path: "data-entry",
    finding: "data_entry",
  },
];
