import React from "react";
import { CustomSolution } from "../customSolution";

export const CustomSolutions = () => {
  return (
    <section className="py-20 md:py-[120px] bg-bgColor2 ">
      <div className="container">
        <div data-aos="flip-left">
          <div className="font-bold sm:text-7xl ">
            <div className="w-max">
              <h5 className="sm:mb-4 mb-3 text-white text-2xl">Our Services</h5>
              <h2 className="max-w-fit text-[#FFDE00] animate-typing overflow-hidden whitespace-nowrap pb-8 md:text-7xl text-3xl sm:text-4xl">
                Custom Solutions
              </h2>
            </div>
            <h2 className="sm:mb-4 mb-0 text-white md:text-7xl text-3xl sm:text-4xl">
              For Your Success
            </h2>
          </div>
        </div>
        <div data-aos="flip-right">
          <CustomSolution />
        </div>
      </div>
    </section>
  );
};
