import { useState } from "react";
import { Accordion } from "../../../components/acordian";
import { ServicePageTitle } from "../../../components/servicePageTitle";

export const UiUxAcoridan = ({ uiuxQuestion }) => {
  const [isActiveIndex, setActiveIndex] = useState(null);

  const handleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className=" xl:pt-20 pt-14 relative z-50 overflow-x-hidden">
      <div className="container">
        <div className="flex justify-center items-center service-title-box-padding text-center ">
          <ServicePageTitle>
            <h2 className="service-title-font-size">Frequently Asked Questions</h2>
          </ServicePageTitle>
        </div>

        <div className="flex flex-col gap-8 text-white text-lg md:text-xl lg:text-2xl font-bold">
          {uiuxQuestion?.map(({ title, answer }, idx) => (
            <Accordion
              key={idx}
              idx={idx}
              title={title}
              answer={answer}
              isActive={isActiveIndex === idx}
              handleAccordion={handleAccordion}
            ></Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};
