import { ButtonArrowTop } from "../button";
// import Carousel from '../carousel'
import { LatestWork2 } from "../../pages/ourWorks/LatestWork2";

export const RecentWork = () => {
  return (
    <section className="py-[70px]   ">
      <div className="container">
        <div className="flex justify-center items-center">
          <div className="font-black md:text-7xl text-4xl sm:text-5xl  text-center">
            <h5 className="mb-4 text-darkColor text-2xl">Our Portfolio</h5>
            <h2 className="mb-4 text-secondary">Look at Our</h2>
            <h2 className="text-darkColor">Recent Work</h2>
          </div>
        </div>
        {/* Recent works carousel */}
        {/* <div>
                        <p className='text-2xl text-[#40403f] max-w-2xl pb-10'>
                            Take a stroll through our recent work and discover the exciting journeys we've embarked on. From innovative designs to successful projects, join the fun and see the vibrant spirit that defines us!
                        </p>
                        <ButtonArrowTop name="See All Works" />
                    </div> */}
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          {/* <Carousel /> */}
          <LatestWork2/>
        </div>
      </div>
    </section>
  );
};
