import { Link } from "react-router-dom";
import { ButtonArrowTop } from "../button";

import { useEffect, useRef, useState } from "react";

import { Modal } from "../modal";
import { ServicePageTitle } from "../servicePageTitle";

export const AboutServices = ({
  title,
  description,
  buttonText,
  link = "/contact",
  video = "https://i.ibb.co/nrQ0WpS/video-417bd92f1beae9f6b705.png",
}) => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);


  const handleModalOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (isOpen) {
      videoElement.currentTime = 0; // Set the current time to 0
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [isOpen]);

  return (
    <>
      <section className="bg-black overflow-x-hidden">
        <div className="container section-padding-services-page">
          <div className=" grid grid-cols-1 lg:grid-cols-2 items-center gap-0 md:gap-10 mx-auto text-white">
            <div
              className=" col-span-1"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <ServicePageTitle>
                <h2 className="xl:text-[56px] xl:leading-[64px] text-3xl leading-[40px]">
                  {title}
                </h2>
              </ServicePageTitle>
              <p className="py-8 text-base md:text-lg font-medium text-white/60 mb-10">
                {description}
              </p>

              <div className="mb-16 ">
                <Link
                  className="footerTextHoverEffect"
                  to={link}
                  onClick={handleClick}
                >
                  <ButtonArrowTop name={buttonText} />
                </Link>
              </div>
            </div>
            {/* video section */}
            <div
              className=" col-span-1 md:pl-8 relative sm:h-[444px]"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <img className="h-full w-full" src={video} alt="video" />
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center ">
                <div class="relative flex h-20 w-20">
                  <div class="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary opacity-75"></div>
                  <button
                    onClick={handleModalOpen}
                    class="relative flex justify-center items-center rounded-full h-20 w-20 bg-white text-secondary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={isOpen} handleModalOpen={handleModalOpen}>
        <div className="sm:h-full h-[250px] w-full">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            loop
            controls
          >
            <source src="/bd-calling-video.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </>
  );
};
