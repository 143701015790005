import React from "react";
import { Images } from "../../utils/images";

export const NewsLetter = () => {
  return (
    <section className="py-[120px] bg-[#F2F4F4] hidden">
      <div
        className="container"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 px-10 py-14 rounded-[40px] bg-bgColor2">
            <div className="grid md:grid-cols-2 gap-5 items-center justify-center justify-items-center">
              <div className="text-white">
                <p className="text-[#ADADAD] sm:text-xl text-base font-bold mb-4">
                  Got a project in mind?
                </p>
                <h3 className="lg:text-[64px] text-3xl  font-semibold lg:leading-[75px] leading-[40px]">
                  Let's discuss the details
                </h3>
                <button className="mt-10 bg-secondary px-7 py-3 rounded-[200px] sm:callingBtn hover:bg-white hover:text-[#13B690] duration-300 transition">
                  Schedule a Call
                </button>
              </div>

              <div>
                <div className="relative z-40">
                  <img
                    className="absolute -top-16 left-0 -z-10 animate-bounchingImage"
                    src={Images.newsletter1}
                    alt="newsletter"
                  />
                  <img
                    className="z-20"
                    src={Images.newsletter2}
                    alt="newsletter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#0C6F57] p-8 rounded-[40px] flex flex-col gap-8 group">
            <img
              className="w-24 h-24 rounded-full group-hover:w-32 group-hover:h-32 duration-200"
              src={Images.Jabed}
              alt="newsletter"
            />
            <p className="sm:text-2xl text-lg  font-medium text-white">
              My name is Jabed, GM of sales at SM Technology. I will answer all
              your questions.
            </p>
            <div className="sm:callingBtn px-7 py-3 rounded-[200px] font-bold flex items-center sm:text-xl text-base sm:gap-2 gap-2 w-fit bg-white hover:bg-black hover:text-white duration-300">
              <span>Ask Questions</span>
              <img src={Images.whatsappIcon} alt="whatsapp icon" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
