export const Accordion = ({
  idx,
  title,
  answer,
  isActive,
  handleAccordion,
}) => {
  return (
    <div>
      <div
        className="flex justify-between sm:gap-0 gap-2 items-center border-b-2 border-gray-500 pb-5 cursor-pointer"
        onClick={() => handleAccordion(idx)}
      >
        <h3 className="flex-1">{title}</h3>
        <span
          className={`flex h-8 w-8 bg-bgColor1 duration-700 rounded-full items-center justify-center text-secondary relative ${
            isActive ? "rotate-180" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </span>
      </div>
      <div
        className={`${
          isActive ? "max-h-[500px]" : "max-h-0"
        } overflow-hidden transition-all duration-700`}
      >
        <p className="font-normal text-base lg:text-xl py-4">{answer}</p>
      </div>
    </div>
  );
};
