import { Link } from "react-router-dom";

import { ButtonArrowTop } from "../button";
import { ServicePageTitle } from "../servicePageTitle";


export const ServicesGallary = ({
  images,
  buttonText = "View more projects",
  link = "/our-works",
}) => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  return (
    <section className="bg-[#0B0B0B] section-padding-services-page overflow-x-hidden">
      <div className="container">
        <div
          className=" service-title-box-padding"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <h3 className="text-[#FFF192] font-medium text-xl flex items-center gap-2 mb-6">
            ‍<span className="bg-[#FFF192] h-[2px] w-8 block"></span> Our Recent
            Works
          </h3>
          <ServicePageTitle>
            <h2 className="service-title-font-size">
              Check out our clients
              <br />
              favorite works
            </h2>
          </ServicePageTitle>
        </div>

        <div
          className=" overflow-x-hidden"
          data-aos="flip-left"
          data-aos-duration="3000"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 ">
            <div className="lg:col-span-2 rounded-lg overflow-hidden xl:h-[400px]   ">
              <img
                className="h-full w-full object-cover duration-700  hover:scale-125"
                src={images[0]}
                alt=""
              />
            </div>
            <div className="rounded-lg overflow-hidden xl:h-[400px]   ">
              <img
                className="h-full w-full object-cover duration-700 hover:scale-125"
                src={images[1]}
                alt=""
              />
            </div>
            <div className="rounded-lg overflow-hidden xl:h-[400px]   ">
              <img
                className="h-full w-full object-cover duration-700 hover:scale-125"
                src={images[2]}
                alt=""
              />
            </div>
            <div className="rounded-lg overflow-hidden xl:h-[400px]   ">
              <img
                className="h-full w-full object-cover duration-700 hover:scale-125"
                src={images[3]}
                alt=""
              />
            </div>
            <div className="md:col-span-2 lg:col-span-1 rounded-lg overflow-hidden xl:h-[400px]  ">
              <img
                className="h-full w-full object-cover duration-700 hover:scale-125"
                src={images[4]}
                alt=""
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
            <div className="lg:col-span-2 xl:h-[400px]    rounded-lg overflow-hidden ">
              <img
                className="h-full w-full object-cover   duration-700 hover:scale-125"
                src={images[5]}
                alt=""
              />
            </div>
            <div className="lg:col-span-2 xl:h-[400px]  rounded-lg overflow-hidden">
              <img
                className="h-full w-full object-cover duration-700 hover:scale-125"
                src={images[6]}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="mt-[72px]">
          <Link to={link} onClick={handleClick}>
            <ButtonArrowTop name={buttonText} />
          </Link>
        </div>
      </div>
    </section>
  );
};
