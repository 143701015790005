import React from 'react'

export const JobInfo = ({ title, description }) => {
    return (
        <div>
            <h4 className='text-bgColor1 text-[30px] font-semibold'>{title}</h4>
            {
                typeof description === 'object' ? (
                    <div className='mt-5 text-[#B0B0B0]'>
                        {
                            description.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))
                        }
                    </div>
                )
                    :
                    <p className='mt-5 text-[#B0B0B0]'>{description}</p>
            }
        </div>
    )
}
