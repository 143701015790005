import React from "react";

// import { AccordionForAbout } from "./AccordionForAbout";
// import OurPassion from "./OurPassion";

import { ButtonArrowTop } from "../button";
import { Link } from "react-router-dom";
import { PiBuildingsBold } from "react-icons/pi";
import { FaLayerGroup } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { FaPeopleArrows } from "react-icons/fa";


const heroBannerImage = {
  backgroundImage: `url("https://i.ibb.co/2vbG2gh/about-Hero.webp")`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
};

export const AboutHeroSection = () => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  const handleScroll = () => {
    const aboutPage = document.getElementById("about-page");
    aboutPage.scrollIntoView({ behavior: "smooth" });
  };
 
  return (
    <div className="overflow-x-hidden">
      <div
        className="h-[85vh] relative flex justify-center  sm:px-0 px-4"
        style={heroBannerImage}
      >
        <div className="container">
          <div className="z-20 relative text-center mt-40">
            <h1 className="text-transparent bg-clip-text bg-gradient-to-b from-white to-gray-400 text-5xl sm:text-6xl md:text-7xl uppercase">
              About Us
            </h1>
            <h2 className="gradientText mt-6 lg:mt-10 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl md:leading-[60px]">
              Leading the Way with Innovative Solutions
            </h2>
          </div>
        </div>

        <div className="box-container flex justify-center flex-col items-center z-20 md:pb-0 pb-10">
          <button
            onClick={handleScroll}
            className="text-transparent   bg-clip-text bg-gradient-to-b from-white to-gray-400 text-center mt-32 flex justify-center mb-4 uppercase md:text-3xl font-semibold "
          >
            Scroll down
          </button>
          <div className="box z-20 relative   ">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black/60 z-10"></div>
      </div>
      <div
        id="about-page"
        className="section-padding-services-page bg-[#606470]"
      >
        <div className="container">
          <div
            className="max-w-3xl mx-auto"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            {/* <ServicePageTitle>
              <h2 className="md:leading-[80px]">
                SM Technology: Leading the Way with Innovative Solutions
              </h2>
            </ServicePageTitle> */}
            <div className="text-white pt-10 text-base sm:text-xl lg:text-2xl xl:text-2xl space-y-8 service-title-box-padding">
              <p>
                At SM Technology, we are a freelance-based digital agency
                specializing in UI/UX design, web development, and business
                administrative services.
              </p>
              <p>
                We have an expert team of 100+ professionals with a deep
                understanding of various industries and technologies. We
                collaborate closely with our clients, understand their unique
                challenges and goals, and tailor our services to meet their
                specific needs.
              </p>
              <div className="mt-10">
                <Link to="/our-works" onClick={handleClick}>
                  <ButtonArrowTop name="Discover Our Work" />
                </Link>
              </div>
            </div>
          </div>

          <div
            className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center bg-gradient-to-b from-white to-gray-400 "
            data-aos="flip-right"
            data-aos-duration="3000"
          >
            <div className="flex justify-center flex-col items-center  mb-[1px] lg:mb-0 lg:mr-[1px] py-6 bg-black">
              <div className="h-16 w-16 rounded-full flex justify-center items-center bg-gradient-to-b from-white to-gray-600">
                <PiBuildingsBold className="text-darkColor text-4xl " />
              </div>
              <h3 className="text-4xl mt-4 font-bold bg-gradient-to-b from-white to-gray-400 bg-clip-text text-transparent ">
                2017
              </h3>
              <p className="text-white text-lg font-medium">Year Founded</p>
            </div>
            <div className="flex justify-center flex-col items-center  mb-[1px] lg:mb-0 lg:mr-[1px] py-6 bg-black">
              <div className="h-16 w-16 rounded-full flex justify-center items-center bg-gradient-to-b from-white to-gray-600">
                <FaLayerGroup className="text-darkColor text-4xl " />
              </div>
              <h3 className="text-4xl mt-4 font-bold bg-gradient-to-b from-white to-gray-400 bg-clip-text text-transparent ">
                20K
              </h3>
              <p className="text-white text-lg font-medium">
                Project Completed
              </p>
            </div>
            <div className="flex justify-center flex-col items-center  mb-[1px] lg:mb-0 lg:mr-[1px] py-6 bg-black">
              <div className="h-16 w-16 rounded-full flex justify-center items-center bg-gradient-to-b from-white to-gray-600">
                <GrMapLocation className="text-darkColor text-4xl " />
              </div>
              <h3 className="text-4xl mt-4 font-bold bg-gradient-to-b from-white to-gray-400 bg-clip-text text-transparent ">
                47+
              </h3>
              <p className="text-white text-lg font-medium">Country's Served</p>
            </div>
            <div className="flex justify-center flex-col items-center  mb-[1px] lg:mb-0 lg:mr-[1px] py-6 bg-black">
              <div className="h-16 w-16 rounded-full flex justify-center items-center bg-gradient-to-b from-white to-gray-600">
                <FaPeopleArrows className="text-darkColor text-4xl " />
              </div>
              <h3 className="text-4xl mt-4 font-bold bg-gradient-to-b from-white to-gray-400 bg-clip-text text-transparent ">
                8+
              </h3>
              <p className="text-white text-lg font-medium">
                Years of Experience
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="bg-[#24362C] py-[120px] text-white">
    //   <div className="container">
    //     <div className="grid grid-cols-1 xl:grid-cols-[7fr_5fr] gap-8 mb-14">
    //       <img className="rounded-3xl" src={Images.About} alt="about us" />
    //       <AccordionForAbout />
    //     </div>
    //     <div className="mt-16">
    //       <OurPassion />
    //     </div>
    //   </div>
    // </div>
  );
};
