import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../button";
import { Link } from "react-router-dom";
import MarqueeItem from "../marqueeItem";
import { useEffect, useRef } from "react";
import headerArrowIcon from "../../assets/Illustration/hero header arrow icon.svg"
import Toggle_switch_Componant_webp from "../../assets/Photo/Toggle switch Componant.webp";

export const Banner = () => {
  const videoRef = useRef(null);

  // Function to save the current playback position
  const savePlaybackPosition = () => {
    if (videoRef.current) {
      sessionStorage.setItem(
        "videoPlaybackPosition",
        videoRef.current.currentTime
      );
    }
  };

  // Function to retrieve and set the playback position when the page loads
  const setPlaybackPosition = () => {
    const playbackPosition = sessionStorage.getItem("videoPlaybackPosition");
    if (playbackPosition && videoRef.current) {
      videoRef.current.currentTime = parseFloat(playbackPosition);
    }
  };

  // Function to handle video playback
  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play video:", error);
      });
    }
  };

  // Effect to set playback position and start video playback when the component mounts
  useEffect(() => {
    setPlaybackPosition();
    handleVideoPlay();
  }, []);

  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  const spinnerStyle = {
    animation: "spin 10000ms infinite",
    width: "150px",
    height: "150px",
  };

  return (
    <div className="overflow-hidden hero-banner-height relative flex flex-col justify-between">
      <div
        className="bg-cover bg-no-repeat text-white overflow-hidden z-50 relative" // Adjusted the min height and removed the calculation
        // style={{ backgroundImage: `url('${Images.heroBg}')` }}
      >
        <div className="container mx-auto relative">
          {/* Centering the container */}
          <div className="text-center pt-24 lg:pt-6 pb-10 flex flex-col justify-center items-center">
            <div className="flex flex-col items-center gap-5">
              {/* Centering text and arrow */}
              <div className="flex items-center gap-2 ">
                {/* Centering text and arrow */}
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl 2xl:text-8xl  font-semibold">
                  We Support
                </h1>
                <img
                  className="mt-2 sm:h-12 md:h-20 sm:mt-5 h-8"
                  src={headerArrowIcon}
                  alt="hero header arrow icon"
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-3 sm:mt-4">
              {/* Centering the grid */}
              <div className="grid grid-cols-12">
                <h1 className="col-span-full text-[60px] sm:text-[100px] md:text-[140px] xl:text-[180px] font-bold -mt-3 sm:-mt-6 max-w-fit animate-typing2 overflow-hidden whitespace-nowrap">
                  <span className="col-span-1">gr</span>
                  <img
                    className="inline-block md:h-36 sm:h-20 h-10"
                    src={Toggle_switch_Componant_webp}
                    alt=""
                  />
                  <span>wth</span>
                </h1>
              </div>
            </div>

            <div className="flex items-center sm:gap-3 gap-1 mt-3 sm:mt-5 ">
              {/* Centering content */}
              <img
                src={Images.heroHeaderIcon}
                alt="hero header icon"
                className="sm:mr-2 mr-0 sm:h-auto h-10 mb-1"
              />
              <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold mb-5">
                of your business
              </h1>
            </div>
            <p className="mt-4 sm:mt-6 text-base sm:text-xl max-w-3xl mx-auto">
              {/* Adjusted text size */}
              SM Technology is a global UI/UX Design, Web Development, and
              Business Administrative Service Agency that helps Brands empower
              their Business GROWTH.
            </p>
            <div className="flex flex-col items-center justify-center gap-3 mt-3 sm:mt-5">
              {/* Centering buttons */}
              <div className="md:flex-row items-center justify-center gap-3 mt-3 sm:mt-5 flex flex-col">
                {/* Centering buttons */}
                <Link to="/contact" onClick={handleClick}>
                  <ButtonArrowTop name="Contact Us" px="px-6 sm:px-10" />
                </Link>
                <Link
                  className="outlined-btn"
                  to="/about"
                  onClick={handleClick}
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          {/* hero section badge */}
          <div className="md:absolute md:bottom-16 md:right-0 lg:block hidden">
            <img style={spinnerStyle} src={Images.heroBadge} alt="badge" />
          </div>
        </div>
      </div>
      {/* marquee */}
      <div className=" pb-3 mt-auto z-50 relative">
        <div className="bg-white py-2 md:py-5 relative overflow-hidden -rotate-1">
          <div className="flex flex-row sm:flex-row gap-3 sm:gap-8 animate-marquee whitespace-nowrap">
            {/* Centering and adjusting layout */}
            {/* Marquee items go here */}
            <MarqueeItem title="Web Design" iconColor="#FFDE00" />
            <MarqueeItem title="Web Development" iconColor="#15C89E" />
            <MarqueeItem title="UI/UX Design" iconColor="#FFDE00" />
            <MarqueeItem title="Data Entry" iconColor="#15C89E" />
            <MarqueeItem title="B2B Lead Generation" iconColor="#FFDE00" />
            <MarqueeItem title="Email Marketing" iconColor="#15C89E" />
            <MarqueeItem title="Webflow" iconColor="#FFDE00" />
            <MarqueeItem title="Wordpress" iconColor="#15C89E" />
          </div>
        </div>
      </div>
      <div className="z-20 absolute top-0 left-0 w-full h-full bg-black/60"></div>
      <div className="absolute top-0 left-0 w-full h-full z-10">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          preload="auto"
          playsInline // Necessary for some mobile devices
          onEnded={savePlaybackPosition} // Save playback position when video ends
        >
          <source src="/work-video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

// {
//   /* <div className="bg-bgColor2 pb-3">
//         <div className="bg-white py-3 relative overflow-hidden -rotate-1">
//           <div className="flex gap-8 animate-marquee whitespace-nowrap">
//             <MarqueeItem title="Web Design" iconColor="#FFDE00" />
//             <MarqueeItem
//               title="Web Development"
//               iconColor="#15C89E"
//             />
//             <MarqueeItem title="UI/UX Design" iconColor="#FFDE00" />
//             <MarqueeItem title="Data Entry" iconColor="#15C89E" />
//             <MarqueeItem
//               title="B2B Lead Generation"
//               iconColor="#FFDE00"
//             />
//             <MarqueeItem
//               title="Email Marketing"
//               iconColor="#15C89E"
//             />
//             <MarqueeItem
//               title="Transcription"
//               iconColor="#FFDE00"
//             />
//             <MarqueeItem
//               title="Skip Tracing"
//               iconColor="#15C89E"
//             />
//             <MarqueeItem title="Webflow" iconColor="#FFDE00" />
//             <MarqueeItem title="Wordpress" iconColor="#15C89E" />
//           </div>
//         </div>
//       </div> */
// }
