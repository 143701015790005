import { ButtonArrowTop } from "../button";

export const CommonHeading = ({
  heading,
  subHeading,
  btnContent,
  description,
  handleScrollJobSection
}) => {
  return (
    <div className="">
      <div className="xl:text-[88px] text-4xl md:text-6xl font-black">
      <h2>{heading}</h2>
      <h2 className="flex items-center gap-5">
        <span className="w-24 h-4 bg-[#FFE533]  sm:inline-block hidden"></span>
        <span className="text-[#FFE533]">{subHeading}</span>
      </h2>
      </div>
      <div className="md:max-w-3xl py-10">
        <p className="md:text-2xl text-lg font-medium">{description}</p>
      </div>
      {btnContent && <ButtonArrowTop handleScrollJobSection={handleScrollJobSection} name={btnContent} />}
    </div>
  );
};
