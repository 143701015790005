import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import {  useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const WahtPeopleSay = () => {
  const {
    isLoading,
    error,
    data: feedbacks,
  } = useQuery({
    queryKey: ["feedback"],
    queryFn: () =>
      axios(
        "https://raw.githubusercontent.com/Shahed007/smt-clients-feedback-data/main/clientsFeedback.json"
      ).then((response) => response.data),
  });

  const swiperRef = useRef(null);

  const handlePrevButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (isLoading) return <h1>Loading..</h1>;
  if (error) return <h1>{error}</h1>;

  return (
    <section className="text-white lg:h-[80vh] py-[100px] flex items-center bg-[#1a1a1a] relative overflow-x-hidden">
      <div className="container grid grid-cols-1 gap-10 md:grid-cols-2  ">
        <div className="flex-1">
          {/* <h4 className="uppercase font-semibold text-secondary text-2xl">
            TESTIMONIALS
          </h4> */}
          <h1 className="service-title-font-size font-semibold mt-5">
            What people <span className="font-normal">Say?</span>
          </h1>
        </div>
        <div className="flex-1 ">
          <Swiper
            navigation={{ prevEl: null, nextEl: null }}
            loop={true}
            modules={[Navigation]}
            className="overflow-visible swiper-container relative"
            ref={swiperRef}
          >
            {feedbacks?.map(({ comments, id, image, name, country }) => (
              <SwiperSlide key={id} className="overflow-visible z-10">
                <div className=" relative w-full h-full">
                  <div className="relative lg:px-10 flex items-center  w-full h-[200px] border-b">
                    <div className="absolute top-10 -left-10 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="256.721"
                        height="208.227"
                        viewBox="0 0 256.721 208.227"
                        className="h-[130px]"
                      >
                        <path
                          data-name="Path"
                          d="M-23.723-530.169v97.327H-121.05v-68.7q0-40.076,13.359-73.472T-62.845-639.9l36.259,28.625Q-63.8-570.244-68.57-530.169Zm158.395,0v97.327H37.345v-68.7q0-40.076,13.359-73.472T95.55-639.9l36.259,28.625Q94.6-570.244,89.825-530.169Z"
                          transform="translate(121.55 640.568)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                          opacity="0.322"
                        ></path>
                      </svg>
                    </div>
                    <p className="text-xl leading-[30px] ">{comments}</p>
                  </div>
                  <div className="mt-8 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <img
                        className="h-16 w-16 rounded-full object-cover"
                        src={image}
                        alt={name}
                      />
                      <div>
                        <h4 className="text-xl font-semibold">{name}</h4>
                        <h6 className="text-base">{country}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="flex items-center gap-2 absolute bottom-5 right-0 z-20">
              <button
                className="h-10 w-10 bg-black rounded-full flex justify-center items-center text-secondary"
                onClick={handlePrevButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
              </button>
              <button
                className="h-10 w-10 bg-black rounded-full flex justify-center items-center text-secondary"
                onClick={handleNextButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
