import { Images } from "./images";

// Team for Hr
export const TeamHR = [
  {
    id: 1,
    name: "Shazzid Yousuf",
    designation: "HR Manager",
    image: "https://i.ibb.co/JcV8cBK/279906628-10221983250755273-3893922738620695689-n.jpg ",
  },
  {
    id: 2,
    name: "Johurul Islam",
    designation: "Finance Manager",
    image: "https://i.ibb.co/sPHgQpd/johirul.png ",
  },
  {
    id: 3,
    name: "Abdul Hadid Manna",
    designation: "IT Specialist",
    image: "https://i.ibb.co/D5y9Kx9/IMG-20240208-WA0015-removebg-preview.png ",
  },
  // {
  //   id: 4,
  //   name: "Raton Islam",
  //   designation: "IT Specialist",
  //   image: " ",
  // },
];

export const TeamBpu = [
  {
    id: 1,
    name: "Jamrul Islam",
    designation: "Sr. Business Development Executive",
    image: "https://i.ibb.co/cNFmjsp/new.jpg",
  },
  {
    id: 2,
    name: "Md. Antor Hossain",
    designation: "Sr. Business Development Executive",
    image: "https://i.ibb.co/nCpRrTB/IMG-20230906-WA0006.jpg",
  },
  {
    id: 3,
    name: "Toukir Ahammed",
    designation: "Business Development Executive",
    image: "https://i.ibb.co/kgYJ3RP/Picture.png",
  },
  // {
  //   id: 4,
  //   name: "Md. Zidan Kabir",
  //   designation: "Business Development Executive",
  //   image: "https://i.ibb.co/grxZQ6N/jpeg-optimizer-Ayoun.jpg",
  // },
];

// Management of SMT
export const MangeMent = [
  {
    id: 1,
    image: "https://i.ibb.co/C2yh0V3/ChairMan.jpg",
    name: "Sabina Akther",
    degisnation: "Chairman, bdCalling Group",
    linkedIn: "https://linkedin.com",
    facebook: "https://facebook.com",
  },
  {
    id: 2,
    image: "https://i.ibb.co/pd73p4Z/Ceo.jpg",
    name: "Muhammad Monir Hossain",
    degisnation: "CEO, bdCalling Group",
    linkedIn: "https://linkedin.com",
    facebook: "https://facebook.com",
  },
];

// SmtDefenders
export const SmtDefenders = [
  {
    id: 1,
    name: "Md. Rayhan Ali",
    designation: "Operations Manager",
    image: "https://i.ibb.co/7WJ7tqw/image-2024-03-17-T17-11-55-626-Z.png",
  },
  {
    id: 2,
    name: "Md. Forhad Hossain",
    designation: "Team Leader",
    image: Images.ForhadDefenders,
  },
  // {
  //   id: 3,
  //   name: "Md. Shahnewaj",
  //   designation: "Sr. BS Executive",
  //   image: " ",
  // },
  // {
  //   id: 4,
  //   name: "Maniruzzaman Munna",
  //   designation: "Operations Manager",
  //   image: " ",
  // },
  {
    id: 5,
    name: "Md. Junaed Mia",
    designation: "BS Executive",
    image: "https://i.ibb.co/y8LxDGq/junaed-Defenders.jpg ",
  },
  // {
  //   id: 6,
  //   name: "Sohena Akter",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  // {
  //   id: 7,
  //   name: "Mst. Tanna Akther",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  {
    id: 8,
    name: "Md Asadullah",
    designation: "BS Executive",
    image: Images.AsadullahDefenders,
  },
  {
    id: 9,
    name: "Md. Sahab Uddin",
    designation: "BS Executive",
    image: "https://i.ibb.co/Sn5cqZ6/Md-Sahabuddin.png",
  },
  // {
  //   id: 10,
  //   name: "Suraiya Islam Nuren",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  {
    id: 11,
    name: "Roksana Akter",
    designation: "BS Executive",
    image: "https://i.ibb.co/CBJfK7W/Imported-Photo-1710395746390-3.jpg",
  },
  {
    id: 12,
    name: "Md Abu Sabbir",
    designation: "BS Executive",
    image: "https://i.ibb.co/KNhdsq7/MD-Abu-Sabbir.jpg",
  },
  {
    id: 13,
    name: "Mohammad Zahid Hossain",
    designation: "Sr. BS Executive",
    image: "https://i.ibb.co/LhfLZVK/zahid-image-removebg-preview.png ",
  },
  // {
  //   id: 14,
  //   name: "Nazmul Hossain Nahid",
  //   designation: "BS Executive",
  //   image: " ",
  // },
];

// SMT Dominators
export const SmtDominators = [
  {
    id: 1,
    name: "Md. Badol",
    designation: "Operations Manager",
    image: "https://i.ibb.co/yQkYbyx/Imported-Photo-1709790893349.jpg ",
  },
    {
    id: 40,
    name: "Maniruzzaman Munna",
    designation: "Operations Manager",
    image: " https://i.ibb.co/Sm4j2MZ/Munna.jpg",
  },
  {
    id: 2,
    name: "Roubite Ahamed",
    designation: "Team Leader",
    image: "https://i.ibb.co/xfKD2hg/rubaiyet-Dominators.jpg",
  },
  {
    id: 3,
    name: "Rona Akter",
    designation: "Team Leader",
    image: "https://i.ibb.co/dJpNdTH/ronascren.png ",
  },
  {
    id: 4,
    name: "Asma Akter",
    designation: "Sr. BS Executive",
    image: "https://i.ibb.co/9ymwx8Q/Asma.jpg ",
  },
  {
    id: 5,
    name: "Md. Riyad Hossain",
    designation: "Sr. BS Executive",
    image: "https://i.ibb.co/DzgybfH/1644849856281.jpg ",
  },
  {
    id: 6,
    name: "Shahida Akter",
    designation: "Sr. BS Executive",
    image: "https://i.ibb.co/kQccD22/processed-4671bb93-cb66-4ac0-af4e-b3b9684eff1e-IXjh-Fw3-C.jpg",
  },
  // {
  //   id: 7,
  //   name: "Rokeya Begum Sweety",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  {
    id: 8,
    name: "Md. Raihan Prodhan",
    designation: "BS Executive",
    image: "https://i.ibb.co/rfvsdbn/Blue-Church-Conference-Instagram-Post-2.png",
  },
  // {
  //   id: 9,
  //   name: "Shumona Akter RIma",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  {
    id: 10,
    name: "Rafiqul Islam",
    designation: "BS Executive",
    image: "https://i.ibb.co/cwt2qrD/1699325203769-1.jpg",
  },
  {
    id: 11,
    name: "Md Sowrov",
    designation: "BS Executive",
    image: "https://i.ibb.co/mGW0GBN/1710396179554.jpg ",
  },
  {
    id: 12,
    name: "Md. Harun Miah",
    designation: "BS Executive",
    image: "https://i.ibb.co/pPLxgGr/420538574-910707434169254-5384537569697280209-n.jpg",
  },
  {
    id: 13,
    name: "Munne Akter",
    designation: "BS Executive",
    image: "https://i.ibb.co/VTpXsFb/IMG-2608.jpg",
  },
  {
    id: 14,
    name: "Md. Imam Hasan",
    designation: "Operations Manager",
    image: "https://i.ibb.co/JnDhYJF/1710395439304.jpg ",
  },
  // {
  //   id: 15,
  //   name: "Md. Ashraful Khan",
  //   designation: "BS Executive",
  //   image: " ",
  // },
  // {
  //   id: 16,
  //   name: "Md. Abu Sayed Munna",
  //   designation: "Sr. BS Executive",
  //   image: " ",
  // },
];

// SMT Sales
export const SmtSeals = [
  {
    id: 15,
    name: "Suzan Miah",
    designation: "Sr. Key Account Manager",
    image: "https://i.ibb.co/LvMM5SC/Suzan-Miah.png ",
  },
  {
    id: 16,
    name: "Sohan Islam",
    designation: "Key Account Manager",
    image: "https://i.ibb.co/YLsYtbR/1710405267441.jpg",
  },
  {
    id: 17,
    name: "Md. Nure Naim Siddique",
    designation: "Key Account Manager",
    image: "https://i.ibb.co/L98dWqP/IMG-20240314-233425.jpg",
  },
  {
    id: 18,
    name: "Yeahia Khan Emu",
    designation: "Sr. Key Account Manager",
    image: "https://i.ibb.co/zZ8Jmgb/78-B0-FB1-E-EA14-48-EC-9476-1-E5-C5-CA002-E7.jpg ",
  },
  {
    id: 19,
    name: "Shahjalal Ahamed",
    designation: "Key Account Manager",
    image: "https://i.ibb.co/zhMMB57/IMG-20221227-042010.jpg ",
  },
  {
    id: 20,
    name: "Md. Abdur Razzak",
    designation: "Key Account Manager",
    image: "https://i.ibb.co/ZRsFR2h/280121184-978788979440225-1530249198715248365-n.jpg ",
  },
  {
    id: 21,
    name: "Md. Alauddin Arif",
    designation: "Sales Executive",
    image:"https://i.ibb.co/xYkHD7X/1710546009342.jpg ",
  },
  {
    id: 22,
    name: "Abdullah Al Akhir",
    designation: "Sales Executive",
    image: "https://i.ibb.co/4JvYmGN/Skype-Picture-2024-03-15-T10-52-46-427-Z.jpg",
  },
  {
    id: 23,
    name: "Md Firoz Hosen",
    designation: "Sales Executive",
    image: "https://i.ibb.co/gPhqYR2/Firoj-vai.jpg ",
  },
  {
    id: 24,
    name: "Md. Ferdous Alam",
    designation: "Sales Executive",
    image: "https://i.ibb.co/PFmvNgW/jpeg-optimizer-fersous-Sales.jpg",
  },
  {
    id: 25,
    name: "Md Tahidul Islam",
    designation: "Sales Executive",
    image: "https://i.ibb.co/vBzFm3c/profile-pic.png",
  },
  {
    id: 26,
    name: "Arif Mahamud",
    designation: "Sales Executive",
    image: Images.ArifSales,
  },
  {
    id: 27,
    name: "Md. Najrul Islam",
    designation: "Sales Executive",
    image: "https://i.ibb.co/M6MDZYZ/nazrul.jpg",
  },
  {
    id: 28,
    name: "Md. Dalim Islam",
    designation: "Sales Executive",
    image: "https://i.ibb.co/LPvZdhj/jpeg-optimizer-Md-Dalim-Islam.jpg ",
  },
  {
    id: 29,
    name: "Fahim Ahmed",
    designation: "Sales Executive",
    image: " https://i.ibb.co/q00WLjs/jpeg-optimizer-fahim-Sales.jpg",
  },
  {
    id: 30,
    name: "Md. Sakawat Hossain",
    designation: "Sales Executive",
    image: "https://i.ibb.co/pr8mL56/shakawat.png",
  },
  {
    id: 31,
    name: "Mahamudul Hassan",
    designation: "Sales Executive",
    image: "https://i.ibb.co/rw3n4J6/IMG-20240311-231301.jpg ",
  },
  {
    id: 32,
    name: "Rukunul Karim",
    designation: "Sales Executive",
    image: "https://i.ibb.co/gJfhfmg/Imported-Photo-1710501227415.jpg ",
  },
  {
    id: 34,
    name: "Md. Nahid Hasan",
    designation: "Sales Executive",
    image: "https://i.ibb.co/3k7TMff/Screenshot-3.png ",
  },
];

// SMT Warriors

export const SmtWarriors = [
  {
    id: 1,
    image: "https://i.ibb.co/wC6f6fR/image-2024-03-14-T07-41-29-090-Z-removebg-preview-2.png",
    name: "Sohan Ahmed",
    designation: "Operations Manager",
  },
  {
    id: 3,
    image: "https://i.ibb.co/sW1yWhC/jpeg-optimizer-IMG-0420-2.jpg",
    name: "Yeasmin Akter",
    designation: "Operation Manager",
  },
  {
    id: 2,
    image: " https://i.ibb.co/K5QnrWN/Imported-Photo-1709633508436.jpg",
    name: "Md. Shofiqul Islam",
    designation: "Team Leader",
  },

  {
    id: 4,
    image: "https://i.ibb.co/zNP9FgX/processed-4bda4fea-6c92-439f-81e2-dc151b4afa6a-0-Hf-T8-SNG.jpg",
    name: "Kumkum Khatun",
    designation: "Sr. BS Executive",
  },
  {
    id: 5,
    image: "https://i.ibb.co/MyvdSkh/m.png",
    name: "Mohosina Akter",
    designation: "Sr. BS Executive",
  },
  // {
  //   id: 6,
  //   image: " ",
  //   name: "Mariya Aibte Monira",
  //   designation: "BS Executive",
  // },
  {
    id: 7,
    image: Images.JhumaWarriors,
    name: "Mst. Jhuma Aktar",
    designation: "BS Executive",
  },
  // {
  //   id: 8,
  //   image: " ",
  //   name: "Mousumi",
  //   designation: "BS Executive",
  // },
  // {
  //   id: 9,
  //   image: " ",
  //   name: "Imam Hossain",
  //   designation: "BS Executive",
  // },
  {
    id: 10,
    image: "https://i.ibb.co/yQnNGxJ/FB-IMG-1706542061728.jpg",
    name: "Md. Al Sadir",
    designation: "BS Executive",
  },
  // {
  //   id: 11,
  //   image: " ",
  //   name: "Md. Imtiaz Khan",
  //   designation: "BS Executive",
  // },
  // {
  //   id: 12,
  //   image: " ",
  //   name: "Ayesha Siddika",
  //   designation: "BS Executive",
  // },
  // {
  //   id: 13,
  //   image: " ",
  //   name: "Farjana Manzoor",
  //   designation: "BS Executive",
  // },
  {
    id: 14,
    image: "https://i.ibb.co/61prdPX/Rifat-Warriors.png",
    name: "Md. Rifat_BS",
    designation: "CRM Executive",
  },
  {
    id: 15,
    image: " https://i.ibb.co/mvfhL5X/F8-A70034-1-E5-C-4-B3-D-A9-FC-004-B36-C51-CB9.jpg",
    name: "Mohon Hossain",
    designation: "CRM Executive",
  },
];

// SMT UI UX
export const SmtUiUx = [
  {
    id: 1,
    image: "https://i.ibb.co/HLS20FP/photo.jpg ",
    name: "Rifat Rahaman",
    designation: "Lead UI/UX Designer",
  },

  {
    id: 2,
    image: "https://i.ibb.co/L0667bf/jpeg-optimizer-1.png ",
    name: "Md. Asraful Islam",
    designation: "UI/UX Designer",
  },
  {
    id: 3,
    image: "https://i.ibb.co/zbDFrNP/jannat.jpg",
    name: "Jannatul Ferdaus",
    designation: "UI/UX Designer",
  },
  {
    id: 4,
    image: "https://i.ibb.co/8jkK6hZ/siam.jpg",
    name: "Shiam Reza",
    designation: "UI/UX Designer",
  },
];

// SMT Web Dev
export const SmtWebDev = [
   {
    id: 1,
    image: "https://i.ibb.co/Wf5H6BT/Imported-Photo-1717571803042.jpg",
    name: "Jhumur Khatun",
    designation: "Operation Manager",
  },
  {
    id: 2,
    image: "https://i.ibb.co/bNKK4Nt/maruf3.jpg",
    name: "Abdullah Al Maruf",
    designation: "Lead Web Developer",
  },
 
  {
    id: 3,
    image: "https://i.ibb.co/1T78z68/billal4.jpg",
    name: "Billal Hossen",
    designation: "Lead Web Developer",
  },
  {
    id: 4,
    image: "https://i.ibb.co/jvCYFKn/logo3.jpg",
    name: "Ashadujjaman Noyon",
    designation: "Lead Web Developer",
  },
  {
    id: 16,
    image: "https://i.ibb.co/HHhdSrN/rahat.jpg",
    name: "Md. Rahat",
    designation: "Web Developer",
  },
  // {
  //   id: 5,
  //   image: "https://i.ibb.co/ZcKNpDY/jpeg-optimizer-Ali.jpg",
  //   name: "Ali Ashraf",
  //   designation: "Web Developer",
  // },
  {
    id: 6,
    image: "https://i.ibb.co/3M0BT1Y/tufael.jpg",
    name: "Tufael Ahmed Zuarder",
    designation: "Web Developer",
  },
  {
    id: 7,
    image: "https://i.ibb.co/VJpgBpt/Imran.png",
    name: "Md. Shahe Imran",
    designation: "Web Developer",
  },
  {
    id: 8,
    image: "https://i.ibb.co/zmSpPB1/tanvir.jpg",
    name: "Tanvir Ahmed",
    designation: "Web Developer",
  },
  {
    id: 9,
    image: "https://i.ibb.co/KyY2CT5/jpeg-optimizer-Riyad.jpg",
    name: "Riyad Hossain",
    designation: "Web Developer",
  },
  {
    id: 10,
    image: "https://i.ibb.co/MPZqpXN/jpeg-optimizer-MD-Shahed.png",
    name: "Md. Shahed",
    designation: "Web Developer",
  },
  {
    id: 11,
    image: "https://i.ibb.co/sqk1KWr/jpeg-optimizer-zalish.jpg",
    name: "Md. Zalish Mahmud",
    designation: "Web Developer",
  },
  {
    id: 12,
    image: "https://i.ibb.co/bQrnx46/parvez.jpg",
    name: "Parvej Miah",
    designation: "Web Developer",
  },
  {
    id: 13,
    image: "https://i.ibb.co/L5RjXsx/jpeg-optimizer-anarul.jpg",
    name: "Md. Anarul Islam",
    designation: "Web Developer",
  },
  {
    id: 14,
    image: "https://i.ibb.co/4Kv3Gpx/jpeg-optimizer-Afif.png",
    name: "Afif Radoan",
    designation: "Web Developer",
  },
  {
    id: 15,
    image: "https://i.ibb.co/vXf1wHS/jpeg-optimizer-Riyad.jpg",
    name: "Md. Riadul Bhuiyan Riad",
    designation: "Web Developer",
  },
  {
    id: 16,
    image: "https://i.ibb.co/F3gbfBg/Ikramul-islam-copy.jpg",
    name: "Ikramul Islam",
    designation: "Web Developer",
  },
];

