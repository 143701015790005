import { Images } from "./images";

// Lead Generation Specality content
export const LeadGenSpecality = {
  title: ["Let us help you elevate", "your digital presence"],
  cardContent: [
    {
      id: 1,
      title: "Targeted Outreach",
      description:
        "Making emails that feel personal to get more customers, and using numbers to see how well things are working.",
      image: "https://i.ibb.co/nC9m342/leadcard1-65e04f18074a0.webp",
    },

    {
      id: 2,
      title: "Compelling Content",
      description:
        "Creating interesting content to attract potential customers and get them interested in what you have to offer.",
      image: "https://i.ibb.co/3cbT40r/lead-Card2-c992c6139de316be2238.webp",
    },

    {
      id: 3,
      title: "Automated Processes",
      description:
        "Assigning scores to potential customers based on their actions, helping focus on the most promising leads for better results..",
      image: "https://i.ibb.co/XZMZ0Xr/lead-Card3-9e2d0bd1f02ab1bcfa82.webp",
    },

    {
      id: 4,
      title: "Lead Scoring",
      description:
        "Web design is the art of crafting visually appealing and user-friendly websites for optimal online interaction.",
      image: "https://i.ibb.co/LSJxHNw/lead-Card4-6f99abf134233221acdd.webp",
    },
  ],
};

// Lead Geneartion Services
export const leadGenerationServices = {
  title: "Lead Generation Services",
  services: [
    {
      title: "Targeted Lead Generation",
      image: "https://i.ibb.co/njHC9hC/Targeted-Lead-Generation.webp",
      description: "Reach Your Ideal Customers with Precision and Efficiency",
    },
    {
      title: "Content Marketing for Leads",
      image:
        "https://i.ibb.co/XJVcmXr/content-marketing-for-leads93ad7b42534789572621-65e0521f1e30f.webp",
      description:
        "Engage and Convert Audiences with Compelling Content Strategies",
    },
    {
      title: "Automated Lead Nurturing",
      image:
        "https://i.ibb.co/PMsrFRn/automated-lead-nurturing-65e053704419c.webp",
      description:
        "Effortlessly Cultivate Relationships and Drive Conversions with Automated Workflows",
    },
    {
      title: "Performance-driven Lead Campaigns",
      image:
        "https://i.ibb.co/QrYW1TH/performance-driven-lead-campaigns-65e053e96e915.webp",
      description:
        "Maximize ROI with Data-Driven Strategies for Lead Acquisition and Conversion",
    },
  ],
  button_text: "Contact Us Now",
  link: "/contact",
};

// About Leade generation
export const aboutLeadGeneration = {
  title: "Build Meaningful Connections, Not Just Leads.",
  description:
    "Focus on creating lasting relationships with potential customers, rather than just generating leads. Our personalized strategies ensure a memorable experience that resonates.",
  buttonText: "Start Building Connections",
};

// Lead Generation deliverables
export const leadGenerationDeliverables = {
  title: "Deliverables ",
  categoryTitle1: "Lead Generation:",
  category1: [
    "Target Audience Identification",
    "Content Strategy Development",
    "Lead Magnet Creation",
    "Email Campaign Setup",
    "Conversion Rate Optimization",
  ],
  category2: [
    "Social Media Advertising",
    "Search Engine Optimization (SEO)",
    "PPC Advertising Management",
    "Lead Scoring Implementation",
    "Analytics and Reporting",
  ],
};

// Working proccess Lead Generation
export const leadGenerationWorkingProccess = {
  title: ["Strategic Working Process", "for Business Success"],
  process: [
    "Research",
    "Strategy",
    "Content",
    "Campaigns",
    "Optimization",
    "Analysis",
    "Refinement",
  ],
};

// Lead Generation Gallery
export const leadGenerationGallery = {
  buttonText: "View more projects",
  link: "/our-works",
  images: [
    "https://i.ibb.co/KVbG6qk/lead5-2853f96a53dbcc7042ea.jpg",
    "https://i.ibb.co/ZMPSmXd/lead2-431a1ec880a7573c19ed.webp",
    "https://i.ibb.co/rwMvmzz/lead3-0c503fb75937e1b2913c.webp",
    "https://i.ibb.co/Xpt9b9M/lead4-0d21876901ae5456bb6b.png",
    "https://i.ibb.co/bWKqWvT/lead1-b0a836a2f17c3d2d97a3.webp",
    "https://i.ibb.co/9GpyKqt/lead6-07d701818439619ada24.png",
    "https://i.ibb.co/6n9VxFz/lead7.jpg",
  ],
};

// Why Chuse us sevices text with accordiono
export const leadGenerationWhayChuseUs = {
  services: [
    {
      id: 1,
      title: `High-Quality Work and On-time Delivery`,
      image: Images.dimond1,
    },
    {
      id: 2,
      title: `Committed to providing the highest quality service.`,
      image: Images.badge1,
    },
    {
      id: 3,
      title: `Quick Response with 24/7 Support`,
      image: Images.tecnical1,
    },
    {
      id: 4,
      title: `30 Days of Free Support After the Delivery`,
      image: Images.productivaty1,
    },
  ],

  question: [
    {
      title: "What are Lead Generation services?",
      answer:
        "Lead generation services are businesses or tools that help companies find and attract potential customers for their products or services. They use various strategies such as online marketing, advertising, and data collection to create a pool of potential leads that a business can then convert into customers.",
    },
    {
      title: "How do you find leads for my business?",
      answer:
        "We use different methods to find potential customers, like online searches, social media, and other tools. We focus on finding people who are likely to be interested in your products or services",
    },
    {
      title: "Is my business information kept private?",
      answer:
        "Yes, we take your privacy seriously. Your business information is safe with us, and we follow strict rules to keep it secure.",
    },
    {
      title: "How long does it take to start getting leads?",
      answer:
        "The time can vary, but we work efficiently to start bringing in leads as soon as possible. We'll keep you informed about the progress.",
    },
    {
      title: "Can I choose the type of leads I want? ",
      answer:
        "Absolutely! We'll work with you to understand your target audience, and we'll focus on finding leads that match your specific criteria.",
    },

    {
      title: "What makes your Lead Generation service different?",
      answer:
        "We use a personalized approach to understand your business needs. Our goal is to provide you with high-quality leads that are more likely to convert into customers.",
    },
    {
      title: "How much does your Lead Generation service cost?",
      answer:
        "We offer different plans to suit your budget. The cost depends on the services you choose. We'll provide transparent pricing, and you can choose the plan that fits your needs.",
    },
  ],
};
