import { Link } from "react-router-dom";
// import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../button";
import { ServiceCard } from "../serviceCard";



export const Services = ({
  title,
  services,
  button_text = "Contact Us Now",
  link = "/contact",
}) => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };

  return (
    <section className="section-padding-services-page bg-white text-darkColor overflow-x-hidden ">
      <div className="container">
        <div
          className="flex justify-center items-center service-title-box-padding"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <h2 className="text-black  text-center  font-bold service-title-font-size">
            {title}
          </h2>
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-12 text-white"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          {services?.map((service, idx) => (
            <ServiceCard key={idx} service={service}></ServiceCard>
          ))}
        </div>
        <div className="flex justify-center items-center">
          <Link to={link} onClick={handleClick} className="mt-[100px] block">
            <ButtonArrowTop name={button_text}></ButtonArrowTop>
          </Link>
        </div>
      </div>
    </section>
  );
};
