const MarqueeItem = ({ title, iconColor }) => {
  return (
    <div className="flex gap-8 items-center min-w-fit">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
      >
        <path
          d="M19.4377 0.230968L25.577 14.3743L40.1035 19.5423L25.9601 25.6816L20.7922 40.208L14.6529 26.0647L0.126454 20.8967L14.2698 14.7574L19.4377 0.230968Z"
          fill={iconColor}
        />
      </svg>
      <h2 className="text-darkColor md:text-5xl text-3xl font-bold">{title}</h2>
    </div>
  );
};

export default MarqueeItem;
