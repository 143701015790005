import { Link, NavLink } from "react-router-dom";
import { Images } from "../../utils/images";
import { useState } from "react";

 const MobileNavbar = ({toogleNave, setToggleNav }) => {
    const [toggleAbout, setToggleAbout] = useState(false);
    const [toggleService, setToggleService] = useState(false);
    const handleToggleAbout = () => {
        setToggleAbout(!toggleAbout);
        setToggleService(false);
      };
    
      const handleToggleService = () => {
        setToggleService(!toggleService);
        setToggleAbout(false);
      };
    return(
        <div
        className={` 
         duration-700 transition-all ease xl:hidden block fixed top-0  right-0  shadow-md  ${
           toogleNave
             ? "z-[100] h-screen w-full visible"
             : "-translate-y-[9999px] h-0 w-0 translate-x-[9999px] "
         } overflow-hidden`}
      >
        <div className=" h-full">
          <div
            className={`h-full w-full ${
              toogleNave ? "opacity-100" : "opacity-0"
            }   p-5 bg-darkColor text-white transition-all  duration-1000 `}
          >
            <div className=" justify-between items-center flex ">
              <Link to="/">
                <img className="sm:h-auto h-10" src={Images.Logo} alt="Logo" />
              </Link>
              <button
                onClick={() => setToggleNav(!toogleNave)}
                className="sm:px-3 p-2 sm:py-2 transition-all duration-1000  shadow rounded-md gap-1 border active:scale-95  justify-center items-center xl:hidden flex z-50 relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
                <span className="text-base font-medium sm:block hidden">Close</span>
              </button>
            </div>
            <div className="px-6 pt-10">
              <ul className="space-y-6 text-xl sm:text-3xl font-semibold  ">
                <li>
                  <NavLink
                  onClick={() => {
                    window.scroll({
                      top: 0,
                    });
                    setToggleNav(!toogleNave);
                  }}
                  to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={handleToggleAbout}
                    className="flex items-center gap-3"
                  >
                    About
                    <span className="flex justify-center items-center h-6 w-6 bg-white rounded-full text-darkColor relative group-hover:rotate-180 duration-300 transition-transform">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`w-4 h-4  transition-transform duration-500 ${
                          toggleAbout ? "rotate-180" : ""
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={` overflow-hidden transition-all duration-700 ease   ${
                      toggleAbout ? "max-h-screen" : "max-h-0"
                    }`}
                  >
                    <ul className="py-2 space-y-4 text-xl ">
                      <li>
                        <NavLink
                          onClick={() => {
                            window.scroll({
                              top: 0,
                            });
                            setToggleNav(!toogleNave);
                          }}
                          to="/about"
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            window.scroll({
                              top: 0,
                            });
                            setToggleNav(!toogleNave);
                          }}
                          to="/team"
                        >
                          Team
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink
                          onClick={() => {
                            window.scroll({
                              top: 0,
                            });
                            setToggleNav(!toogleNave);
                          }}
                          to="/testimonial"
                        >
                          Testimonial
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      window.scroll({
                        top: 0,
                      });
                      setToggleNav(!toogleNave);
                    }}
                    to="/our-works"
                  >
                    Our Works
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      window.scroll({
                        top: 0,
                      });
                      setToggleNav(!toogleNave);
                    }}
                    to="/career"
                  >
                    Career
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={handleToggleService}
                    className="flex items-center gap-2"
                  >
                    Services
                    <span className="flex justify-center items-center h-6 w-6 bg-white rounded-full text-darkColor   relative group-hover:rotate-180 duration-300 transition-transform">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`w-4 h-4 transition-transform duration-500 ${
                          toggleService ? "rotate-180" : ""
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                        />
                      </svg>
                    </span>
                  </button>

                  <div
                    className={` overflow-hidden transition-all duration-700 ease   ${
                      toggleService ? "max-h-screen" : "max-h-0"
                    }`}
                  >
                    <ul className="py-2 space-y-4 text-xl ">
                      {servicesMenu?.map(({ id, path, title }) => (
                        <li key={id}>
                          <NavLink
                            to={path}
                            onClick={() => {
                              window.scroll({
                                top: 0,
                              });
                              setToggleNav(!toogleNave);
                            }}
                          >
                            {title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
}

export default MobileNavbar;
const servicesMenu = [
  {
    id: 1,
    title: "UI UX Design",
    path: "ui-ux",
    finding: "ui_ux",
  },
  {
    id: 2,
    title: "Web Development",
    path: "web-development",
    finding: "Webdev",
  },
  {
    id: 3,
    title: "Email Marketing",
    path: "email-marketing",
    finding: "email_marketing",
  },
  {
    id: 4,
    title: "Lead Generation",
    path: "lead-generation",
    finding: "lead_genaretion",
  },
  {
    id: 5,
    title: "Data Entry Service",
    path: "data-entry",
    finding: "data_entry",
  },
];
