import { OurSpecality } from "../../components/ourSpecality";
import { ServicePageBanner } from "../../components/servicePageBanner";
import { Services } from "../../components/services";
import { WhayChoseUS } from "../../components/whayChoseUs";
import { ServicesClientsFeedback } from "../../components/servicesClientsFeedback";
import { StragicWorking } from "./strategicWorking";



import { AboutServices } from "../../components/aoboutServices";
import { ServicesFeatuers } from "../../components/servicesFeatuers";
import { ServicesGallary } from "../../components/servicesGallary";
import {
  deliverables,
  justMakeSomething,
  uiUXGallery,
  uiuxServices,
  uiuxSpecality,
  uiuxWorkingProccess,
  whayChuseUs,
} from "../../utils/uiuxServiceInfo";
import { ServicesProjectInMind } from "../../components/servicesProjectInMind";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const UIUX = () => {
  const {
    error,
    data: ui_ux,
    isLoading,
  } = useQuery({
    queryKey: ["ui_ux"],
    queryFn: () => {
      const res = axios.get("/sercices.json");
      return res;
    },
  });


  const filterData = ui_ux?.data.filter(item => item.category.toLowerCase() === "ui ux design") || [];



  return (
    <>
      <ServicePageBanner
        title="UI/UX Design"
        subTitle="Services"
        description="We provide customized UI/UX solutions to your product, customer
      needs, and unique design challenges. Our goal is to provide
      efficient and effective solutions."
        linkText="/contact"
        buttonText="Free UX Consultation"
        serviceImage="https://i.ibb.co/4jf7XX1/uiuxd5e3f88efaf16ee8c315-65e004e12e1cb.webp"
      ></ServicePageBanner>

      <OurSpecality {...uiuxSpecality}></OurSpecality>

      <Services {...uiuxServices}></Services>

      <AboutServices {...justMakeSomething} />

      {/* deliverables */}

      <ServicesFeatuers {...deliverables} />

      {/* Strategic Working Section */}
      <StragicWorking {...uiuxWorkingProccess} />

      {/* gallary */}

      <ServicesGallary {...uiUXGallery} />

      {/* Clients Feedback */}
      <ServicesClientsFeedback />

      {/* Why Chuse Us */}
      <WhayChoseUS {...whayChuseUs} />

      {/* project in mind */}

      <ServicesProjectInMind error={error} isLoading={isLoading} categories={filterData[0]?.subServices}  />
    </>
  );
};
