

import { ProjectInMind } from "../../components/projectInMind";


export const MoreInfo = () => {
  return (
    <section className=" text-white overflow-x-hidden">
      {/* <div className="container">
        <div className="text-[#1F1F1F]   font-medium">
          <p className="lg:text-[56px]  lg:leading-[75px] sm:text-3xl text-xl ">
            <span className="text-[#0F0F0F] font-bold">At SM Technology,</span>
            innovation thrives in our close-knit team. We&apos;re here to turn
            your visions into digital realities, making your success our
            mission.
          </p>
        </div>

    //     <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 lg:items-center py-20 lg:py-[150px] gap-8">
    //       <div className="lg:max-h-80 h-[300px]">
    //         <img
    //           className="rounded-2xl h-full  w-full object-cover"
    //           src={Images.DownloadSecond}
    //           alt="about ourselves"
    //         />
    //       </div>
    //       <div className="h-[300px] lg:h-auto">
    //         <img
    //           className="rounded-2xl w-full  h-full object-cover"
    //           src={Images.DownloadFirst}
    //           alt="about ourselves"
    //         />
    //       </div>
    //       <div className="h-[300px] lg:max-h-80">
    //         <img
    //           className="rounded-2xl h-full  w-full object-cover"
    //           src={Images.DownloadSecond}
    //           alt="about ourselves"
    //         />
    //       </div>
    //       <div className="h-[300px] lg:h-auto">
    //         <img
    //           className="rounded-2xl w-full h-full object-cover"
    //           src={Images.DownloadFourth}
    //           alt="about ourselves"
    //         />
    //       </div>
    //     </div>

    //     <div className="text-[#1F1F1F]  text-xl sm:text-3xl lg:leading-[75px] lg:text-[56px] font-medium">
    //       <h4>
    //         Since 2010 we&apos;ve been working with amazing clints to create
    //         meaningful impact and compelling experiences.
    //       </h4>
    //     </div>

        <div
          className="mt-[120px] bg-no-repeat bg-cover bg-center text-white rounded-2xl py-20 sm:py-[120px] p-4"
          style={{ backgroundImage: `url('${Images.AboutBottomBg}')` }}
        >
          <div className="flex flex-col justify-center items-center gap-8  mb-[250px]">
            <h4 className="md:text-2xl text-lg sm:text-xl text-center">
              Got a project brewing in your brain?
            </h4>
            <h4 className="text-[#B0B0B0] text-xl sm:text-2xl md:text-[32px] font-semibold text-center">
              Spark it alive with a "Hi!" We're here to listen and help.
            </h4>
            <ButtonArrowTop name="Contact Us" />
          </div>
        </div>
      </div> */}
      <ProjectInMind />
    </section>
  );
};
