import { Images } from "../../utils/images";


export const SuccessStories = () => {

  return (
    <section className="section-padding-services-page bg-bgColor1 overflow-x-hidden">
      <div className="container">
        {/* heading */}
        <div
          className="service-title-font-size  text-[#2F2F2F] font-black w-full xl:max-w-[720px] text-center xl:text-end"
          data-aos="fade-right"
          data-aos-duration="3000"
        >
          <h4>Success Stories</h4>
          <h4>That Inspire</h4>
        </div>

        {/* content */}
        <div
          className="xl:grid flex flex-col-reverse grid-cols-1 xl:grid-cols-5 items-center gap-5 mt-[69px]"
          data-aos="fade-left"
          data-aos-duration="3000"
        >
          <div className="md:col-span-2">
            <img src={Images.SuccessStoriesImg} alt="success stories" />
          </div>

          <div className="md:col-span-3 text-base sm:text-xl">
            <p className="text-[#323232] mb-6 text-justify">
              At SM Technology, we believe in the power of innovation and
              determination. Our journey is paved with inspiring success stories
              that showcase the incredible potential when passion meets
              technology.
            </p>
            <p className="text-[#323232] border-b-2 border-[#3C3C3C] pb-3">
              Explore the inspiring tales of startups and established businesses
              alike, as they soar to new heights by unlocking the potential of
              SM Technology solutions.
            </p>

            <p className="text-[#323232] border-b-2 border-[#3C3C3C] py-3">
              <span className="font-bold leading-7 sm:leading-9">
                Innovative Solutions:
              </span>{" "}
              Redefining industries with cutting-edge technology.
              <br />
              <span className="font-bold leading-7 sm:leading-9">
                Determined Success:
              </span>{" "}
              Stories of overcoming challenges with SM Technology support.
              <br />
              <span className="font-bold leading-7 sm:leading-9">
                Growth through Partnership:
              </span>{" "}
              Collaborative efforts that drive business expansion.
              <br />
              <span className="font-bold leading-7 sm:leading-9">
                Heart in Technology:
              </span>{" "}
              Where passion meets advanced solutions, shaping success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
