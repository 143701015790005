import { Images } from "../../utils/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

export const ClientsFeedback = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const {
    isLoading,
    error,
    data: feedbacks,
  } = useQuery({
    queryKey: ["feedback"],
    queryFn: () =>
      axios(
        "https://raw.githubusercontent.com/Shahed007/smt-clients-feedback-data/main/clientsFeedback.json"
      ).then((response) => response.data), 
  });

  if (isLoading) return <h1>Loading..</h1>;
  if (error) return <h1>{error}</h1>;

  return (
    <section className={`overflow-x-hidden py-[120px] bg-bgColor1 `}>
      <div className="container">
        <div className="flex justify-center items-center">
          <div className="font-black text-3xl lg:text-7xl text-center">
            <h2 className={`mb-16 `}>Clients Feedback</h2>
          </div>
        </div>
      </div>
      <div
        className="relative xl:px-0 px-5 "
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          pagination={true}
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          breakpoints={{
            992: {
              pagination: false,
              slidesPerView: 2,
              spaceBetween: 30,
              navigation: { prevEl: ".button-prev", nextEl: ".button-next" },
              autoplay: false,
            },
            1200: {
              pagination: false,
              slidesPerView: 3,
              spaceBetween: 30,
              navigation: { prevEl: ".button-prev", nextEl: ".button-next" },
              autoplay: false,
            },
          }}
          className="relative flex "
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        >
          <SwiperSlide
            className={`${
              activeSlideIndex === 0 ? "xl:ml-[220px] xl:mr-0" : "xl:ml-0"
            } ${activeSlideIndex === 2 ? "xl:ml-0" : "xl:mr-0"}`}
          >
            <div
              className={`
                  bg-whiteColor text-darkColor overflow-hidden
                shadow-sm border p-4 md:px-8 md:py-10 rounded-lg   h-[400px] flex flex-col  lg:h-[450px] xl:h-[500px] 2xl:[250px]`}
            >
              <div className="grow">
                <div className="mb-3">
                  <img className="h-10 w-10" src={Images.Quotation2} alt="" />
                </div>
                <p className="font-medium md:text-2xl text-base   mb-20">
                  {feedbacks[0]?.comments}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <img
                    className="h-20 w-20 object-cover rounded-full"
                    src={feedbacks[0]?.image}
                    alt=""
                  />
                  <div>
                    <h2 className="text-2xl  font-medium">
                      {feedbacks[0]?.name}
                    </h2>
                    <p>{feedbacks[0]?.country}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {feedbacks?.slice(1).map((feedback) => (
            <SwiperSlide key={feedback.id}>
              <div
                className={`
                bg-whiteColor text-darkColor border overflow-hidden
                   shadow-sm  p-4 md:px-8 md:py-10 rounded-lg  h-[400px] flex flex-col  lg:h-[450px] xl:h-[500px] 2xl:[250px]`}
              >
                <div className="grow">
                  <div className="mb-3">
                    <img className="h-10 w-10" src={Images.Quotation2} alt="" />
                  </div>
                  <p className="font-medium md:text-2xl text-base  mb-20">
                    {feedback.comments}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <img
                      className="h-20 w-20 object-cover rounded-full"
                      src={feedback.image}
                      alt=""
                    />
                    <div>
                      <h2 className="text-2xl  font-medium">{feedback.name}</h2>
                      <p>{feedback.country}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div className="absolute inset-y-0 xl:flex hidden left-[180px] z-10  items-center">
            <button className=" button-prev  text-whiteColor  flex justify-center w-20 h-20 bg-info/80 active:scale-95 items-center  rounded-full shadow focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </button>
          </div>
          <div className="absolute inset-y-0 xl:flex hidden right-[380px] z-10  items-center">
            <button className=" button-next text-whiteColor   mr-2 lg:mr-4 flex justify-center items-center w-20 h-20 active:scale-95 bg-info/80 rounded-full shadow focus:outline-none h">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </button>
          </div>
        </Swiper>
      </div>
    </section>
  );
};
