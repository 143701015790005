import React from "react";
import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../button";
import { HiOutlineMail } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";

export const Footer = () => {
 //company deck download function
 const handleDownload = () => {

  const link = document.createElement('a');
  link.href = 'https://drive.google.com/file/d/1YqnhCHC77iIQxfJ2yXC6hrb1pqUMWiEM/view?usp=sharing';
  link.download = 'https://drive.google.com/file/d/1YqnhCHC77iIQxfJ2yXC6hrb1pqUMWiEM/view?usp=sharing';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const location = useLocation();

  return (
    <footer className="bg-darkColor text-white pt-[100px] sm:pt-[120px] ">
      <div className="md:container px-4">
        <div className="md:text-[100px] text-5xl sm:text-7xl gradientText">
          <h2 className="leading-[75px]">Connect</h2>
          <h2>With Us</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_180px_1fr_1fr] lg:gap-6 mt-16 gap-x-6 gap-y-10">
          <div className="sm:block hidden">
            <img
              className="md:h-auto md:w-auto h-16 "
              src={Images.footerArrow}
              alt="footer arrow icon"
            />
          </div>
          <div>
            <h4 className="font-bold text-xl sm:text-3xl uppercase mb-8">
              Sitemap
            </h4>
            <ul className="flex flex-col gap-5 text-[#ADADAD] text-lg sm:text-2xl font-medium">
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="/about" onClick={handleClick}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="ui-ux" onClick={handleClick}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="/our-works" onClick={handleClick}
                >
                  Our Work
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="career" onClick={handleClick}
                >
                  Career
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="/contact" onClick={handleClick}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-bold text-xl sm:text-3xl uppercase mb-8">
              Our Services
            </h4>
            <ul className="flex flex-col gap-5 text-[#ADADAD] text-lg sm:text-2xl font-medium">
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="ui-ux" onClick={handleClick}
                >
                  UI UX Design
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="web-development" onClick={handleClick}
                >
                  Web Development
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"
                  to="email-marketing" onClick={handleClick}
                >
                  Email Marketing
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"

                  to="lead-generation" onClick={handleClick}
                >
                  Lead Generation
                </Link>
              </li>
              <li>
                <Link
                  className="footerTextHoverEffect"

                  to="data-entry" onClick={handleClick}
                >
                  Data Entry Service
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-bold text-xl sm:text-3xl uppercase mb-8">
              Contact Us
            </h4>
            {/* <p className=' mb-7 text-[#ADADAD] text-2xl font-medium footerTextHoverEffect'>30 N Gould St, Sheridan, Wyoming 82801, US</p>
            <p className='underline mb-7 text-[#ADADAD] text-2xl font-medium footertexthovereffect'>hello@smtech24.com</p> */}

            <p className="mb-7 text-[#ADADAD] text-lg sm:text-2xl font-medium footerTextHoverEffect  ">
              <IoLocationOutline className="inline-block mr-2 " /> 30 N Gould
              St, Sheridan, <span className="xl:pl-10">Wyoming 82801, US</span>
            </p>
            <p className="mb-7 text-[#ADADAD] text-lg sm:text-2xl font-medium footerTextHoverEffect">
              <IoCallOutline className="inline-block mr-2" /> +1 (307)-207-3020
            </p>
            <p className="mb-7 text-[#ADADAD] text-lg sm:text-2xl font-medium footerTextHoverEffect">
              <HiOutlineMail className="inline-block mr-2" /> hello@smtech24.com
            </p>
            <Link
              className="footerTextHoverEffect"
              to="/contact" onClick={handleClick}
            >
              <ButtonArrowTop name="Contact Us" />
            </Link>
          </div>
        </div>
        <div className="flex justify-end my-12">
          <div className="w-12 h-12 cursor-pointer rounded-full bg-white group hover:bg-info duration-300 grid place-items-center">
            <Link
              className="footerTextHoverEffect"
              onClick={scrollToTop}
              to={location.pathname}
            >
              <span className="material-symbols-outlined text-[#0F8F71] font-bold group-hover:text-white duration-300">
                north
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* {/ footer end /} */}
      <div className="pt-10 pb-14 border-t-2 border-[#B3B3B3]">
        <div className="md:container px-4">
          <div className="flex xl:flex-row flex-col justify-center gap-8  xl:justify-between items-center">
            <div className="flex gap-5 justify-between items-center sm:flex-row flex-col">
              <Link onClick={handleClick} to="/">
                <img
                  className="sm:w-[320px] w-60"
                  src={logo}
                  alt="footer logo"
                />
              </Link>
              <div className="flex items-center gap-5 group justify-between">
                <div
                  className="sm:w-16 sm:h-16 w-10 h-10 cursor-pointer rounded-full bg-white group-hover:bg-secondary duration-300 grid place-items-center"
                  onClick={handleDownload}
                >
                  <span className="material-symbols-outlined text-4xl text-[#0F8F71] group-hover:text-white duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                  </span>
                </div>
                <Link to="https://drive.google.com/file/d/1YqnhCHC77iIQxfJ2yXC6hrb1pqUMWiEM/view?usp=sharing" target="_blank" className="md:text-3xl text-lg font-medium group-hover:text-secondary duration-300" id="pdfLink">
                  Company Deck
                </Link>
              </div>
            </div>

            <div className="flex  gap-5">
              <Link
                to="https://www.behance.net/sm_technology"
                className="sm:text-3xl text-lg font-medium footerTextHoverEffect"
                target="_blank"
              >
                Behance
              </Link>
              <Link
                to="https://dribbble.com/smtechnology_"
                className="sm:text-3xl text-lg font-medium footerTextHoverEffect"
                target="_blank"
              >
                Dribbble
              </Link>
              <Link
                to="https://www.linkedin.com/company/smtechnology/"
                className="sm:text-3xl text-lg font-medium footerTextHoverEffect"
                target="_blank"
              >
                LinkedIn
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
