import { useEffect, useRef } from "react";

export const WorksHero = () => {
  // const videoRef = useRef(null);

  // // Function to save the current playback position
  // const savePlaybackPosition = () => {
  //   if (videoRef.current) {
  //     sessionStorage.setItem(
  //       "videoPlaybackPosition",
  //       videoRef.current.currentTime
  //     );
  //   }
  // };

  // // Function to retrieve and set the playback position when the page loads
  // const setPlaybackPosition = () => {
  //   const playbackPosition = sessionStorage.getItem("videoPlaybackPosition");
  //   if (playbackPosition && videoRef.current) {
  //     videoRef.current.currentTime = parseFloat(playbackPosition);
  //   }
  // };

  // // Function to handle video playback
  // const handleVideoPlay = () => {
  //   if (videoRef.current) {
  //     videoRef.current.play().catch((error) => {
  //       console.error("Failed to play video:", error);
  //     });
  //   }
  // };

  // // Effect to set playback position and start video playback when the component mounts
  // useEffect(() => {
  //   setPlaybackPosition();
  //   handleVideoPlay();
  // }, []);
  return (
    // <section
    //   className="min-h-screen py-[120px] bg-no-repeat bg-cover bg-center text-white flex items-center"
    //   style={{ backgroundImage: `url('${Images.HeroSectionBg}')` }}
    // >
    //   <div className="container">
    //     <CommonHeading
    //       heading="Your Data Entry &"
    //       subHeading="Web Research Partner"
    //       description="An Experience design agency building high-end products and experiences that grow your business exponentially."
    //     />
    //   </div>
    // </section>
    <>
    <section className="h-[80vh]   relative overflow-hidden bg-cover bg-center" style={{backgroundImage: `url('https://i.ibb.co/Bf0gJ5Q/our-works.jpg')`}}>
      <div className="bg-black/60 relative z-20 w-full h-full flex items-center">
        <div className="max-w-6xl px-3 mx-auto  w-full text-white ">
          <h1 className="md:text-8xl text-5xl tracking-wider sm:text-6xl font-bold bg-gradient-to-b from-white to-gray-500 text-transparent bg-clip-text mb-7">
            <span>Work</span>
            <br />
            <span className="flex items-center gap-2 before:block before:h-5 before:w-20 sm:before:w-28  before:bg-gray-500">
              Portfolio
            </span>
          </h1>
          <p className="text-xl w-full sm:w-[50%]">
            Crafting top-notch products and experiences to supercharge your
            business growth.
          </p>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-darkColor/40"></div>
      {/* <div className="absolute top-0 left-0 w-full h-full z-10">
      <video
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          preload="auto"
          playsInline // Necessary for some mobile devices
          onEnded={savePlaybackPosition} // Save playback position when video ends
        >
          <source src="/work-video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div> */}
    </section>
    
    </>
  );
};
