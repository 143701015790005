import { RecentWork } from "../../components/RecentWork";
import AboutOurselves from "../../components/aboutOurselves";
import { Banner } from "../../components/banner";
import { ClientsFeedback } from "../../components/clientFeedback";
import { CustomSolutions } from "../../components/customSolutions";
import { NewsLetter } from "../../components/newsletter";
// import ClientsFeedback from "../../components/clientFeedback";
import { ProjectInMind } from "../../components/projectInMind";
export const Home = () => {
  return (
    <>
      <Banner />
      <AboutOurselves />
      <CustomSolutions />
      <RecentWork />
      <ClientsFeedback></ClientsFeedback>
      <NewsLetter />
      <ProjectInMind />
    </>
  );
};
