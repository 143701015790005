import React from 'react'

export const CommonSectionHeading = ({ heading1, heading2, description }) => {
    return (
        <div className="lg:flex justify-between items-center gap-8">
            <div className='service-title-font-size font-bold lg:max-w-2xl'>
                <h4 className="text-[#000] ">{heading1}</h4>
                <h4 className="text-[#7B7B7B] lg:text-end  pb-5 lg:pb-0 "  >{heading2}</h4>
            </div>
            {description && <div className="max-w-md">
                <p className='text-[#464646] sm:text-lg text-base md:text-2xl'>{description}</p>
            </div>}
        </div>
    )
}
