import { useEffect, useRef, useState } from "react";
import { Modal } from "../../components/modal";

const aboutVideoBackground = {
  backgroundImage: `url("https://i.ibb.co/mtS58jy/jpeg-optimizer-IMG-7554.jpg")`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
};

export const AboutVideoSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef();

  const handleModalOpen = () => {
    setIsOpen(!isOpen);
  };

  // handling vide play mecanigom
  useEffect(() => {
    const videoElement = videoRef.current;

    if (isOpen) {
      videoElement.currentTime = 0;
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [isOpen]);

  return (
    <>
      {/* About Video Section */}
      <section
        style={aboutVideoBackground}
        className="h-[60vh] bg-no-repeat flex justify-center items-center relative overflow-x-hidden"
      >
        <div class="relative flex  h-[100px] w-[100px] z-20">
          <div class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></div>
          <button
            onClick={handleModalOpen}
            class="relative flex justify-center items-center rounded-full h-[100px] w-[100px]  border-[2px] border-white text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-14 h-14"
            >
              <path
                fillRule="evenodd"
                d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-gray-950/30 z-10"></div>
      </section>

      {/* Modal */}
      <Modal isOpen={isOpen} handleModalOpen={handleModalOpen}>
        <div className="sm:h-full h-[250px] w-full">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            loop
            controls
          >
            <source src="/bd-calling-video.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </>
  );
};
