export const ButtonArrowTop = (props) => {
  return (
    <>
      <div className="flex items-center h-[54px] w-fit group relative">
        <div className="w-[45px] h-[45px] mt-[5px] md:mt-0 md:w-[54px] md:h-[54px] ml-[7px] md:ml-0 bg-secondary rounded-full text-white grid place-items-center absolute top-0 left-0">
          <span className="material-symbols-outlined text-2xl">north_east</span>
        </div>
        <button onClick={props.handleScrollJobSection} className={`bg-secondary border border-secondary text-white md:text-xl py-3 ${props.px ? `${props.px}` : "px-5"} font-medium rounded-full group-hover:translate-x-[54px] transition-transform ease-out duration-200 z-10`}>{props?.name}</button>
        <div className="w-[45px] h-[45px] mt-[5px] md:mt-0 md:w-[54px] md:h-[54px]  md:ml-0 bg-secondary rounded-full text-white grid place-items-center">
          <span className="material-symbols-outlined text-2xl">north_east</span>
        </div>
      </div>
    </>
  );
};