import { Images } from "../../utils/images";
import { ButtonArrowTop } from "../../components/button";
import { Link } from "react-router-dom";

export const FourOFour = () => {
  return (
    <section className="bg-[#ECF0F1]">
      <div className="container">
        <div className="min-h-screen flex justify-center items-center flex-col">
          <img
            className="max-h-[60vh] p-10"
            src={Images.notFound}
            alt="not found"
          />
          <div className="flex flex-col gap-12 items-center pb-20">
            <p className="bg-[#FFFCF3] p-4 text-[32px] font-semibold text-[#1B2821] rounded-xl">
              Oops! Sorry, we could not find the page 
            </p>
            <Link to="/">
              <ButtonArrowTop name="Go to home page" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
