import { AboutServices } from "../../components/aoboutServices";
import { OurSpecality } from "../../components/ourSpecality";
import { ServicePageBanner } from "../../components/servicePageBanner";
import { Services } from "../../components/services";
import { ServicesClientsFeedback } from "../../components/servicesClientsFeedback";
import { ServicesFeatuers } from "../../components/servicesFeatuers";
import { ServicesGallary } from "../../components/servicesGallary";
import { ServicesProjectInMind } from "../../components/servicesProjectInMind";
import { WhayChoseUS } from "../../components/whayChoseUs";
import {
  DataEntrySpecality,
  aboutForDataEntry,
  dataEntryGallery,
  dataEntryServices,
  dataEntryWorkingProccess,
  dataEntrydeliverables,
  dataEntrywhayChuseUs,
} from "../../utils/dataEntryServicesInfo";
import { Images } from "../../utils/images";
import { StragicWorking } from "../ui-ux/strategicWorking";

export const Data = () => {
  return (
    <>
      <ServicePageBanner
        title="Data Entry"
        subTitle="Services"
        description="Get streamlined data entry solutions tailored to your requirements. Our expert team ensures accuracy and efficiency, helping you optimize your operations effortlessly."
        linkText="/contact"
        buttonText="Get Started with Data Entry "
        serviceImage="https://i.ibb.co/SRM5s6G/data-N-00f3fd13852aadb94442.png"
      ></ServicePageBanner>
      {/* Data Entry Specality */}
      <OurSpecality {...DataEntrySpecality} />
      {/* Date Entry Services */}
      <Services {...dataEntryServices} />
      {/* About for data entry */}
      <AboutServices {...aboutForDataEntry} />
      {/* Data entry deliverables section */}
      <ServicesFeatuers {...dataEntrydeliverables} />
      {/* Data Entry Workig process */}
      <StragicWorking {...dataEntryWorkingProccess} />
      {/* Data Entry Gallery */}
      <ServicesGallary {...dataEntryGallery} />
      {/* Data Entry Clients Feed back */}
      <ServicesClientsFeedback />
      {/* Data Whay chouse us section with accordion */}
      <WhayChoseUS {...dataEntrywhayChuseUs} />
      {/* Project in mind */}
      <ServicesProjectInMind />
    </>
  );
};
