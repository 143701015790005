import Logo from "../assets/logo.png";
import heroBg from "../assets/Photo/hero_bg.webp";
import heroHeaderIcon from "../assets/Illustration/hero header icon.svg";
import footerArrow from "../assets/Illustration/bottom section down arrow curving right.svg";
import heroBadge from "../assets/Icon/badge_hero_section.svg";
import aboutOurselvesImg1 from "../assets/Photo/UI-UX-Design.avif";
import aboutOurselvesImg2 from "../assets/Photo/1.webp";
import FirstImage from "../assets/Photo/Rectangle 30.webp";
import SecondImage from "../assets/Photo/open-communication2.avif";
import DownloadFirst from "../assets/Photo/group-people.webp";
import DownloadSecond from "../assets/Photo/second_gallery-min.webp";
import DownloadThird from "../assets/Photo/two-people.avif";
import DownloadFourth from "../assets/Photo/three-people-2.avif";
import newsletter1 from "../assets/Photo/newsletter1.webp";
import newsletter2 from "../assets/Photo/newsletter2.webp";
import newsletter3 from "../assets/Photo/newsletter3.webp";
import whatsappIcon from "../assets/Illustration/whatsappIcon.svg";
import testimonialBg from "../assets/Photo/testimonialBg.webp";
import testimonial1 from "../assets/Photo/testimonial1.webp";
import space from "../assets/Illustration/space.svg";
import spaceX from "../assets/Illustration/spaceX.svg";
import MiddleGallery from "../assets/Photo/fifth_gallery.webp";
import customSolution1 from "../assets/Photo/uxui.webp";
import customSolution2 from "../assets/Photo/web_dev.webp";
import customSolution3 from "../assets/Photo/data entry.webp";
import customSolution4 from "../assets/Photo/email_marketing_for_custom_solution.webp";
import customSolution5 from "../assets/Photo/b2b_lead_generation.webp";
import SliderOne from "../assets/Photo/Rectangle 19.webp";
import SliderTwo from "../assets/Photo/Rectangle 22.webp";
import Feedback from "../assets/Photo/feedback.webp";
import AboutHeroImage from "../assets/Photo/about-hero-img.webp";
import SuccessStoriesImg from "../assets/Photo/success-stories.webp";
import OpenCommunication from "../assets/Photo/open-communication.avif";
import AboutIcon1 from "../assets/Icon/iconamoon_discover-thin.png";
import AboutIcon2 from "../assets/Icon/ph_target-light.png";
import AboutIcon3 from "../assets/Icon/prototype.png";
import AboutIcon4 from "../assets/Icon/deliver.png";
import AboutBottomBg from "../assets/Photo/about-bottom-img.webp";
import team1 from "../assets/Photo/team_ceo.webp";
import team2 from "../assets/Photo/team_chairman.webp";
import team3 from "../assets/Photo/team3.webp";
import team4 from "../assets/Photo/team4.webp";
import team5 from "../assets/Photo/team5.webp";
import team6 from "../assets/Photo/team6.webp";
import team7 from "../assets/Photo/team7.webp";
import team8 from "../assets/Photo/team8.webp";
import linkedin from "../assets/Photo/Linkedin.webp";
import teamMemberImg from "../assets/Photo/teamMember.webp";
import notFound from "../assets/Photo/notFound.webp";
// import HeroSectionBg from "../assets/Photo/HeroSectionBg.webp";
import Work1 from "../assets/Photo/work1.webp";
import Work2 from "../assets/Photo/work2.webp";
import Work3 from "../assets/Photo/work3.webp";
import PlusIcon from "../assets/Photo/plus-icon.webp";
import CareerHero from "../assets/Photo/career-hero.webp";
import CoreValue1 from "../assets/Photo/coreValue1.webp";
import CoreValue3 from "../assets/Photo/coreValue3.webp";
import CoreValue5 from "../assets/Photo/coreValue5.webp";
import CoreValue6 from "../assets/Photo/coreValue6.webp";
import Career1 from "../assets/Photo/career1_User-experience.webp";
import Career2 from "../assets/Photo/career2_Statistics.webp";
import Career3 from "../assets/Icon/conversion.png";
import Career4 from "../assets/Icon/marketing.png";
import Career5 from "../assets/Photo/career5_Statistics.webp";
import Career6 from "../assets/Icon/file-case.png";
import Job from "../assets/Photo/job.webp";
import CustomArrow from "../assets/Photo/custom-arrow.webp";
import Quotation from "../assets/Photo/quotation.webp";
import Employee from "../assets/Photo/employee.webp";

import Gallery1 from "../assets/Photo/Optimized-Gal1.webp";
import Gallery2 from "../assets/Photo/Optimized-Gal2.webp";
import Gallery3 from "../assets/Photo/Optimized-Gal3.webp";
import Gallery4 from "../assets/Photo/Optimized-Gal4.webp";
import Custom1 from "../assets/Photo/B2B lead generation.webp";
import Custom2 from "../assets/Photo/Data Entry & web research.webp";
import Custom3 from "../assets/Photo/email marketing.webp";
import Custom4 from "../assets/Photo/UX Design.webp";
import Quotation2 from "../assets/Photo/qutation2.svg";
import Webdev from "../assets/Photo/web-developemtn-service.jpg";
import clent1 from "../assets/Photo/0.webp";
import clent2 from "../assets/Photo/11.webp";
import clent3 from "../assets/Photo/22.webp";
import clent4 from "../assets/Photo/33.webp";
import clent5 from "../assets/Photo/44.webp";
import clent6 from "../assets/Photo/55.webp";
// import CardGal1 from "../assets/Photo/works1.webp";
// import CardGal2 from "../assets/Photo/works2.webp";
import CardGal3 from "../assets/Photo/works3.webp";
// import CardGal42 from "../assets/Photo/jpeg-optimizer_Card4.png";
// import CardGal52 from "../assets/Photo/jpeg-optimizer_Card5.jpg";
// import CardGal62 from "../assets/Photo/jpeg-optimizer_Card6.jpg";
import ui_ux from "../assets/Photo/UI-UX-Design.avif";
import email_marketing from "../assets/Photo/email marketing.webp";
import lead_genaretion from "../assets/Photo/B2B lead generation.webp";
import data_entry from "../assets/Photo/data entry.webp";
// import CardGal4 from "../assets/Photo/works5.avif";
import Jabed from "../assets/Photo/jabed.webp";
import About from "../assets/Photo/About2.JPG";
import serviceImage from "../assets/Photo/service-banner-image.png";
import glow from "../assets/Photo/glow.png";
import webDesign from "../assets/Icon/web-design.svg";
import appDesign from "../assets/Icon/app-design.svg";
import productDesign from "../assets/Icon/product-design.svg";
import mobileUiUxDesign from "../assets/Photo/mobile-ui-ux-design.png";
import webUiUxDesign from "../assets/Photo/web-ui-ux-design.png";
import wireframingPrototyping from "../assets/Photo/wireframing-prototyping.png";
import dataDriven from "../assets/Photo/data-driven-design.png";
import Video from "../assets/Photo/video.png";
import UiGalOne from "../assets/Photo/service-gal-1.png";
import UiGalTwo from "../assets/Photo/service-gal-2.png";
import UiGalThree from "../assets/Photo/service-gal-3.png";
import UiGalFour from "../assets/Photo/service-gal-4.png";
import UiGalFive from "../assets/Photo/service-gal-5.png";
import UiGalSix from "../assets/Photo/service-gal-6.png";
import UiGalSeven from "../assets/Photo/service-gal-7.png";
import dimond1 from "../assets/Icon/dimond1.svg";
import badge1 from "../assets/Icon/badge1.svg";
import tecnical1 from "../assets/Icon/technical1.svg";
import productivaty1 from "../assets/Icon/productivity1.svg";
import whayChuseUsBanner from "../assets/Photo/whay-chuse-us-banner.png";
import Qutation3 from "../assets/Icon/qutation3.svg";
import workingProccessIcon from "../assets/Icon/working-process-icon.png";
import workProccessLine from "../assets/Photo/work-process-line.png";
import Circle from "../assets/Photo/dev.png";
import cw from "../assets/Icon/custom-website.png";
import rd from "../assets/Icon/responsive-webiste.png";
import ufi from "../assets/Icon/user-friendly.png";
import cms from "../assets/Icon/cms.png";
import cbc from "../assets/Icon/cross-platform.png";
import seo from "../assets/Icon/seo.png";
import swa from "../assets/Icon/applications.png";
import mr from "../assets/Icon/response.png";
import fls from "../assets/Icon/speed.png";
import ief from "../assets/Icon/integration.png";
import ms from "../assets/Icon/technical-support.png";
import wordpress from "../assets/Photo/worpress.jpg";
import webflow from "../assets/Photo/webflow.jpg";
import shopify from "../assets/Photo/shopfy.jpg";
import customeCoding from "../assets/Photo/custome-website.jpg";
import shape1 from "../assets/Photo/image.png";
import campaign from "../assets/Icon/campaign.png";
import automatedWorFlows from "../assets/Icon/workflow.png";
import targetedSagments from "../assets/Icon/target-audience.png";
import performanceAnalytics from "../assets/Icon/benchmark.png";
import Targeted_Email_Campaigns from "../assets/Photo/Targeted-Email-Campaigns.jpg";
import Automated_Email_Sequences from "../assets/Photo/Automated-Email-Sequences.jpg";
import Email_List_Management from "../assets/Photo/Email-List-Management.jpg";
import Performance_Analytics from "../assets/Photo/Performance-Analytics.jpg";
import DevGalOne from "../assets/Photo/dev-1.webp";
import DevGalTwo from "../assets/Photo/first.webp";
import DevGalThree from "../assets/Photo/dev-3.webp";
import DevGalFour from "../assets/Photo/dev-2.webp";
import DevGalFive from "../assets/Photo/dev-5.webp";
import DevGalSix from "../assets/Photo/dev-6.webp";
import DevGalSeven from "../assets/Photo/dev-7.png";
import EmailGalOne from "../assets/Photo/email1.webp";
import EmailGalTwo from "../assets/Photo/email2.webp";
import EmailGalThree from "../assets/Photo/email3.webp";
import EmailGalFour from "../assets/Photo/email4.webp";
import EmailGalFive from "../assets/Photo/email5.webp";
import EmailGalSix from "../assets/Photo/email6.webp";
import EmailGalSeven from "../assets/Photo/email7.webp";

import targeted_Outreach from "../assets/Icon/Targeted-Outreach.png";
import compelling_Content from "../assets/Icon/Compelling-Content.png";
import lead_Scoring from "../assets/Icon/Lead-Scoring.png";
// import targeted_Lead_Generation from "../assets/Photo/Targeted-Lead-Generation.jpg";
// import content_Marketing_for_Leads from "../assets/Photo/";
// import automated_Lead_Nurturing from "../assets/Photo/Automated-Lead-Nurturing.jpg";
// import performance_driven_Lead_Campaigns from "../assets/Photo/Performance-driven-Lead-Campaigns.jpg";
import shape3 from "../assets/Photo/dataN.png";
import accuracy_Assurance from "../assets/Icon/Accuracy-Assurance.png";
import timely_Processing from "../assets/Icon/Timely-Processing.png";
import custom_Solutions from "../assets/Icon/Custom-Solutions.png";
import confidential_Handling from "../assets/Icon/Confidential-Handling.png";
import quickData_Entry from "../assets/Photo/QuickData-Entry.jpg";
import dataMasters_Solutions from "../assets/Photo/DataMasters-Solutions.svg";
import swiftInput_Services from "../assets/Photo/SwiftInput-Services.jpg";
import precisionData_Solutions from "../assets/Photo/PrecisionData-Solutions.jpg";
// import LeadGalOne from "../assets/Photo/lead1.webp";
// import LeadGalTwo from "../assets/Photo/lead2.webp";
// import LeadGalThree from "../assets/Photo/lead3.webp";
// import LeadGalFour from "../assets/Photo/lead4.png";
// import LeadGalFive from "../assets/Photo/lead5.jpg";
// import LeadGalSix from "../assets/Photo/lead6.png";
// import LeadGalSeven from "../assets/Photo/lead7.jpg";
import DataGalOne from "../assets/Photo/data1.webp";
import DataGalTwo from "../assets/Photo/data2.webp";
import DataGalThree from "../assets/Photo/data3.webp";
import DataGalFour from "../assets/Photo/data4.webp";
import DataGalFive from "../assets/Photo/data5.webp";
import DataGalSix from "../assets/Photo/data6.webp";
import DataGalSeven from "../assets/Photo/data7.webp";
import ServiceImage1 from "../assets/Photo/uiux.png";
import UiCardOne from "../assets/Photo/uiCard1.webp";
import gallery1 from "../assets/Photo/aboutHero.jpg";
// import gallery7 from "../assets/Photo/gallery-5.webp";
import gallery3 from "../assets/Photo/gallery-3.webp";
import gallery4 from "../assets/Photo/gallery-4.webp";
import UiCardTwo from "../assets/Photo/uiCard2.jpg";
import UiCardThree from "../assets/Photo/product.webp";
import UiCardFour from "../assets/Photo/saas2.jpg";
import DevCardOne from "../assets/Photo/devCard1.webp";
import DevCardTwo from "../assets/Photo/devCard2.jpg";
import DevCardThree from "../assets/Photo/devCard3.webp";
import DevCardFour from "../assets/Photo/devCard4.webp";
import EmailCardOne from "../assets/Photo/emailCard1.jpeg";
import EmailCardTwo from "../assets/Photo/emailCard2.jpg";
import EmailCardThree from "../assets/Photo/emailCard3.jpg";
import EmailCardFour from "../assets/Photo/emailCard4.webp";

// import LeadCardTwo from "../assets/Photo/leadCard2.webp";
// import LeadCardThree from "../assets/Photo/leadCard3.webp";
// import LeadCardFour from "../assets/Photo/leadCard4.webp";
import DataCardOne from "../assets/Photo/dataCard1.webp";
import DataCardTwo from "../assets/Photo/dataCard2.webp";
import DataCardThree from "../assets/Photo/dataCard3.webp";
import DataCardFour from "../assets/Photo/dataCard4.webp";
import bangladehsLocation from "../assets/Photo/bangladesh.png";
import unitedStateLocation from "../assets/Photo/united-state.png";
import AboutBackgroundVideoImage from "../assets/Photo/about.jpg";
import whatPeopleSayBackground from "../assets/Photo/what-people-say-bg.png";
import Icon1 from "../assets/Photo/icon1.png";
import Icon2 from "../assets/Photo/icon2.png";
import Icon3 from "../assets/Photo/icon5.png";
import Icon4 from "../assets/Photo/icon4.png";
import Growth from "../assets/Photo/growth.jpg";
import CEO from "../assets/Photo/ceo2.jpg";
import CEO1 from "../assets/Photo/Ceo.jpg";
import Chairman from "../assets/Photo/team/management/ChairMan.jpg";
import server from "../assets/Icon/server.png";
import monitor from "../assets/Icon/monitor.png";
import chooseIcon1 from "../assets/Photo/iconOne.png";
import chooseIcon2 from "../assets/Photo/iconFour.png";
import chooseIcon3 from "../assets/Photo/iconC4.png";
import chooseIcon4 from "../assets/Photo/icons8-response-100.png";
import TeamLead3 from "../assets/Photo/team/management/rakibul islam.png";
import TeamLead4 from "../assets/Photo/team/management/rajon.png";
// import GmMaruf from "../assets/Photo/team/webdev/maruf3.jpg";
import BillalLeader from "../assets/Photo/team/webdev/billal4.jpg";
import KamalLeader from "../assets/Photo/team/webdev/jpeg-optimizer_Kamal.JPG";
import Tufael from "../assets/Photo/team/webdev/tufael.jpg";
// import SalesTahidul from "../assets/Photo/team/sales/tahidul.png";
import Dev4 from "../assets/Photo/team/webdev/logo3.jpg";
import Ali from "../assets/Photo/team/webdev/jpeg-optimizer_Ali.JPEG";
import Shahed from "../assets/Photo/team/webdev/jpeg-optimizer_MD-Shahed.png";
import Imran from "../assets/Photo/team/webdev/jpeg-optimizer_Imran.JPG";
import Afif from "../assets/Photo/team/webdev/jpeg-optimizer_Afif.png";
import Riyad from "../assets/Photo/team/webdev/jpeg-optimizer_Riyad.JPG";
import Zaiish from "../assets/Photo/team/webdev/jpeg-optimizer_zalish.jpg";
// import Riyad2 from "../assets/Photo/team/webdev/jpeg-optimizer_Riyad.JPEG";
// import Anarul from "../assets/Photo/team/webdev/jpeg-optimizer_anarul.JPG";
import Tanvir from "../assets/Photo/team/webdev/tanvir.JPEG";
import Parvez from "../assets/Photo/team/webdev/parvez.JPG";
// import Rahat from "../assets/Photo/team/webdev/rahat.JPG";
// import UxNaeem from "../assets/Photo/team/ui-ux/naeem.JPG";
// import UxSiam from "../assets/Photo/team/ui-ux/siam.JPG";
// import UxJannat from "../assets/Photo/team/ui-ux/jannat.JPG";
// import SalesDalim from "../assets/Photo/team/sales/dalim.JPG";
// import SalesRokon from "../assets/Photo/team/sales/rokon.JPG";
// import BpuZidan from "../assets/Photo/team/bpu/zidan.JPG";
// import NewGal1 from "../assets/Photo/gal1.JPG";
// import NewGal2 from "../assets/Photo/gal4.JPG";
// import NewGal3 from "../assets/Photo/gal2.JPG";
// import NewGal4 from "../assets/Photo/gallersir.webp";
// import aboutHeroSectionBackgroun from "../assets/Photo/aboutHero.webp";
// import gameBarse from "../assets/Photo/gameborse-image-2.png";
import Sohel from "../assets/Photo/sohel.jpg"
import AsadullahDefenders from "../assets/Photo/asadullah.jpg";
import ForhadDefenders from "../assets/Photo/forhad.png";
import NazrulSales from "../assets/Photo/nazrulSales.jpg";
import JhumaWarriors from "../assets/Photo/jhuma.JPEG";
import ArifSales from "../assets/Photo/arifSales3.jpeg";
import Firoz from "../assets/Photo/firoz.jpg";
import Shamim from "../assets/Photo/shamim.jpg";
import Jhumur from "../assets/Photo/team/webdev/jhumur.png";

export const Images = {
  gameBarse:
    "https://i.ibb.co/BNZZtVX/gameborse-image-2-ezgif-com-jpg-to-webp-converter.webp",
  NewGal2: "https://i.ibb.co/mFH5Cv1/gal4.jpg",
  gallery7L: "https://i.ibb.co/VV2nXXX/gallery-5.webp",
  SalesRokon: "https://i.ibb.co/9tjPVk0/rokon.jpg",
  SalesDalim: "https://i.ibb.co/MB4v22y/dalim.jpg",
  SalesTahidul: "https://i.ibb.co/0qZ13zk/tahidul.png",
  CEO1: "https://i.ibb.co/pd73p4Z/Ceo.jpg",
  TeamLead3,
  Jhumur,
  Shamim,
  TeamLead4,
  Firoz,
  ArifSales,
  AsadullahDefenders,
  ForhadDefenders,
  NazrulSales,
  JhumaWarriors,
  Sohel,
  // MyVideo2,
  chooseIcon2,
  chooseIcon3,
  chooseIcon4,
  monitor,
  server,
  Growth,
  CEO,
  Chairman,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  AboutBackgroundVideoImage,
  whatPeopleSayBackground,
  bangladehsLocation,
  unitedStateLocation,
  gallery4,
  gallery3,
  gallery1,

 
 


 


  DataGalOne,

  dataMasters_Solutions,

  accuracy_Assurance,
  timely_Processing,
  custom_Solutions,
  confidential_Handling,
  compelling_Content,
  lead_Scoring,
  targeted_Outreach,


  campaign,
  automatedWorFlows,
  targetedSagments,
  performanceAnalytics,

  


 
  
  



  cw,
  rd,
  ufi,
  cms,
  cbc,
  seo,
  swa,
  mr,
  fls,
  ief,
  ms,
 
  workProccessLine,
  workingProccessIcon,
  Qutation3,
  dimond1,
  badge1,
  tecnical1,
  productivaty1,
  whayChuseUsBanner,
  

 


  

 

  webDesign,
  appDesign,
  productDesign,
  glow,
  serviceImage,

  About,
  Jabed,
  ui_ux,
  email_marketing,
  lead_genaretion,
  data_entry,
  Logo,
  heroBg,
  heroHeaderIcon,
  heroBadge,
  footerArrow,
  aboutOurselvesImg1,
  aboutOurselvesImg2,
  FirstImage,
  SecondImage,
  DownloadFirst,
  DownloadSecond,
  DownloadThird,
  DownloadFourth,
  newsletter1,
  newsletter2,
  newsletter3,
  whatsappIcon,
  testimonialBg,
  testimonial1,
  space,
  spaceX,
  MiddleGallery,
  customSolution1,
  customSolution2,
  customSolution3,
  customSolution4,
  customSolution5,
  SliderOne,
  SliderTwo,
  Feedback,
  AboutHeroImage,
  SuccessStoriesImg,
  OpenCommunication,
  AboutIcon1,
  AboutIcon2,
  AboutIcon3,
  AboutIcon4,
  AboutBottomBg,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  linkedin,
  teamMemberImg,
  notFound,
  Work1,
  Work2,
  Work3,
  PlusIcon,
  CoreValue1,
  CoreValue3,
  CoreValue5,
  CoreValue6,
  Career1,
  Career2,
  Career3,
  Career4,
  Career5,
  Career6,
  Job,
  CustomArrow,
  Quotation,
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Custom1,
  Custom2,
  Custom3,
  Custom4,
  Quotation2,
  Webdev,
  clent1,
  clent2,
  clent3,
  clent4,
  clent5,
  clent6,
  CardGal3,
};
// export const Images = {
//   gameBarse,
//   aboutHeroSectionBackgroun,
//   NewGal1:"https://i.ibb.co/b24zDXR/gal1.jpg",
//   NewGal2,
//   NewGal3: "https://i.ibb.co/zn9Lrdr/gal2.jpg",
//   NewGal4:"https://i.ibb.co/FzNW80X/gallersir.webp",
//   CardGal42:"",
//   CardGal52:"",
//   CardGal62:"",
//   gallery7,
//   BpuZidan,
//   SalesRokon,
//   SalesDalim,
//   UxJannat,
//   UxSiam,
//   UxNaeem,
//   GmMaruf,
//   Rahat,
//   Anarul,
//   Tanvir,
//   Parvez,
//   Riyad2,
//   Riyad,
//   Zaiish,
//   Afif,
//   Imran,
//   Shahed,
//   Dev4,
//   Ali,
//   SalesTahidul,
//   CEO1,
//   KamalLeader,
//   BillalLeader,
//   Tufael,
//   TeamLead3,
//   TeamLead4,
//   // MyVideo2,
//   chooseIcon1,
//   chooseIcon2,
//   chooseIcon3,
//   chooseIcon4,
//   monitor,
//   server,
//   Growth,
//   CEO,
//   Chairman,
//   Icon1,
//   Icon2,
//   Icon3,
//   Icon4,
//   AboutBackgroundVideoImage,
//   whatPeopleSayBackground,
//   bangladehsLocation,
//   unitedStateLocation,
//   DataCardOne,
//   DataCardTwo,
//   DataCardThree,
//   DataCardFour,
//   LeadCardOne,
//   LeadCardTwo,
//   LeadCardThree,
//   LeadCardFour,
//   gallery6: "https://i.ibb.co/FhZy9p9/gallery-6.webp",
//   gallery4,
//   gallery3,
//   gallery1,
//   EmailCardOne,
//   EmailCardTwo,
//   EmailCardThree,
//   EmailCardFour,
//   DevCardOne,
//   DevCardTwo,
//   DevCardThree,
//   DevCardFour,
//   UiCardOne,
//   UiCardTwo,
//   UiCardThree,
//   UiCardFour,
//   ServiceImage1,
//   DataGalOne,
//   DataGalTwo,
//   DataGalThree,
//   DataGalFour,
//   DataGalFive,
//   DataGalSix,
//   DataGalSeven,
//   LeadGalOne,
//   LeadGalTwo,
//   LeadGalThree,
//   LeadGalFour,
//   LeadGalFive,
//   LeadGalSix,
//   LeadGalSeven,
//   quickData_Entry,
//   dataMasters_Solutions,
//   swiftInput_Services,
//   precisionData_Solutions,
//   accuracy_Assurance,
//   timely_Processing,
//   custom_Solutions,
//   confidential_Handling,
//   shape3,
//   targeted_Lead_Generation,
//   content_Marketing_for_Leads,
//   automated_Lead_Nurturing,
//   performance_driven_Lead_Campaigns,
//   compelling_Content,
//   lead_Scoring,
//   targeted_Outreach,
//   shape2,
//   EmailGalOne,
//   EmailGalTwo,
//   EmailGalThree,
//   EmailGalFour,
//   EmailGalFive,
//   EmailGalSix,
//   EmailGalSeven,
//   Targeted_Email_Campaigns,
//   Automated_Email_Sequences,
//   Email_List_Management,
//   Performance_Analytics,
//   campaign,
//   automatedWorFlows,
//   targetedSagments,
//   performanceAnalytics,
//   shape1,
//   wordpress,
//   DevGalOne,
//   DevGalTwo,
//   DevGalThree,
//   DevGalFour,
//   DevGalFive,
//   DevGalSix,
//   DevGalSeven,
//   webflow,
//   shopify,
//   customeCoding,
//   cw,
//   rd,
//   ufi,
//   cms,
//   cbc,
//   seo,
//   swa,
//   mr,
//   fls,
//   ief,
//   ms,
//   Circle,
//   workProccessLine,
//   workingProccessIcon,
//   Qutation3,
//   dimond1,
//   badge1,
//   tecnical1,
//   productivaty1,
//   whayChuseUsBanner,
//   Video,
//   UiGalSix,
//   UiGalSeven,
//   UiGalFive,
//   UiGalThree,
//   UiGalFour,
//   UiGalOne,
//   UiGalTwo,
//   dataDriven,
//   wireframingPrototyping,
//   webUiUxDesign,
//   mobileUiUxDesign,
//   webDesign,
//   appDesign,
//   productDesign,
//   glow,
//   serviceImage,
//   CardGal4:"https://i.ibb.co/3cvqs6K/works5.jpg",
//   About,
//   Jabed,
//   ui_ux,
//   email_marketing,
//   lead_genaretion,
//   data_entry,
//   Logo,
//   heroBg,
//   heroHeaderIcon,
//   heroHeaderArrowIcon,
//   heroBadge,
//   footerArrow,
//   aboutOurselvesImg1,
//   aboutOurselvesImg2,
//   FirstImage,
//   SecondImage,
//   DownloadFirst,
//   DownloadSecond,
//   DownloadThird,
//   DownloadFourth,
//   BigO,
//   newsletter1,
//   newsletter2,
//   newsletter3,
//   whatsappIcon,
//   testimonialBg,
//   testimonial1,
//   space,
//   spaceX,
//   MiddleGallery,
//   customSolution1,
//   customSolution2,
//   customSolution3,
//   customSolution4,
//   customSolution5,
//   SliderOne,
//   SliderTwo,
//   Feedback,
//   AboutHeroImage,
//   SuccessStoriesImg,
//   OpenCommunication,
//   AboutIcon1,
//   AboutIcon2,
//   AboutIcon3,
//   AboutIcon4,
//   AboutBottomBg,
//   team1,
//   team2,
//   team3,
//   team4,
//   team5,
//   team6,
//   team7,
//   team8,
//   linkedin,
//   teamMemberImg,
//   notFound,
//   HeroSectionBg,
//   Work1,
//   Work2,
//   Work3,
//   Work4,
//   Work5,
//   Work6,
//   PlusIcon,
//   CareerHero,
//   CoreValue1,
//   CoreValue3,
//   CoreValue5,
//   CoreValue6,
//   Career1,
//   Career2,
//   Career3,
//   Career4,
//   Career5,
//   Career6,
//   Job,
//   CustomArrow,
//   Quotation,
//   Employee,
//   Footer2,
//   Gallery1,
//   Gallery2,
//   Gallery3,
//   Gallery4,
//   Custom1,
//   Custom2,
//   Custom3,
//   Custom4,
//   Quotation2,
//   Webdev,
//   clent1,
//   clent2,
//   clent3,
//   clent4,
//   clent5,
//   clent6,
//   CardGal1:"https://i.ibb.co/9qz9hbL/works1.webp",
//   CardGal2:"https://i.ibb.co/rcwW6xp/works2.webp",
//   CardGal3,
// };
