import { useState } from "react";
import { ButtonArrowTop } from "../button";
import { Link } from "react-router-dom";
import custom1 from "../../assets/Photo/UX Design.webp";
import custom2 from "../../assets/Photo/Web Development.webp";
import custom3 from "../../assets/Photo/email marketing.webp";
import custom4 from "../../assets/Photo/B2B lead generation.webp";
import custom5 from "../../assets/Photo/data entry.webp";

export const CustomSolution = () => {
  const handleClick = (event) => {
    if (event.ctrlKey) {
      return;
    }
    window.scroll({
      top: 0,
    });
  };
  const [selected, setSelected] = useState();

  const handleAccordion = (item) => {
    if (selected === item) {
      return setSelected(null);
    }
    return setSelected(item);
  };

  return (
    <div className="sm:pt-20 pt-16">
      {solutions.map((item) => (
        <div
          onMouseEnter={() => handleAccordion(item.id)}
          onMouseLeave={() => setSelected(null)}
          key={item.id}
          className="border-t-2 last:border-b-2 border-[#FFDE00] text-white"
        >
          <div className="grid grid-cols-1 md:flex gap-10 justify-between items-center py-8 sm:px-10 px-4 ">
            <div>
              <div className="flex items-center gap-6 font-bold">
                <h3
                  className={`md:text-2xl text-[20px] ${
                    selected === item.id ? "text-white" : "text-info"
                  }`}
                >
                  0{item.id}
                </h3>
                <h3
                  className={`md:text-[32px] lg:text-[56px] md:leading-[32px] text-xl ${
                    selected === item.id ? "text-info" : "text-[#FFDE00]"
                  }`}
                >
                  {item.name}
                </h3>
              </div>
              <div
                className={`grid grid-cols-1 w-full ${
                  selected === item.id
                    ? "grid-rows-[1fr] opacity-100 pt-8"
                    : "grid-rows-[0fr] opacity-0"
                } transition-all duration-700 overflow-hidden`}
              >
                <p className="sm:text-xl text-base overflow-hidden lg:w-3/5 w-full">
                  {item.description}
                </p>
                <div
                  className={`${
                    selected === item.id ? "mt-6 h-full" : "h-0"
                  } transition-all duration-700 pb-2`}
                >
                  {/* <Link to={item.link}> */}
                  <Link to={item.link} onClick={handleClick}>
                    <ButtonArrowTop name="Learn More" />
                  </Link>
                </div>
              </div>
            </div>
            <img
              className={`w-64 h-40 rounded mb-5 ${
                selected === item.id && "w-80 h-44 -rotate-12"
              } transition-all duration-700`}
              src={item.image}
              alt="custom solution"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const solutions = [
  {
    id: 1,
    name: "UI/UX Design",
    description:
      "Transform your online presence with our specialized design services covering App Design, Web Design, and Product & SAAS Design.",
    image: `${custom1}`,
    link: "/ui-ux",
  },
  {
    id: 2,
    name: "Web Development",
    description:
      "Explore the best-in-class website development options, including custom development, WordPress, Webflow, and Shopify, to elevate your digital footprint with seamless functionality and design.",
    image: `${custom2}`,
    link: "/web-development",
  },
  {
    id: 3,
    name: "Email Marketing",
    description:
      "We'll set up and run Effective campaigns, Automate your emails for efficiency, and design eye-catching Templates and Newsletters to make your communication with customers a breeze.",
    image: `${custom3}`,
    link: "/email-marketing",
  },
  {
    id: 4,
    name: "Lead Generation",
    description:
      "Unlock business growth with our specialized services, providing targeted B2B leads that align with your goals. We excel in constructing comprehensive email lists and contact databases, along with leveraging LinkedIn to deliver valuable leads for your business.",
    image: `${custom4}`,
    link: "/lead-generation",
  },
  {
    id: 5,
    name: "Data Entry Service",
    description:
      "Whether it's collecting information, entering data into Excel, conducting web research, scraping data from the web, converting PDFs, or precise typing work, our comprehensive services ensure accuracy and efficiency.",
    image: `${custom5}`,
    link: "/data-entry",
  },
];
